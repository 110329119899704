import React from 'react'

import Seperator from 'components/Seperator'

import InviteDoctorForm from './InviteDoctorForm'
import InvitePharmaForm from './InvitePharmaForm'
import SickfundsOverview from './SickfundsOverview'

function AdminAdministration() {
  return (
    <>
      <InvitePharmaForm />
      <Seperator />
      <InviteDoctorForm />
      <SickfundsOverview />
    </>
  )
}

export default AdminAdministration
