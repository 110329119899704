import React, { SyntheticEvent, useState } from 'react'
import * as yup from 'yup'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import Button from 'components/Button'
import InputGrid from 'components/InputGrid'
import Label from 'components/Label'
import RadioGroup from 'components/RadioGroup'
import InputGroup from 'components/InputGroup'
import InputError from 'components/InputError'
import Menu from 'components/Menu'
import TextArea from 'components/TextArea'
import FaIcon from 'components/FaIcon'
import CharacterCounter from 'components/CharacterCounter'
import Input from 'components/Input'
import BirthDayPicker from 'components/BirthDayPicker'
import InputAddon from 'components/InputAddon'

import { useFormFields, useYup, useScrollTo } from 'hooks'
import { requiredError, tooLongError } from 'messages/errors'
import { useClaimConfig, useClaim, ClaimStepFooter } from 'claimbuilder'
import { useTheme } from 'styled'

import MandatePdf from '../assets/Auftrag.pdf'
import {
  isPatientStepDefinition,
  DefaultPatientStepDefinition,
} from 'claimbuilder/types'
import { ClaimData } from 'claimbuilder/ClaimContext'

export const PatientStep = () => {
  useScrollTo()
  const { colors } = useTheme()
  const { config } = useClaimConfig()
  const claim = useClaim()
  const stepDefinition = claim.definition.steps.find(
    isPatientStepDefinition
  ) as DefaultPatientStepDefinition

  const data = config.prefill ? prefilledFormFields : initialFormFields
  const { fields, values, setValue } = useFormFields(data)
  const { validate, errors } = useYup(values, validationSchema)

  const { setData } = useClaim()
  const handleNext = async (evt: SyntheticEvent<HTMLButtonElement>) => {
    const { isValid } = await validate()
    if (isValid) {
      const patient = formFieldsToClaimPatient(values)
      setData(prev => ({
        ...prev,
        patient,
      }))
    } else {
      evt.preventDefault()
    }
  }

  const [diagnoseBtnClicked, setDiagnoseBtnClicked] = useState(false)
  const showGlow = !diagnoseBtnClicked && !values.diagnose

  return (
    <>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">
            Patienten Daten
          </FormDescription.Heading>
          <FormDescription.Content>
            Bitte geben Sie hier die Patienten Daten an.
          </FormDescription.Content>

          <FormDescription.Heading as="h2">
            Patienten Auftrag
          </FormDescription.Heading>
          <FormDescription.Content>
            <p>
              {`Wir empfehlen Ihnen sich vom Patienten einen Auftrag zur Einholung
              der Bewilligung bestätigen zu lassen. Das dafür
              notwendige Formular, sowie die dazugehörigen
              Datenschutzbestimmungen können Sie hier downloaden:`}
            </p>
            <a href={MandatePdf} target="_blank" rel="noopener noreferrer">
              <Button variant="tertiary">
                <Button.Icon name="download" />
                <span>Auftrags-Formular</span>
              </Button>
            </a>
          </FormDescription.Content>
        </FormDescription>
        <InputGrid>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Anrede</Label>
            <RadioGroup {...fields.gender}>
              <InputGroup>
                <RadioGroup.Button
                  value="male"
                  style={{ flex: '1', textAlign: 'center' }}
                >
                  Herr
                </RadioGroup.Button>
                <RadioGroup.Button
                  value="female"
                  style={{ flex: '1', textAlign: 'center' }}
                >
                  Frau
                </RadioGroup.Button>
              </InputGroup>
            </RadioGroup>
            {errors.gender && <InputError>{errors.gender}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Titel</Label>
            <Input type="text" {...fields.title} />
          </InputGrid.Item>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Vorname</Label>
            <Input type="text" {...fields.name} />
            {errors.name && <InputError>{errors.name}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Nachname</Label>
            <Input type="text" {...fields.surname} />
            {errors.surname && <InputError>{errors.surname}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item>
            <Label>Geburtsdatum</Label>
            <BirthDayPicker
              date={values.birthday}
              onChange={date => setValue('birthday', date)}
            />
            {errors.birthday && <InputError>{errors.birthday}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Gewicht</Label>
            <InputGroup>
              <Input
                type="number"
                onChange={evt =>
                  setValue('weight', Number(evt.currentTarget.value))
                }
                name={fields.weight.name}
                value={fields.weight.value || ''}
              />
              <InputAddon style={{ width: '48px' }}>kg</InputAddon>
            </InputGroup>
            {errors.weight && <InputError>{errors.weight}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>SV.-Nr.</Label>
            <Input type="text" {...fields.svn} />
            {errors.svn && <InputError>{errors.svn}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item>
            <Label>Diagnose (frei editierbar)</Label>
            <InputGroup>
              <TextArea
                placeholder="Bitte geben Sie hier eine Diagnose ein, oder wählen Sie einen Vorschlag aus dem Menü-Button rechts neben dem Textfeld. Der Text dieser Vorschläge kann ebenfalls noch frei editiert werden."
                {...fields.diagnose}
              />
              {stepDefinition.diagnoseOptions &&
                stepDefinition.diagnoseOptions.length > 0 && (
                  <Menu>
                    <Menu.Button
                      style={{
                        alignSelf: 'baseline',
                        padding: '12px 14px 12px 14px',
                        border: '1px solid',
                        borderLeft: '0',
                        borderColor: colors['grey-70'],
                        color: colors['grey-50'],
                      }}
                      className={showGlow ? 'glow' : ''}
                      onClick={() => setDiagnoseBtnClicked(true)}
                    >
                      <FaIcon name="bars" />
                    </Menu.Button>
                    <Menu.List>
                      {stepDefinition.diagnoseOptions.map(diagnose => (
                        <Menu.Item
                          key={diagnose.label}
                          onClick={() => setValue('diagnose', diagnose.value)}
                        >
                          {diagnose.label}
                        </Menu.Item>
                      ))}
                    </Menu.List>
                  </Menu>
                )}
            </InputGroup>
            <CharacterCounter
              maxCharacters={500}
              currentCharacters={fields.diagnose.value.length}
            />
            {errors.diagnose && <InputError>{errors.diagnose}</InputError>}
          </InputGrid.Item>
        </InputGrid>
      </FormGrid>

      <ClaimStepFooter onCommit={handleNext} />
    </>
  )
}

const validationSchema = yup.object().shape({
  gender: yup.string().required(requiredError('Anrede')),
  name: yup
    .string()
    .max(50, tooLongError('Vorname', 50))
    .required(requiredError('Vorname')),
  surname: yup
    .string()
    .max(50, tooLongError('Nachname', 50))
    .required(requiredError('Nachname')),
  weight: yup
    .number()
    .min(1, requiredError('Gewicht'))
    .max(650, 'Bitte geben Sie ein gültiges Gewicht an.'),
  svn: yup
    .string()
    .max(13, tooLongError('SV.-NR', 13))
    .required(requiredError('SV.-NR')),
  birthday: yup
    .date()
    .required(requiredError('Geburtsdatum'))
    .max(new Date(), 'Ungültiges Geburtsdatum.'),
  diagnose: yup
    .string()
    .max(500, tooLongError('SV.-NR', 500))
    .required(requiredError('Diagnose')),
})

const initialFormFields = {
  gender: '' as 'male' | 'female' | '',
  title: '',
  name: '',
  surname: '',
  weight: 0,
  svn: '',
  birthday: undefined as Date | undefined,
  diagnose: '',
}

const prefilledFormFields = {
  gender: 'male' as 'male' | 'female' | '',
  title: '',
  name: 'Max',
  surname: 'Mustermann',
  weight: 80,
  svn: '1234',
  birthday: new Date(1980, 0, 1) as Date | undefined,
  diagnose: 'Musterdiagnose',
}

const formFieldsToClaimPatient = (
  values: typeof initialFormFields
): ClaimData['patient'] => {
  return {
    gender: values.gender as 'male' | 'female',
    name: `${values.title ? `${values.title} ` : ''}${values.name} ${
      values.surname
    }`,
    birthdate: values.birthday as Date,
    svn: values.svn,
    weight: values.weight,
    diagnose: values.diagnose,
  }
}
