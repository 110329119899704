// styled-components.ts
import { useContext } from 'react'
import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule } from "styled-components";

import ThemeInterface from "./themes/ThemeInterface";

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  ThemeContext,
} = styledComponents as ThemedStyledComponentsModule<ThemeInterface>;

const useTheme = () => useContext(ThemeContext)

export { css, createGlobalStyle, keyframes, ThemeProvider, useTheme }
export default styled;
