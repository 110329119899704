
import { ReactNode } from 'react'
import ReactDOM from 'react-dom'

const body = document.body;

type Props = {
    children: ReactNode,
    mountElement?: HTMLElement,
}

function Portal(props: Props) {
    const mountElement = props.mountElement || body;

    return ReactDOM.createPortal(
        props.children,
        mountElement
    )
}

export default Portal;
