import React, { CSSProperties } from 'react'

type StepsPaneProps = {
  children?: React.ReactNode,
  className?: string,
  style?: CSSProperties
}

function StepsPane(props: StepsPaneProps) {
  return <div {...props} />
}

export default StepsPane
