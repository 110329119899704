import React from 'react'

import { ClaimStepDefinition, StepDefinitionTypes } from 'claimbuilder/types'
import { useMedia } from 'hooks'
import FaIcon, {FaIconProps} from 'components/FaIcon'

interface ClaimStepTitleProps {
  step: ClaimStepDefinition
}

export const ClaimStepTitle: React.FC<ClaimStepTitleProps> = ({ step }) => {
  const { isPhone, isTablet } = useMedia()

  const title = useClaimStepTitle(step)
  const icon = useClaimStepIcon(step)

  if (isTablet || isPhone) {
    return <FaIcon name={icon} />
  }
  return (
    <>{title}</>
  )
}

const useClaimStepTitle = ({ type }: ClaimStepDefinition): string => {
  switch (type) {
    case StepDefinitionTypes.DOCTOR:
      return 'Arzt'
    case StepDefinitionTypes.PATIENT:
      return 'Patient'
    case StepDefinitionTypes.DOSING:
      return 'Dosierung'
    case StepDefinitionTypes.ARGUMENTS:
      return 'Argumente'
    case StepDefinitionTypes.DISPATCH:
      return 'Versand'
    case StepDefinitionTypes.SUMMARY:
      return 'Vorschau'
  }
}

const useClaimStepIcon = ({type }: ClaimStepDefinition): FaIconProps['name'] => {
  switch (type) {
    case StepDefinitionTypes.DOCTOR:
      return 'user-md'
    case StepDefinitionTypes.PATIENT:
      return 'user'
    case StepDefinitionTypes.DOSING:
      return 'balance-scale'
    case StepDefinitionTypes.ARGUMENTS:
      return 'exclamation-circle'
    case StepDefinitionTypes.DISPATCH:
      return 'exclamation-circle' // TODO: same icon as arguments?
    case StepDefinitionTypes.SUMMARY:
      return 'file-alt'
  }
}
