import React from 'react'
import { RouteComponentProps } from '@reach/router'

import Title from 'components/Title'
import DefaultPageLayout from 'layout/DefaultPageLayout'
export const VerifySuccess = (props: RouteComponentProps) => {
  return (
    <DefaultPageLayout>
      <Title>E-Mail verifizierung erfolgreich abgeschlossen</Title>
      <p>
        Sie können dieses Fenster nun schließen.
      </p>
    </DefaultPageLayout>
  )
}

export default VerifySuccess
