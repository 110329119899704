/* eslint-disable eqeqeq */
/* eslint-disable no-extend-native */

function registerStringRepeatPolyfill() {
  if (!String.prototype.repeat) {
    String.prototype.repeat = function(count) {
      if (this == null) {
        // check if `this` is null or undefined
        throw new TypeError("can't convert " + this + ' to object')
      }
      var str = '' + this
      // To convert string to integer.
      count = +count
      if (count < 0) {
        throw new RangeError('repeat count must be non-negative')
      }
      if (count == Infinity) {
        throw new RangeError('repeat count must be less than infinity')
      }
      count |= 0 // floors and rounds-down it.
      if (str.length == 0 || count == 0) {
        return ''
      }
      // Ensuring count is a 31-bit integer allows us to heavily optimize the
      // main part. But anyway, most current (August 2014) browsers can't handle
      // strings 1 << 28 chars or longer, so:
      if (str.length * count >= 1 << 28) {
        throw new RangeError(
          'repeat count must not overflow maximum string size'
        )
      }
      while ((count >>= 1)) {
        // shift it by multiple of 2 because this is binary summation of series
        str += str // binary summation
      }
      str += str.substring(0, str.length * count - str.length)
      return str
    }
  }
}

function registerPolyfills() {
  registerStringRepeatPolyfill()
}

export { registerPolyfills }
