export const getHomeRoute = () => '/'

export const getLoginRoute = () => '/login'

export const getClaimRoute = (drug: string) => `/antrag/${drug}`

export const getProfileRoute = () => '/profil'

export const getAdministrationRoute = () => '/administration'
export const getSickfundEditRoute = (sickfundId: string) => `${getAdministrationRoute()}/sickfund/${sickfundId}`

export const getTermsRoute = () => '/agb'
export const getDisclosureRoute = () => '/impressum'
export const getDataPrivacyRoute = () => '/datenschutz'

export const getVerifySuccessRoute = () => '/verifysuccess'
