import styled from 'styled'
import { darken, transparentize } from 'polished'

const BaseButton = styled.button`
  cursor: pointer;
  appearance: none;
  &:focus {
    outline: none;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  padding: ${props => props.theme.spaces.insetSquish.l};

  border-radius: ${props => props.theme.radius.s};
  border: 0;

  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: ${props => props.theme.fonts.default};

  box-shadow: 0 1px 3px
      ${props => transparentize(0.9, props.theme.colors['grey-40'])},
    0 1px 2px ${props => transparentize(0.85, props.theme.colors['grey-30'])};

  > *:not(:last-child) {
    margin: ${props => props.theme.spaces.inline.s};
  }

  &.glow {
    animation: glowing 2500ms infinite;
  }

  @keyframes glowing {
    0% {
      box-shadow: 0 0 -10px ${props => props.theme.colors.primary};
    }
    50% {
      box-shadow: 0 0 8px ${props => props.theme.colors.primary};
    }
    100% {
      box-shadow: 0 0 -10px ${props => props.theme.colors.primary};
    }
  }
`

export const PrimaryButton = styled(BaseButton)`
  background: ${props => props.theme.colors.primary};
  color: #ffffff;
  :hover {
    background: ${props => darken(0.03, props.theme.colors.primary)};
  }
  &:disabled,
  &[disabled] {
    background: ${props => props.theme.colors['primary-60']};
    color: ${props => props.theme.colors['grey-95']};
  }
`

export const SecondaryButton = styled(BaseButton)`
  background: ${props => props.theme.colors['grey-90']};
  color: ${props => props.theme.colors.color};
  :hover {
    background: ${props => darken(0.02, props.theme.colors['grey-90'])};
  }
  &:disabled,
  &[disabled] {
    background: ${props => props.theme.colors['grey-90']};
    color: ${props => props.theme.colors['grey-60']};
  }
`

export const TertiaryButton = styled(BaseButton)`
  background: none;
  color: ${props => props.theme.colors.primary};
  :hover {
    color: ${props => darken(0.07, props.theme.colors.primary)};
  }
  &:disabled,
  &[disabled] {
    color: ${props => props.theme.colors['grey-60']};
  }
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
`
