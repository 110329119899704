import Auth from '@aws-amplify/auth'

const changePassword = async (oldPassword: string, newPassword: string) => {
  const user = await Auth.currentAuthenticatedUser()
  return await Auth.changePassword(user, oldPassword, newPassword)
}

const forgotPassword = async (username: string) => {
  const data = await Auth.forgotPassword(username)
  return data
}

const forgotPasswordSubmit = async (username: string, code: string, password: string) => {
  const data = await Auth.forgotPasswordSubmit(username, code, password)
  return data
}

export { changePassword, forgotPassword, forgotPasswordSubmit }
