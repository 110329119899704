import useUserInfo from './useUserInfo'
import { PharmaCompany } from 'claimbuilder'

const companyEmails: { [key in PharmaCompany]: Array<RegExp> } = {
  [PharmaCompany.TAKEDA]: [/@takeda.com$/, /@shire.com$/ ],
  [PharmaCompany.SIGMA_PHARM]: [/@sigmapharm.at$/, /@chefarztantrag.at$/],
  [PharmaCompany.DEMO]: [],
}

function enumToArray<T>(t: T): ReadonlyArray<T[keyof T]> {
  const values = Object.values(t)
  return values.some(x => typeof x === 'number')
    ? values.filter(x => typeof x === 'number')
    : values
}

function usePharmaCompany(): PharmaCompany | null {
  const userInfo = useUserInfo()
  if (userInfo) {
    for (const company of enumToArray(PharmaCompany)) {
      const emailSignatures = companyEmails[company]
      if (emailSignatures) {
        for (const signature of emailSignatures) {
          if (signature.test(userInfo.attributes.email)) {
            return company
          }
        }
      }
    }
  }
  return null
}

export default usePharmaCompany
