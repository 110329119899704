import { useEffect, useState } from 'react'
import Jimp from 'jimp'

interface ImageFilterOptions {
  width?: number
}

function useImageProcessor(file: File | null, options: ImageFilterOptions) {
  const [base64, setBase64] = useState('')
  const [loading, setLoading] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 0, height: 0 })
  useEffect(() => {
    if (file) {
      const processImage = async () => {
        const image = await Jimp.read(URL.createObjectURL(file))

        const resizeFactor = (options.width || 600) / image.bitmap.width

        if (image.bitmap.width < image.bitmap.height) {
          image.rotate(90)
        }

        whiteBalance(image)

        image.scale(2)

        const cropArea = extractSignature(image)

        // recalculate crop because of down scaling
        cropArea.x = Math.round((cropArea.x * resizeFactor) / 2)
        cropArea.y = Math.round((cropArea.y * resizeFactor) / 2)
        cropArea.width = Math.round((cropArea.width * resizeFactor) / 2)
        cropArea.height = Math.round((cropArea.height * resizeFactor) / 2)

        image
          .scale(resizeFactor / 2)
          .getBuffer(Jimp.MIME_PNG, (error, buffer) => {
            if (error) {
              console.error(error)
            }
            const base64String = buffer.toString('base64')
            setBase64('data:image/png;base64, ' + base64String)
            setCrop(cropArea)
            setLoading(false)
          })
      }
      setLoading(true)
      processImage()
    }
  }, [file, options.width])

  return [base64, loading, crop] as [typeof base64, boolean, typeof crop]
}

function extractSignature(image: Jimp) {
  let xMin = Number.MAX_SAFE_INTEGER,
    yMin = Number.MAX_SAFE_INTEGER,
    xMax = 0,
    yMax = 0

  image
    .rgba(true)
    .scan(0, 0, image.bitmap.width, image.bitmap.height, function(
      xIdx,
      yIdx,
      idx
    ) {
      const red = this.bitmap.data[idx]
      const green = this.bitmap.data[idx + 1]
      const blue = this.bitmap.data[idx + 2]

      const [, , l] = getHSL([red, green, blue])
      const alpha = l < 0.65 ? 255 : 0

      if (alpha === 255) {
        if (xIdx < xMin) {
          xMin = xIdx
        }
        if (yIdx < yMin) {
          yMin = yIdx
        }
        if (xIdx > xMax) {
          xMax = xIdx
        }
        if (yIdx > yMax) {
          yMax = yIdx
        }
      }
      this.bitmap.data[idx + 3] = alpha
    })

  return {
    x: xMin,
    y: yMin,
    width: xMax - xMin,
    height: yMax - yMin,
  }
}

function whiteBalance(image: Jimp) {
  const histogram = {
    r: new Array(256).fill(0),
    g: new Array(256).fill(0),
    b: new Array(256).fill(0),
  }

  image.scan(0, 0, image.bitmap.width, image.bitmap.height, function(
    x,
    y,
    index
  ) {
    histogram.r[this.bitmap.data[index + 0]]++

    histogram.g[this.bitmap.data[index + 1]]++

    histogram.b[this.bitmap.data[index + 2]]++
  })

  // store bounds (minimum and maximum values)
  const bounds = {
    r: getBounds(histogram.r),
    g: getBounds(histogram.g),
    b: getBounds(histogram.b),
  }
  if (bounds.r[1] < 100 || bounds.g[1] < 100 || bounds.b[1] < 100) {
    bounds.r[1] = Math.min(bounds.r[1] + 20, 255)
    bounds.g[1] = Math.min(bounds.g[1] + 20, 255)
    bounds.b[1] = Math.min(bounds.b[1] + 20, 255)
  }

  image.scan(0, 0, image.bitmap.width, image.bitmap.height, function(
    x,
    y,
    idx
  ) {
    const red = this.bitmap.data[idx]

    const green = this.bitmap.data[idx + 1]

    const blue = this.bitmap.data[idx + 2]

    this.bitmap.data[idx + 0] = normalize(red, bounds.r[0], bounds.r[1])

    this.bitmap.data[idx + 1] = normalize(green, bounds.g[0], bounds.g[1])

    this.bitmap.data[idx + 2] = normalize(blue, bounds.b[0], bounds.b[1])
  })
}

const normalize = function(value: number, min: number, max: number) {
  if (value > max) {
    return 255
  }
  if (value < min) {
    return 0
  }

  return ((value - min) * 255) / (max - min)
}

const getBounds = function(histogramChannel: number[]) {
  let maxValue = 0
  histogramChannel.forEach(val => {
    maxValue = val > maxValue ? val : maxValue
  })
  return [
    histogramChannel.findIndex(value => value > 0),
    255 -
      Math.max(
        histogramChannel
          .slice()
          .reverse()
          .findIndex(value => value === maxValue) - 10,
        0
      ),
  ]
}

function getHSL([red, green, blue]: [number, number, number]) {
  const r = red / 255
  const g = green / 255
  const b = blue / 255
  const M = Math.max(r, g, b)
  const m = Math.min(r, g, b)
  const C = M - m
  let H: number | undefined = undefined

  if (M === r) {
    H = ((g - b) / C) % 6
  }
  if (M === g) {
    H = (b - r) / C + 2
  }
  if (M === b) {
    H = (r - g) / C + 4
  }
  if (H) {
    H = H * 60
  }

  const L = (1 / 2) * (M + m)
  const S = L === 1 || L === 0 ? 0 : C / (1 - Math.abs(2 * L - 1))

  return [H, S, L] as [number | undefined, number, number]
}

export default useImageProcessor
