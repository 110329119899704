export enum StepDefinitionTypes {
  DOCTOR,
  PATIENT,
  DOSING,
  ARGUMENTS,
  DISPATCH,
  SUMMARY,
}

export interface DefaultDoctorStepDefinition {
  type: StepDefinitionTypes.DOCTOR
}

export const isDoctorStepDefinition = (
  stepDef: ClaimStepDefinition
): stepDef is DefaultDoctorStepDefinition => {
  return stepDef.type === StepDefinitionTypes.DOCTOR
}

export interface PatientDiagnoseOption {
  label: string
  value: string
}

export interface DefaultPatientStepDefinition {
  type: StepDefinitionTypes.PATIENT
  diagnoseOptions?: PatientDiagnoseOption[]
}

export const isPatientStepDefinition = (
  stepDef: ClaimStepDefinition
): stepDef is DefaultPatientStepDefinition => {
  return stepDef.type === StepDefinitionTypes.PATIENT
}

export enum DosingFrequencyUnit {
  DAY,
  WEEK,
  MONTH,
}

export interface DosingFrequencyDefinition {
  label: string
  value: number | 'CUSTOM'
}

export interface DosingDefinition {
  label?: string
  value: number
}
export interface DefaultDosingStepDefinition {
  type: StepDefinitionTypes.DOSING
  frequencyUnit: DosingFrequencyUnit
  frequencies: DosingFrequencyDefinition[]
  dosings: DosingDefinition[]
  unit: string
  description?: string
}

export const isDosingStepDefinition = (
  stepDef: ClaimStepDefinition
): stepDef is DefaultDosingStepDefinition => {
  return stepDef.type === StepDefinitionTypes.DOSING
}

export interface ArgumentDefinition {
  label: string
  description?: string
}
export interface DefaultArgumentsStepDefinition {
  type: StepDefinitionTypes.ARGUMENTS
  arguments: ArgumentDefinition[]
}

export const isArgumentsStepDefinition = (
  stepDef: ClaimStepDefinition
): stepDef is DefaultArgumentsStepDefinition => {
  return stepDef.type === StepDefinitionTypes.ARGUMENTS
}

export interface DefaultDispatchStepDefinition {
  type: StepDefinitionTypes.DISPATCH
}

export const isDispatchStepDefinition = (
  stepDef: ClaimStepDefinition
): stepDef is DefaultDispatchStepDefinition => {
  return stepDef.type === StepDefinitionTypes.DISPATCH
}

export interface DefaultSummaryStepDefinition {
  type: StepDefinitionTypes.SUMMARY
}

export const isSummaryStepDefinition = (
  stepDef: ClaimStepDefinition
): stepDef is DefaultSummaryStepDefinition => {
  return stepDef.type === StepDefinitionTypes.SUMMARY
}

export type ClaimStepDefinition =
  | DefaultDoctorStepDefinition
  | DefaultPatientStepDefinition
  | DefaultDosingStepDefinition
  | DefaultArgumentsStepDefinition
  | DefaultDispatchStepDefinition
  | DefaultSummaryStepDefinition

export enum PharmaCompany {
  DEMO,
  TAKEDA,
  SIGMA_PHARM,
}

export interface ProductInformation {
  path?: string
  name: string
  informationDocument: string
  logo: string
  substances: string[]
}

export enum ClaimClearance {
  DEVELOPMENT,
  DEMO,
  APPROVAL_REQUIRED,
  APPROVED,
}

export interface Attachment {
  fileSrc: string
  label: string
}

export interface GivenAttachments {
  sendingPortalLabel: string
  attachments?: Attachment[]
}

export interface ClaimDefinition {
  product: ProductInformation
  owner: PharmaCompany
  clearance: ClaimClearance
  steps: ClaimStepDefinition[]
  givenAttachments?: GivenAttachments
}
