export enum UserGroup {
  ADMIN = 'Admin',
  PHARMA = 'Pharma',
  DOCTOR = 'Doctor',
  DEMO = 'Demo',
}

export type CognitoUserInfo = {
  username: string
  attributes: {
    sub: string
    email: string
  }
  groups: UserGroup[]
}

export type Doctor = {
  id: string
  gender: 'male' | 'female' | ''
  title: string
  name: string
  surname: string
  email: string
  oid: string
  hospital: {
    name: string
    department: string
    street: string
    zip: number
    city: string
    phone: string
    fax: string
  }
  surgery: {
    name: string
    street: string
    zip: number
    city: string
    phone: string
    fax: string
  }
}

export type Admin = {
  id: string
  gender: 'male' | 'female' | ''
  title: string
  name: string
  surname: string
  email: string
}

export type Pharma = {
  id: string
  gender: 'male' | 'female' | ''
  title: string
  name: string
  surname: string
  email: string
  company: string
  department: string
}

export interface SickfundService {
  id: string
  sickfundId: string
  name: string
  street: string
  zip: number
  city: string
  phone: string
  fax: string
  email: string
  note: string
  secureMail?: boolean
  emailAllowed: boolean
  faxPreferred: boolean
}

export interface Sickfund {
  id: string,
  name: string
  longName: string
}

export interface AddressFields {
  name: string
  companyName?: string
  department?: string
  firstAddressLine: string
  secondAddressLine: string
  phoneNo?: string
  faxNo?: string
}
