import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useTheme } from 'styled'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import InputGrid from 'components/InputGrid'
import Input from 'components/Input'
import Label from 'components/Label'
import RadioGroup from 'components/RadioGroup'
import InputGroup from 'components/InputGroup'
import InputError from 'components/InputError'
import Button from 'components/Button'
import { useAlert, withAlertProvider, AlertFeed } from 'components/Alert'

import { requiredError, tooLongError, emailError } from 'messages/errors'
import * as API from 'api'
import { useFormFields, useYup, useApi } from 'hooks'

const initalFormState = {
  gender: '' as 'male' | 'female' | '',
  title: '',
  surname: '',
  name: '',
  company: '',
  department: '',
  email: '',
}

function InvitePharmaForm() {
  const [alert] = useAlert({ dismissEnabled: false, autoHide: false })
  const { fields, values, reset } = useFormFields(initalFormState)
  const { validate, errors } = useYup(values, validationSchema)
  const [invitePharmaResponse, invitePharma] = useApi(API.invitePharma)

  const { spaces } = useTheme()

  const handlePharmaInvite = async () => {
    const { isValid } = await validate()
    if (isValid) {
      invitePharma({
        ...values,
        email: values.email.toLocaleLowerCase(),
      })
    }
  }

  useEffect(() => {
    if (!invitePharmaResponse.loading && invitePharmaResponse.data) {
      alert.success(
        `Der Pharma Mitarbeiter ${
          invitePharmaResponse.data.email
        } wurde erfolgreich eingeladen.`
      )
      reset()
    } else if (!invitePharmaResponse.loading && invitePharmaResponse.error) {
      alert.error(
        `Beim Senden der Einladung ist leider ein Fehler aufgetreten.`
      )
    }
  }, [
    alert,
    invitePharmaResponse.data,
    invitePharmaResponse.error,
    invitePharmaResponse.loading,
    reset,
  ])

  return (
    <FormGrid>
      <FormDescription>
        <FormDescription.Heading>
          Neuen Pharma Mitarbeiter einladen
        </FormDescription.Heading>
        <FormDescription.Content>
          Bitte f&uuml;llen Sie hier s&auml;mtliche Felder aus, um einen neuen
          Pharma Mitarbeiter einzuladen.
        </FormDescription.Content>
      </FormDescription>
      <form onSubmit={evt => evt.preventDefault()}>
        <InputGrid>
          <AlertFeed limit={1} />
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Anrede</Label>
            <RadioGroup {...fields.gender}>
              <InputGroup>
                <RadioGroup.Button
                  value="male"
                  style={{ flex: '1', textAlign: 'center' }}
                >
                  Herr
                </RadioGroup.Button>
                <RadioGroup.Button
                  value="female"
                  style={{ flex: '1', textAlign: 'center' }}
                >
                  Frau
                </RadioGroup.Button>
              </InputGroup>
            </RadioGroup>
            {errors.gender && <InputError>{errors.gender}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Titel</Label>
            <Input type="text" {...fields.title} />
            {errors.title && <InputError>{errors.title}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Vorname</Label>
            <Input type="text" {...fields.name} />
            {errors.name && <InputError>{errors.name}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Nachname</Label>
            <Input type="text" {...fields.surname} />
            {errors.surname && <InputError>{errors.surname}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Firma</Label>
            <Input type="text" {...fields.company} />
            {errors.company && <InputError>{errors.company}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Abteilung</Label>
            <Input type="text" {...fields.department} />
            {errors.department && <InputError>{errors.department}</InputError>}
          </InputGrid.Item>
          <div style={{ margin: spaces.stack.l }}>
            <Label>Email</Label>
            <Input type="text" {...fields.email} />
            {errors.email && <InputError>{errors.email}</InputError>}
          </div>
          <Button
            onClick={handlePharmaInvite}
            variant="primary"
            style={{ justifySelf: 'start' }}
            type="submit"
            disabled={invitePharmaResponse.loading}
          >
            <span>Pharma Mitarbeiter einladen</span>
          </Button>
        </InputGrid>
      </form>
    </FormGrid>
  )
}

const validationSchema = yup.object().shape({
  gender: yup.string().required(requiredError('Anrede')),
  title: yup.string().max(40, tooLongError('Titel', 40)),
  name: yup
    .string()
    .max(50, tooLongError('Vorname', 50))
    .required(requiredError('Vorname')),
  surname: yup
    .string()
    .max(50, tooLongError('Nachname', 50))
    .required(requiredError('Nachname')),
  email: yup
    .string()
    .email(emailError())
    .max(254, tooLongError('Email', 254))
    .required(requiredError('Email')),
  company: yup
    .string()
    .max(50, tooLongError('Firma', 50))
    .required(requiredError('Firma')),
  department: yup
    .string()
    .max(50, tooLongError('Firma', 50))
    .required(requiredError('Firma')),
})

export default withAlertProvider(InvitePharmaForm)
