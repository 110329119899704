import React, {
  CSSProperties,
  useCallback,
  useReducer,
  ReactNode,
  useContext,
  useRef,
  useEffect,
  useMemo,
} from 'react'
import uuid from 'uuid/v4'

import AlertBox from './AlertBox'

type AlertType = 'success' | 'warning' | 'error'
type AlertOptions = {
  duration: number
  autoHide: boolean
  dismissEnabled: boolean
}
type AlertMessage = {
  id: string
  type: AlertType
  message: string
  options: AlertOptions
}

///////////////////////////////
///// Alert Provider
///////////////////////////////

type AddAlertAction = {
  type: 'add'
  payload: AlertMessage
}

type RemoveAlertAction = {
  type: 'remove'
  payload: { id: string }
}

type AlertReducerAction = AddAlertAction | RemoveAlertAction

const initialAlertState = {
  messages: [] as AlertMessage[],
}

const AlertContext = React.createContext({
  state: initialAlertState,
  dispatch: (() => {}) as React.Dispatch<AlertReducerAction>,
})

const alertReducer = (
  state: typeof initialAlertState,
  action: AlertReducerAction
) => {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }
    case 'remove':
      return {
        ...state,
        messages: state.messages.filter(msg => msg.id !== action.payload.id),
      }
  }
}

const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(alertReducer, {
    messages: [] as AlertMessage[],
  })

  return (
    <AlertContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

const withAlertProvider = <P extends {}>(Component: React.ComponentType<P>) => {
  return (props: P) => (
    <AlertProvider>
      <Component {...props} />
    </AlertProvider>
  )
}

///////////////////////////////
///// Alert Portal
///////////////////////////////

type AlertFeedProps = {
  limit?: number
  alertClassName?: string
  alertStyle?: CSSProperties
}

const AlertFeed = (props: AlertFeedProps) => {
  const {
    state: { messages },
    dispatch,
  } = useContext(AlertContext)

  if (!messages.length) {
    return null
  }
  const alertMessages = props.limit ? messages.slice(-props.limit) : messages

  return (
    <div>
      {alertMessages.map(({ id, type, message, options }) => (
        <AlertBox
          key={id}
          type={type}
          onClose={
            options.dismissEnabled
              ? () => dispatch({ type: 'remove', payload: { id } })
              : undefined
          }
          className={props.alertClassName}
          style={props.alertStyle}
        >
          {message}
        </AlertBox>
      ))}
    </div>
  )
}

///////////////////////////////
///// useAlert
///////////////////////////////

type AlertFunction = (message: string, options?: Partial<AlertOptions>) => void

const defaultUseAlertOptions: AlertOptions = {
  duration: 10000,
  autoHide: true,
  dismissEnabled: true,
}

const useAlert = (defaultAlertOptions: Partial<AlertOptions> = {}) => {
  const defaultOptions = useMemo(
    () => ({ ...defaultUseAlertOptions, ...defaultAlertOptions }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(defaultAlertOptions)]
  )
  const { dispatch, state } = useContext(AlertContext)

  // track local messageIds to remove them when component which is using hook gets unmounted
  const localMessageIds = useRef([] as string[])
  const dismissAlertMessage = useCallback(
    (id: string) => {
      dispatch({ type: 'remove', payload: { id } })
      localMessageIds.current = localMessageIds.current.filter(
        localMsgId => localMsgId !== id
      )
    },
    [dispatch]
  )
  useEffect(() => {
    return () => {
      localMessageIds.current.forEach(dismissAlertMessage)
    }
  }, [dismissAlertMessage])

  const addAlert = useCallback(
    (type: AlertType, message: string, config: Partial<AlertOptions> = {}) => {
      const options = { ...defaultOptions, ...config }
      const id = uuid()
      localMessageIds.current.push(id)

      dispatch({
        type: 'add',
        payload: { id, type, message, options },
      })

      if (options.autoHide) {
        setTimeout(() => {
          if (localMessageIds.current.some(msgId => msgId === id)) {
            dismissAlertMessage(id)
          }
        }, options.duration)
      }
    },
    [defaultOptions, dismissAlertMessage, dispatch]
  )

  const alert = useMemo(
    () => ({
      error: (message: string, options?: Partial<AlertOptions>) =>
        addAlert('error', message, options),
      warning: (message: string, options?: Partial<AlertOptions>) =>
        addAlert('warning', message, options),
      success: (message: string, options?: Partial<AlertOptions>) =>
        addAlert('success', message, options),
    }),
    [addAlert]
  )

  return [alert, state.messages] as [
    { error: AlertFunction; warning: AlertFunction; success: AlertFunction },
    AlertMessage[]
  ]
}

export { AlertBox, AlertFeed, AlertProvider, useAlert, withAlertProvider }
