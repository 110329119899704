import styled from 'styled'

const ProductArea = styled.div`
  display: grid;

  grid-column-gap: ${props => props.theme.spaces.xs};

  grid-template-columns: auto auto;
  ${props => props.theme.media.phone`grid-template-columns: auto;`};

  justify-items: start;
  align-items: end;
`
export default ProductArea
