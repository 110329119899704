import React, { useState, useEffect } from 'react'

import Spinner from 'components/Spinner'

function LoadingPage() {
  const [showSpinner, setShowSpinner] = useState(false)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSpinner(true)
    }, 300)
    return () => clearTimeout(timeoutId)
  }, [])

  if (!showSpinner) {
    return null
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 40,
    }}>
      <div>
        <Spinner />
      </div>
      Die Seite wird geladen...
    </div>
  )
}

export default LoadingPage
