import styled from 'styled'
import { Link as ReachLink } from '@reach/router'

import Menu from 'components/Menu'

// ToDo: change Shadow and implement in theme
export const HeaderBar = styled.div`
  width: 100%;
  box-shadow: 0 1px 2px #2626262e, 0 1px 1px #1f1f1f26;
  background: #ffffff;
`

export const ContentArea = styled.div`
  display: grid;
  height: 50px;
  grid-template-columns: auto auto;
`

export const LogoText = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  align-self: center;
  justify-self: start;

  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`

export const TopMenu = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const MobileMenuButton = styled(Menu.Button)`
  align-self: center;
  padding: 16px 12px 12px 12px;
  margin-right: -12px;
  box-shadow: none;

  background: ${props => props.theme.colors.background};
  &:focus {
    background: ${props => props.theme.colors.background};
  }
  :hover {
    background: ${props => props.theme.colors.background};
  }
`

export const MobileMenuList = styled(Menu.List)`
  text-align: right;
  position: absolute;
  right: -12px;
  top: -1px;
  border-radius: 0 0 0 5px;
`

export const MobileMenuLink = styled(ReachLink)`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: ${props => props.theme.colors['grey-40']};

  padding: ${props => props.theme.spaces.insetSquish.m};
  text-decoration: none;

  display: flex;
  align-items: center;

  > * {
    margin: ${props => props.theme.spaces.inline.s};
  }

  &:visited {
    color: ${props => props.theme.colors['grey-50']};
  }

  &:hover {
    color: ${props => props.theme.colors['grey-30']};
    text-decoration: none;
  }
`
