import React, { useMemo, CSSProperties } from 'react'

///////// START OF FA LIBRARY
// https://github.com/FortAwesome/react-fontawesome
// https://github.com/FortAwesome/react-fontawesome#typescript
import {
  library,
  findIconDefinition,
  IconLookup,
  IconDefinition,
  SizeProp,
} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {} from '@fortawesome/pro-light-svg-icons'
import {
  faArrowAltLeft,
  faArrowAltRight,
  faFile,
  faFlaskPotion,
  faDownload,
  faPencilAlt,
  faExclamationCircle,
  faCheckCircle,
  faPaperclip,
  faEye,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBalanceScale,
  faCogs,
  faFileAlt,
  faPaperPlane,
  faTrash,
  faUnlockAlt,
  faUser,
  faUserMd,
  faSignOutAlt,
  faBars,
  faShieldAlt,
  faRabbitFast,
  faCheckSquare,
  faCheck,
  faTimes,
  faSpinnerThird
} from '@fortawesome/pro-solid-svg-icons'

const faIconImports = [
  faArrowAltLeft,
  faArrowAltRight,
  faBalanceScale,
  faCogs,
  faExclamationCircle,
  faFile,
  faFileAlt,
  faFlaskPotion,
  faPaperclip,
  faPaperPlane,
  faTrash,
  faUnlockAlt,
  faUser,
  faUserMd,
  faSignOutAlt,
  faBars,
  faDownload,
  faPencilAlt,
  faTimes,
  faCheckCircle,
  faEye,
  faShieldAlt,
  faRabbitFast,
  faCheckSquare,
  faCheck,
  faSpinnerThird,
]

library.add(...faIconImports)

export const availableIcons = {
  'arrow-alt-left': {
    prefix: 'far',
    iconName: 'arrow-alt-left',
  } as IconLookup,
  'arrow-alt-right': {
    prefix: 'far',
    iconName: 'arrow-alt-right',
  } as IconLookup,
  'balance-scale': {
    prefix: 'fas',
    iconName: 'balance-scale',
  } as IconLookup,
  cogs: {
    prefix: 'fas',
    iconName: 'cogs',
  } as IconLookup,
  'exclamation-circle': {
    prefix: 'far',
    iconName: 'exclamation-circle',
  } as IconLookup,
  'check-circle': {
    prefix: 'far',
    iconName: 'check-circle',
  } as IconLookup,
  file: {
    prefix: 'far',
    iconName: 'file',
  } as IconLookup,
  'file-alt': {
    prefix: 'fas',
    iconName: 'file-alt',
  } as IconLookup,
  'flask-potion': {
    prefix: 'far',
    iconName: 'flask-potion',
  } as IconLookup,
  paperclip: {
    prefix: 'far',
    iconName: 'paperclip',
  } as IconLookup,
  'paper-plane': {
    prefix: 'fas',
    iconName: 'paper-plane',
  } as IconLookup,
  times: {
    prefix: 'fas',
    iconName: 'times',
  } as IconLookup,
  trash: {
    prefix: 'fas',
    iconName: 'trash',
  } as IconLookup,
  'unlock-alt': {
    prefix: 'fas',
    iconName: 'unlock-alt',
  } as IconLookup,
  user: {
    prefix: 'fas',
    iconName: 'user',
  } as IconLookup,
  'user-md': {
    prefix: 'fas',
    iconName: 'user-md',
  } as IconLookup,
  'sign-out-alt': {
    prefix: 'fas',
    iconName: 'sign-out-alt',
  } as IconLookup,
  bars: {
    prefix: 'fas',
    iconName: 'bars',
  } as IconLookup,
  download: {
    prefix: 'far',
    iconName: 'download',
  } as IconLookup,
  'pencil-alt': {
    prefix: 'far',
    iconName: 'pencil-alt',
  } as IconLookup,
  eye: {
    prefix: 'far',
    iconName: 'eye',
  } as IconLookup,
  'shield-alt': {
    prefix: 'fas',
    iconName: 'shield-alt',
  } as IconLookup,
  'rabbit-fast': {
    prefix: 'fas',
    iconName: 'rabbit-fast',
  } as IconLookup,
  'check-square': {
    prefix: 'fas',
    iconName: 'check-square',
  } as IconLookup,
  'check': {
    prefix: 'fas',
    iconName: 'check',
  } as IconLookup,
  'spinner': {
    prefix: 'fas',
    iconName: 'spinner-third',
  } as IconLookup
}

//////// END OF FA LIBRARY

type FaSize = {}

export type FaIconProps = {
  name: keyof typeof availableIcons
  size?: SizeProp
  spin?: boolean
  border?: boolean
  className?: string
  style?: CSSProperties
}

function FaIcon(props: FaIconProps) {
  const iconDefinition = useMemo<IconDefinition>(
    () => findIconDefinition(availableIcons[props.name]),
    [props.name]
  )

  return (
    <FontAwesomeIcon
      icon={iconDefinition}
      size={props.size}
      spin={props.spin}
      border={props.border}
      className={props.className}
      style={props.style}
    />
  )
}

export default FaIcon
