import React from 'react'

const defaultContextState = {
  activeIndex: 0,
  selectStep: (index: number) => {},
}

interface StepsContextType {
  activeIndex: number,
  selectStep: ((index: number) => void),
}

const StepsContext = React.createContext<StepsContextType>(defaultContextState)

export default StepsContext
