import React from 'react'

import { useTheme } from 'styled'

type CharacterCounterProps = {
  maxCharacters: number
  currentCharacters: number
}

function CharacterCounter(props: CharacterCounterProps) {
  const { colors } = useTheme()
  const remainingCharacters = props.maxCharacters - props.currentCharacters

  return remainingCharacters >= 0 ? (
    <div style={{ color: colors['grey-40'] }}>
      {'verbleibende Zeichen: ' + remainingCharacters}
    </div>
  ) : (
    <div style={{ color: colors.danger }}>
      {'überzählige Zeichen: ' + Math.abs(remainingCharacters)}
    </div>
  )
}

export default CharacterCounter
