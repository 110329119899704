import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useTheme } from 'styled'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import InputGrid from 'components/InputGrid'
import Input from 'components/Input'
import Label from 'components/Label'
import RadioGroup from 'components/RadioGroup'
import InputGroup from 'components/InputGroup'
import InputError from 'components/InputError'
import Button from 'components/Button'
import { AlertBox } from 'components/Alert'
import OidLink from 'containers/OidLink'

import { requiredError, tooLongError, emailError } from 'messages/errors'
import { useApi, useYup, useFormFields } from 'hooks'
import * as API from 'api'

const initalFormState = {
  gender: '' as 'male' | 'female' | '',
  title: '',
  surname: '',
  name: '',
  oid: '',
  email: '',
}

function InviteDoctorForm() {
  const { fields, values, reset } = useFormFields(initalFormState)
  const { validate, errors } = useYup(values, validationSchema)
  const [{ data: inviteResult, loading, error }, inviteDoctor] = useApi(
    API.inviteDoctor
  )

  const { spaces } = useTheme()

  const handleDoctorInvite = async () => {
    const { isValid } = await validate()
    if (isValid) {
      await inviteDoctor({ ...values, email: values.email.toLocaleLowerCase() })
    }
  }

  useEffect(() => {
    if (!loading && !error) {
      reset()
    }
  }, [loading, error, reset])

  return (
    <FormGrid>
      <FormDescription>
        <FormDescription.Heading>Neuen Arzt einladen</FormDescription.Heading>
        <FormDescription.Content>
          Bitte f&uuml;llen Sie hier s&auml;mtliche Felder aus, um einen neuen
          Arzt einzuladen.
        </FormDescription.Content>
      </FormDescription>
      <form onSubmit={evt => evt.preventDefault()}>
        <InputGrid>
          {error &&
            ((error as any).response.status === 409 ? (
              <AlertBox type="error">
                An diese E-Mail Adresse wurde bereits eine Einladung gesendet.
              </AlertBox>
            ) : (
              <AlertBox type="error">
                Beim Senden der Einladung ist leider ein Fehler aufgetreten.
              </AlertBox>
            ))}
          {!error && inviteResult && (
            <AlertBox type="success">
              {`Die Einladung an ${
                inviteResult.email
              } wurde erfolgreich versendet.`}
              <p style={{ marginBottom: 0 }}>
                In seltenen F&auml;llen kann es vorkommen, dass eine
                Einladungs-Email vom Mailprovider des Empf&auml;ngers in den
                Spam-Ordner verschoben wurde.
              </p>
            </AlertBox>
          )}
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Anrede</Label>
            <RadioGroup {...fields.gender}>
              <InputGroup>
                <RadioGroup.Button
                  value="male"
                  style={{ flex: '1', textAlign: 'center' }}
                >
                  Herr
                </RadioGroup.Button>
                <RadioGroup.Button
                  value="female"
                  style={{ flex: '1', textAlign: 'center' }}
                >
                  Frau
                </RadioGroup.Button>
              </InputGroup>
            </RadioGroup>
            {errors.gender && <InputError>{errors.gender}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Titel</Label>
            <Input type="text" {...fields.title} />
            {errors.title && <InputError>{errors.title}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Vorname</Label>
            <Input type="text" {...fields.name} />
            {errors.name && <InputError>{errors.name}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Nachname</Label>
            <Input type="text" {...fields.surname} />
            {errors.surname && <InputError>{errors.surname}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Email</Label>
            <Input type="text" {...fields.email} />
            {errors.email && <InputError>{errors.email}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item
            side={'right'}
            responsiveFullAt={'phone'}
            style={{ margin: spaces.stack.l }}
          >
            <Label>&ouml;&auml;K Nr.</Label>
            <Input
              type="text"
              {...fields.oid}
              style={{ margin: spaces.stack.xs }}
            />
            <OidLink />
            {errors.oid && <InputError>{errors.oid}</InputError>}
          </InputGrid.Item>
          <Button
            onClick={handleDoctorInvite}
            variant="primary"
            style={{ justifySelf: 'start' }}
            type="submit"
            disabled={loading}
          >
            <span>Arzt einladen</span>
          </Button>
        </InputGrid>
      </form>
    </FormGrid>
  )
}

const validationSchema = yup.object().shape({
  gender: yup.string().required(requiredError('Anrede')),
  title: yup
    .string()
    .max(40, tooLongError('Titel', 40))
    .required(requiredError('Titel')),
  name: yup
    .string()
    .max(50, tooLongError('Vorname', 50))
    .required(requiredError('Vorname')),
  surname: yup
    .string()
    .max(50, tooLongError('Nachname', 50))
    .required(requiredError('Nachname')),
  email: yup
    .string()
    .email(emailError())
    .max(254, tooLongError('Email', 254))
    .required(requiredError('Email')),
  oid: yup
    .string()
    .max(50, tooLongError('ÖÄK-Arztnummer', 50))
    .required(requiredError('ÖÄK-Arztnummer')),
})

export default InviteDoctorForm
