
import { useEffect } from 'react'

const stage = process.env.REACT_APP_STAGE || ''

const appTitle = 'chefarztantrag.at'
const prefix = stage === 'prod' ? '' : `${stage.toUpperCase()} `

function useDocumentTitle(title: string) {
  useEffect(
    () => {
      document.title = `${prefix}${appTitle}${title ? ` - ${title}` : ''}`;
    },
    [title]
  );
}

export default useDocumentTitle
