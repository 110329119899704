import {
  ClaimDefinition,
  StepDefinitionTypes,
  PharmaCompany,
  DosingFrequencyUnit,
  ClaimClearance,
} from 'claimbuilder'

import infoDoc from './assets/emea-combined-h-4246-de-clean.pdf'
import logo from './assets/elmiron.png'

export const ElmironClaimDefinition: ClaimDefinition = {
  product: {
    name: 'Elmiron',
    informationDocument: infoDoc,
    logo,
    substances: ['Pentosanpolysulfat-Natrium'],
  },
  clearance: ClaimClearance.APPROVED,
  owner: PharmaCompany.SIGMA_PHARM,
  steps: [
    {
      type: StepDefinitionTypes.DOCTOR,
    },
    {
      type: StepDefinitionTypes.PATIENT,
      diagnoseOptions: [
        {
          label: 'Interstitielle Cystitis',
          value:
            'Interstitielle Cystitis: Chronische Blasenschmerzen, die durch viele kleine Blutungen oder markante Läsionen auf der Blasenwand charakterisiert sind und mittelstarke bis starke Schmerzen sowie häufiger Harndrang.',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DOSING,
      unit: 'Hartkapsel',
      frequencyUnit: DosingFrequencyUnit.DAY,
      frequencies: [
        {
          label: 'täglich',
          value: 3,
        },
      ],
      dosings: [
        {
          label: '90 Stück Packung',
          value: 90,
        },
        {
          label: '300 Stück Packung',
          value: 300,
        },
      ],
    },
    {
      type: StepDefinitionTypes.ARGUMENTS,
      arguments: [
        {
          label:
            'einziges verfügbares orales Arzneimittel zur Anwendung bei IC',
          description: '',
        },
        {
          label: 'Schmerzen',
          description:
            'Patient leidet unter starken Schmerzen, erwartete Verbesserung durch die entzündungshemmende Wirkung von elmiron.',
        },
        {
          label: 'Glomerulationen bzw. Läsionen an der Blasenwand vorhanden',
          description:
            'Wiederaufbau des Urothels durch potenzielle Barrierefunktion des elmiron.',
        },
        {
          label: 'Hohe Miktionshäufigkeit',
          description: '',
        },
        {
          label: 'Häufiger, schmerzhafter Harndrang',
          description: '',
        },
        {
          label: 'kein ambulanter Eingriff nötig (vgl. Instillation)',
          description: '',
        },
        {
          label: 'Entzündung des Harntraktes durch Instillation oder Katheter',
          description: '',
        },
        {
          label: 'Schmerzen bei Hydrodistension',
          description: '',
        },
        {
          label: 'Schmerzen bei Gabe von DMSO',
          description: '',
        },
        {
          label: 'chronisches Müdigkeitssyndrom',
          description: '',
        },
        {
          label: 'Depression in Assoziation mit IC',
          description: '',
        },
        {
          label: 'Fibromyalgie',
          description: '',
        },
        {
          label: 'andere Therapieoptionen wirkungslos',
          description: '',
        },
        {
          label: 'Nebenwirkungen bei anderen medikamentösen Therapieoptionen',
          description: '',
        },
        {
          label:
            'Patient verweigert Produkte mit tierischen Nebenstoffen (Gelatine aus Schwein) aus religiösen oder sonstigen Gründen',
          description: '',
        },
        {
          label: 'bereits erfolgte Verschreibung',
          description: 'Patient ist Responder, elmiron zeigt Wirkung',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DISPATCH,
    },
    {
      type: StepDefinitionTypes.SUMMARY,
    },
  ],
}

export default ElmironClaimDefinition
