
import styled from 'styled'
import { Link as ReachLink } from '@reach/router'

const Link = styled(ReachLink)`

  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* aslo change &:visted when changing this */
  color: ${props => props.theme.colors["grey-40"]};

  padding: ${props => props.theme.spaces.insetSquish.m};
  text-decoration: none;

  display: flex;
  align-items: center;

  > * {
    margin: ${props => props.theme.spaces.inline.s};
  }

  &:visited {
    /* should be the same color as the base link color */
    color: ${props => props.theme.colors["grey-40"]};
  }

  &:hover {
    color: ${props => props.theme.colors["grey-30"]};
    text-decoration: none;
  }
`
export default Link;
