import React, { useContext, ReactNode, CSSProperties } from 'react'

import Button from 'components/Button'
import styled from 'styled'

import RadioGroupContext from '../context'

type ReadioGroupButtonProps = {
  value: string | number
  children: ReactNode
  className?: string
  style?: CSSProperties
  disabled?: boolean
}

const RadioBtnComponent = styled((props) => (<Button {...props} as="label" />))`
  margin: 0;
  height: 36px;
  font-size: 16px;
  padding: 10px 20px;
`

function RadioGroupButton(props: ReadioGroupButtonProps) {
  const { name, value, onChange } = useContext(RadioGroupContext)
  const checked = String(value) === String(props.value)

  return (
    <RadioBtnComponent
      variant={checked ? 'primary' : 'secondary'}
      className={props.className}
      style={{ ...props.style }}
      disabled={props.disabled}
    >
      <input
        type="radio"
        checked={checked}
        name={name}
        value={props.value}
        onChange={onChange}
        style={{ display: 'none' }}
        disabled={props.disabled}
      />
      {props.children}
    </RadioBtnComponent>
  )
}

export default RadioGroupButton
