import React, { ReactNode, RefObject, CSSProperties } from 'react'

import useElementRect from 'hooks/useElementRect'



type AlignProps = {
  to: RefObject<HTMLElement>,
  // verticalAlign: 'bottom' | 'center',
  // horizontalAlign: 'left',
  children: ReactNode
}

function haventMeasuredYet(referenceRect: ClientRect | null): referenceRect is null {
  return referenceRect === null
}

function getWrapperStyle(referenceRect: ClientRect | null): CSSProperties {
  if (haventMeasuredYet(referenceRect)) {
    return { opacity: 0 }
  }

  const styles = {
    top: referenceRect.top + referenceRect.height + window.scrollY,
    left: referenceRect.left + window.scrollX,
    width: referenceRect.width
  }
  return {
    position: 'absolute',
    ...styles
  }
}

function getAlignStyle(referenceRect: ClientRect | null): CSSProperties {

  return {
    position: 'absolute',
    right: 0
  }
}

function Align(props: AlignProps) {
  const elementRect = useElementRect(props.to)

  return (
    <div style={getWrapperStyle(elementRect)}>
      <div style={getAlignStyle(elementRect)}>
        {props.children}
      </div>
    </div>
  )
}

export default Align
