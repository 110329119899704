import { hsl } from 'polished'

import { ThemeColors } from '../../ThemeInterface'

const primaryColor = hsl(205, 0.76, 0.39)

const colors: ThemeColors = {
  primary: primaryColor,

  'primary-60': hsl(205, 0.65, 0.55),

  'grey-10': hsl(209, 0.61, 0.16),
  'grey-20': hsl(211, 0.39, 0.23),
  'grey-30': hsl(209, 0.34, 0.3),
  'grey-40': hsl(209, 0.28, 0.39),
  'grey-50': hsl(210, 0.22, 0.49),
  'grey-60': hsl(209, 0.23, 0.6),
  'grey-70': hsl(211, 0.27, 0.7),
  'grey-80': hsl(210, 0.31, 0.8),
  'grey-90': hsl(212, 0.33, 0.89),
  'grey-95': hsl(210, 0.36, 0.96),

  get color() {
    return this['grey-10']
  },

  background: hsl(210, 0.4, 0.98),

  success: '#0E7C86',
  warning: '#F57C00',
  danger: '#BA2525',
}

export default colors
