import styled from 'styled'

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.meta.fixedLayoutWidth}px;
  padding-right: ${props => props.theme.spaces.l};
  padding-left: ${props => props.theme.spaces.l};
  margin-right: auto;
  margin-left: auto;
`

export default Container
