import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import PageHero from 'components/PageHero'
import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import { ProductGrid } from './styled-home'
import ProductCard from 'components/ProductCard'
import Badge from 'components/Badge'

import DefaultPageLayout from 'layout/DefaultPageLayout'

import useUserGroups from 'auth/useUserGroups'

import useDocuemtnTitle from 'hooks/useDocumentTitle'
import { getClaimRoute } from 'pages/routes'

import { allProductDefinitions } from '../Claims/definitions'
import { ClaimClearance, ClaimDefinition, useCompanyLogo } from 'claimbuilder'
import usePharmaCompany from 'auth/usePharmaCompany'

function HomePage(props: RouteComponentProps) {
  useDocuemtnTitle('Neuer Antrag')
  const definitions = useFilteredProductDefinitions()

  return (
    <DefaultPageLayout>
      <PageHero>
        <div style={{ height: '28px' }} /> {/* PlaceHolder for SearchBar */}
      </PageHero>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">
            Produkt Auswahl
          </FormDescription.Heading>
          <FormDescription.Content>
            Bitte wählen Sie hier das entsprechende Produkt für Ihren Antrag
            aus.
          </FormDescription.Content>
        </FormDescription>
        <ProductGrid>
          {definitions.map(def => (
            <Product key={def.product.name} definition={def} />
          ))}
        </ProductGrid>
      </FormGrid>
    </DefaultPageLayout>
  )
}

function Product(props: { definition: ClaimDefinition }) {
  const productInfo = props.definition.product
  const companyLogo = useCompanyLogo(props.definition.owner)
  const path = (productInfo.path || productInfo.name).toLowerCase()

  return (
    <ProductCard
      onClick={() => navigate(getClaimRoute(path))}
    >
      <ProductCard.CompanyLogo src={companyLogo} />
      <ProductCard.ProductLogoWrapper>
        <ProductCard.ProductLogo src={productInfo.logo} />
      </ProductCard.ProductLogoWrapper>
      <ProductCard.Footer>
        {productInfo.substances.length && (
          <Badge icon="flask-potion">{productInfo.substances[0]}</Badge>
        )}
      </ProductCard.Footer>
    </ProductCard>
  )
}

const useFilteredProductDefinitions = () => {
  const { isDemo, isPharma, isAdmin, isDoctor } = useUserGroups()
  const company = usePharmaCompany()

  if (isAdmin) {
    return allProductDefinitions
  }

  if (isDemo) {
    return allProductDefinitions.filter(
      def => def.clearance === ClaimClearance.DEMO
    )
  } else if (isDoctor) {
    return allProductDefinitions.filter(
      def => def.clearance === ClaimClearance.APPROVED
    )
  } else if (isPharma) {
    return allProductDefinitions.filter(
      def =>
        def.owner === company &&
        (def.clearance === ClaimClearance.APPROVAL_REQUIRED ||
          def.clearance === ClaimClearance.APPROVED)
    )
  }
  return []
}

export default HomePage
