import React, { ReactNode, CSSProperties } from 'react'

import {
  Container,
  TopLine,
  CompanyLogo,
  ProductLogo,
  ProductLogoWrapper,
  Footer,
} from './styled-card'

type ProductCardProps = {
  onClick?: () => void
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

function ProductCard(props: ProductCardProps) {
  return (
    <Container
      className={props.className}
      style={props.style}
      onClick={props.onClick}
    >
      <TopLine />
      {props.children}
    </Container>
  )
}

ProductCard.CompanyLogo = CompanyLogo
ProductCard.ProductLogo = ProductLogo
ProductCard.ProductLogoWrapper = ProductLogoWrapper
ProductCard.Footer = Footer

export default ProductCard
