import API from '@aws-amplify/api'

import config from 'aws-exports'
import { Sickfund, SickfundService } from 'types'

const API_URL = config.Api.endpoint
const API_NAME = 'api_gateway'
API.configure({
  endpoints: [
    {
      name: API_NAME,
      endpoint: API_URL,
      region: 'eu-central-1',
    },
  ],
})

async function getSickfunds() {
  const data = await API.get(API_NAME, '/sickfund', {})
  return data as Sickfund[]
}

async function getSickfundService(sickfundId: string) {
  const data = await API.get(API_NAME, `/sickfund/${sickfundId}/service`, {})
  return data as SickfundService[]
}

async function deleteSickfund(sickfundId: string) {
  const data = await API.del(API_NAME, `/sickfund/${sickfundId}`, {})
  return data as Sickfund
}

async function updateSickfund(sickfund: Sickfund) {
  const data = await API.put(API_NAME, `/sickfund/${sickfund.id}`, {
    body: sickfund,
  })
  return data
}

async function createSickfund(sickfund: Omit<Sickfund, 'id'>) {
  const data = await API.post(API_NAME, `/sickfund`, { body: sickfund })
  return data
}

async function deleteSickfundService(sickfundId: string, serviceId: string) {
  const data = await API.del(
    API_NAME,
    `/sickfund/${sickfundId}/service/${serviceId}`,
    {}
  )
  return data as SickfundService
}

async function updateSickfundService(service: SickfundService) {
  const data = await API.put(
    API_NAME,
    `/sickfund/${service.sickfundId}/service/${service.id}`,
    { body: service }
  )
  return data
}

async function createSickfundService(service: Omit<SickfundService, 'id'>) {
  const data = await API.post(
    API_NAME,
    `/sickfund/${service.sickfundId}/service`,
    { body: service }
  )
  return data
}

export {
  getSickfunds,
  getSickfundService,
  deleteSickfund,
  updateSickfund,
  deleteSickfundService,
  updateSickfundService,
  createSickfund,
  createSickfundService,
}
