import React from 'react'

import { ClaimStepDefinition, StepDefinitionTypes } from 'claimbuilder/types'
import { useClaim } from 'claimbuilder/ClaimContext'
import Steps from 'components/Steps'
import { DoctorStep } from './DoctorStep'
import { PatientStep } from './PatientStep'
import { DefaultDosingStep } from './DefaultDosingStep'
import { DefaultArgumentStep } from './DefaultArgumentStep'
import { DispatchStep } from './DispatchStep'
import { SummaryStep } from './SummaryStep'

interface ClaimStepPanesProps {}

export const ClaimStepPanes: React.FC<ClaimStepPanesProps> = props => {
  const steps = useClaim().definition.steps

  return (
    <Steps.Panes>
      {steps.map(step => (
        <Steps.Pane key={step.type}>
          <ClaimStepNegotiator step={step} />
        </Steps.Pane>
      ))}
    </Steps.Panes>
  )
}

interface ClaimStepNegotiatorProps {
  step: ClaimStepDefinition
}

const ClaimStepNegotiator: React.FC<ClaimStepNegotiatorProps> = ({ step }) => {
  const child = useClaimComponent(step)
  return <>{child}</>
}

const useClaimComponent = (step: ClaimStepDefinition) => {
  const { customSteps } = useClaim()
  const customStep = customSteps.find(custom => custom.type === step.type)
  
  if (customStep) {
    return customStep.component
  }

  switch (step.type) {
    case StepDefinitionTypes.DOCTOR:
      return <DoctorStep />
    case StepDefinitionTypes.PATIENT:
      return <PatientStep />
    case StepDefinitionTypes.DOSING:
      return <DefaultDosingStep />
    case StepDefinitionTypes.ARGUMENTS:
      return <DefaultArgumentStep />
    case StepDefinitionTypes.DISPATCH:
      return <DispatchStep />
    case StepDefinitionTypes.SUMMARY:
      return <SummaryStep />
    default:
      return <>'not implemented yet'</>
  }
}
