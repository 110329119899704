import React, { ReactNode } from 'react'

import Heading from './Heading'
import Content from './Content'

type FormDescriptionProps = {
  children: ReactNode
}

function FormDescription(props: FormDescriptionProps) {

  return (
    <div>
      {props.children}
    </div>   
  )
}

FormDescription.Heading = Heading
FormDescription.Content = Content

export default FormDescription
