import React from 'react'
import { useTheme } from 'styled'

import InputGrid from 'components/InputGrid'
import Button from 'components/Button'

import { getTermsRoute } from 'pages/routes'

import { LogInDescriptionText } from './styled-login'

type NewPasswordRequiredFormProps = {
  confirm: () => any
  loading: boolean
}

function NewPasswordRequiredForm(props: NewPasswordRequiredFormProps) {
  const { spaces } = useTheme()

  return (
    <form onSubmit={event => event.preventDefault()}>
      <InputGrid>
        <LogInDescriptionText style={{ textAlign: 'center' }}>
          Bitte akzeptieren Sie unsere{' '}
          <a href={getTermsRoute()} target="_blank" rel="noopener noreferrer">
            AGBs
          </a>
          .
        </LogInDescriptionText>
        <Button
          style={{ width: '100%', margin: spaces.stack.s }}
          type="submit"
          variant="primary"
          onClick={props.confirm}
          disabled={props.loading}
        >
          Akzeptieren
        </Button>
      </InputGrid>
    </form>
  )
}

export default NewPasswordRequiredForm
