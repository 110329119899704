import React, { useState, useRef, ReactNode, RefObject } from 'react'

import { MenuProvider } from './context'
import Button from './Button'
import MenuList from './MenuList'
import MenuListItem from './MenuListItem'

type MenuProps = {
  children: ReactNode,
}

function Menu({ children }: MenuProps) {
  const [isOpen, setIsOpen] = useState(false)
  // const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)
  const buttonRef = useRef<HTMLElement | null>(null)
  const menuListRef = useRef<HTMLElement | null>(null)

  const contextState = {
    isOpen,
    setIsOpen,
    toggleOpen: () => setIsOpen(prevIsOpen => !prevIsOpen),
    registerButton: (ref: RefObject<HTMLElement>) => { buttonRef.current = ref.current },
    registerMenuList: (ref: RefObject<HTMLElement>) => { menuListRef.current = ref.current },
    buttonRef,
    menuListRef,
  }

  return (
    <MenuProvider value={contextState}>
      {children}
    </MenuProvider>
  )
}

Menu.Button = Button
Menu.List = MenuList
Menu.Item = MenuListItem

export default Menu;
