import React, { useContext, FunctionComponent } from 'react'
import { RouteComponentProps } from '@reach/router'

import { UserGroup } from 'types'

import Login from 'pages/Login'
import Forbidden from 'pages/Forbidden'

import useUserInGroup from 'auth/useUserInGroup'

import authContext from './context'

type PrivateRouteProps<T extends RouteComponentProps> = T & {
  as: FunctionComponent<T>,
  withGroup?: UserGroup | UserGroup[]
}

function PrivateRoute<T extends RouteComponentProps>({ as: Component, withGroup, ...props }: PrivateRouteProps<T>) {
  const { authenticated, initialized } = useContext(authContext)
  const userInGroup = useUserInGroup(withGroup)
  if (!initialized) { return null }

  if (authenticated && (!withGroup || userInGroup))  {
    return <Component {...(props as unknown as T)} />
  }
  if (authenticated && withGroup) {
    return <Forbidden />
  }
  return <Login />
}

export default PrivateRoute
