import { useState } from 'react'

import { forgotPasswordSubmit as forgotPasswordSubmitFunction } from './util'

export interface ForgotPasswordSubmitError extends Error {
  name: 'InvalidParameterException' | 'CodeMismatchException' | 'ExpiredCodeException'
}

/**
 * react hook to request a new cognito password
 * 
 * error.name types:
 *  - InvalidParameterException: wrong username
 *  - CodeMismatchException: Invalid verification code provided
 *  - ExpiredCodeException: Invalid code provided, please request a code again.
 */
function useForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ForgotPasswordSubmitError | null>(null)
  const [success, setSuccess] = useState<boolean | null>(null)

  const forgotPasswordSubmit = async (username: string, code: string, password: string) => {
    let err: ForgotPasswordSubmitError | null = null
    try {
      setLoading(true)
      await forgotPasswordSubmitFunction(username.toLowerCase(), code, password)
    } catch (error) {
      err = error
    } finally {
      setError(err)
      setLoading(false)
      setSuccess(err === null)
    }
    return { error: err, success: err === null}
  }

  return {
    forgotPasswordSubmit,
    loading,
    success,
    error
  }
}

export default useForgotPassword
