import React, { useContext, CSSProperties } from 'react'

import stepsContext from '../context'

import StyledStepList from './StepList'

export interface StepListChildProps {
  isActive?: boolean
  disabled?: boolean
  onSelect?: () => void
}

type StepListProps = {
  children: React.ReactElement<StepListChildProps>[]
  className?: string
  style?: CSSProperties
}

function StepList({ children, ...props }: StepListProps) {
  const { activeIndex, selectStep } = useContext(stepsContext)

  const steps = React.Children.map(children, (child, index) =>
    React.cloneElement(child as React.ReactElement<any>, {
      isActive: index === activeIndex,
      onSelect: () => selectStep(index),
      disabled: index > activeIndex,
    })
  )

  return <StyledStepList {...props}>{steps}</StyledStepList>
}

export default StepList
