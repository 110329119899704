import React, {
  useContext,
  useRef,
  useEffect,
  ReactNode,
  CSSProperties,
} from 'react'
import styled from 'styled'

import Portal from 'components/Portal'
import Align from 'components/Align'

import context from './context'

type MenuListProps = {
  children: ReactNode
  className?: string
  style?: CSSProperties
}

const StyledList = styled.ul`
  display: inline-block;
  list-style: none;
  padding: ${props => props.theme.spaces.xs} 0;
  margin: 0;
  background: ${props => props.theme.colors.background};

  border-radius: 5px;
  overflow: hidden; /* required fro border radius */

  box-shadow: 0 10px 20px #26262633, 0 3px 6px #82828225;

  z-index: 1000;
`

type ListComponentProps = {
  children: ReactNode
  className?: string
  style?: CSSProperties
}

function ListComponent(props: ListComponentProps) {
  const { registerMenuList, buttonRef, setIsOpen } = useContext(context)
  const listRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    registerMenuList(listRef)
    if (listRef.current) {
      listRef.current.focus()
    }
  }, [registerMenuList])

  return (
    <StyledList
      ref={listRef}
      role="menu"
      tabIndex={-1}
      onBlur={event => {
        if (
          listRef.current &&
          !listRef.current.contains(event.relatedTarget as Node) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.relatedTarget as Node)
        ) {
          setIsOpen(false)
        }
      }}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </StyledList>
  )
}

function MenuList(props: MenuListProps) {
  const { isOpen, buttonRef } = useContext(context)

  if (!isOpen || !buttonRef.current) {
    return null
  }

  return (
    <Portal>
      <Align to={buttonRef}>
        <ListComponent className={props.className} style={props.style}>
          {props.children}
        </ListComponent>
      </Align>
    </Portal>
  )
}

export default MenuList
