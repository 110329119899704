import React, { useState, useEffect, ReactNode, useCallback } from 'react'
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { HubCapsule } from '@aws-amplify/core/lib/Hub';

import AWSConfig from '../aws-exports'
import { CognitoUserInfo } from 'types'

import Context, { defaultContextState } from './context'

// type HubCapsule = {
//   channnel: string,
//   payload: {
//     event: 'signIn' | 'signUp' | 'signOut' | 'signIn_failure' | 'configured'
//   },
// }

Auth.configure(AWSConfig.Auth);

const getUserInfo = async () => {
  try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true// Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
      const session = await Auth.currentSession()
      const groups = session.getIdToken().payload['cognito:groups']
      
      return {
        ...user,
        groups,
      }
  } catch {
    return;
  }
}

const AuthProvider = (props: { children: ReactNode }) => {
  const [authState, setAuthState] = useState(defaultContextState)

  const setUserInfo =  useCallback(
    async () => {
    const userInfo = await getUserInfo() as CognitoUserInfo
    setAuthState({
      initialized: true,
      authenticated: !!userInfo,
      userInfo: userInfo
    })
  }
  , [])

  const onHubCapsule = useCallback(async (capsule: HubCapsule) => {
    switch (capsule.payload.event) {
      case 'signIn': 
        setUserInfo()
        break
      case 'signOut':
        setAuthState({ ...defaultContextState, initialized: true })
        break
      case 'signIn_failure':
        console.log('sign in failed')
        break
      case 'configured':
        setUserInfo()
        break;
      default: 
        console.warn(`AuthProvider: Amplify Hub event '${capsule.payload.event}' not implemented.`)
    }
  }, [setUserInfo])

  useEffect(() => {
    setUserInfo()
    Hub.listen('auth', onHubCapsule, 'AuthProvider')
  }, [onHubCapsule, setUserInfo])

  return (
    <Context.Provider value={authState} >
      {props.children}
    </Context.Provider>
  )
}


export default AuthProvider
