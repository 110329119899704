import React, { MouseEvent } from 'react'
import { navigate } from '@reach/router'
import HeaderMenuLink from 'components/HeaderMenuLink'
import MobileHeaderMenuLink from './MobileMenuLink'
import useMedia from 'hooks/useMedia'
import Menu from 'components/Menu'
import {
  getHomeRoute,
  getAdministrationRoute,
  getProfileRoute,
} from 'pages/routes'
import FaIcon from 'components/FaIcon'

import { useAuthentication, useUserInfo } from 'auth'
import useUserGroups from 'auth/useUserGroups'

import {
  HeaderBar,
  ContentArea,
  LogoText,
  TopMenu,
  MobileMenuButton,
  MobileMenuList,
} from './styled-menu'

import Container from '../Container'

function TopMenuBar() {
  const { signOut } = useAuthentication()
  const userInfo = useUserInfo()
  const { isPharma, isAdmin } = useUserGroups()
  const showAdministration = isPharma || isAdmin

  const { isPhone, isTablet } = useMedia()

  const logout = async (evt: MouseEvent<HTMLElement>) => {
    evt.preventDefault()
    await signOut()
    navigate('/')
  }

  return (
    <HeaderBar>
      <Container>
        <ContentArea>
          <LogoText onClick={() => navigate('/')}>chefarztantrag.at</LogoText>
          {userInfo && (
            <TopMenu>
              {isPhone || isTablet ? (
                <Menu>
                  <MobileMenuButton>
                    <FaIcon name="bars" />
                  </MobileMenuButton>
                  <MobileMenuList>
                    <Menu.Item key={'newClaim'}>
                      <MobileHeaderMenuLink to={getHomeRoute()}>
                        Neuer Antrag
                      </MobileHeaderMenuLink>
                    </Menu.Item>
                    <Menu.Item key={'profil'}>
                      <MobileHeaderMenuLink to={getProfileRoute()}>
                        Profil
                      </MobileHeaderMenuLink>
                    </Menu.Item>
                    {showAdministration && (
                      <Menu.Item key={'administration'}>
                        <MobileHeaderMenuLink to={getAdministrationRoute()}>
                          Administration
                        </MobileHeaderMenuLink>
                      </Menu.Item>
                    )}
                    <Menu.Item key={'signOut'}>
                      <MobileHeaderMenuLink to="/logout" onClick={logout}>
                        Abmelden
                      </MobileHeaderMenuLink>
                    </Menu.Item>
                  </MobileMenuList>
                </Menu>
              ) : (
                <>
                  <HeaderMenuLink to={getHomeRoute()}>
                    Neuer Antrag
                  </HeaderMenuLink>
                  <HeaderMenuLink to={getProfileRoute()}>Profil</HeaderMenuLink>
                  {showAdministration && (
                    <HeaderMenuLink to={getAdministrationRoute()}>
                      Administration
                    </HeaderMenuLink>
                  )}
                  <HeaderMenuLink to="/logout" onClick={logout}>
                    Abmelden
                  </HeaderMenuLink>
                </>
              )}
            </TopMenu>
          )}
        </ContentArea>
      </Container>
    </HeaderBar>
  )
}

export default TopMenuBar
