
interface LoginError extends Error {
  name: 'UserNotFoundException' | 'NotAuthorizedException'
}

export const getLoginApiError = (error: LoginError | null) => {
  if (error) {
    switch (error.name) {
      case 'UserNotFoundException':
        return 'Die E-Mail Adresse wurde nicht gefunden.'
      case 'NotAuthorizedException':
        return 'Falsche E-Mail/Passwort Kombination.'
    }
    return 'Es ist ein Fehler aufgetreten.'
  }
  return null
}


interface ChangePasswordError extends Error {
  name: 'InvalidParameterException' | 'InvalidPasswordException' | 'NotAuthorizedException' | 'LimitExceededException'
}

export const getChangePasswordApiError = (error: ChangePasswordError | null) => {
  if (error) {
    switch (error.name) {
      case 'InvalidPasswordException':
        return 'Passwort erfüllt nicht die minimalen Anforderungen.'
      case 'NotAuthorizedException':
        return 'Ihr aktuelles Passwort ist nicht korrekt.'
      case 'LimitExceededException':
        return 'Sie haben es zu oft versucht. Probieren Sie es später noch einmal.'
      case 'InvalidParameterException':
        return 'Ihr Passwort erfüllt nicht die notwendigen Anfroderungen.'
    }
    return 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Administrator.'
  }
  return null
}
