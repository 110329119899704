import React, { useContext, useEffect, useRef } from 'react'

import Button, { ButtonProps } from 'components/Button'

import context from './context'

function MenuButton(props: ButtonProps) {
  const { toggleOpen, registerButton } = useContext(context)
  const buttonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    registerButton(buttonRef)
  }, [registerButton])

  return (
    <Button
      ref={buttonRef}
      {...props}
      onClick={(...args) => {
        toggleOpen()
        if (props.onClick) {
          props.onClick(...args)
        }
      }}
    />
  )
}

export default MenuButton
