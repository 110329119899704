import React, { SyntheticEvent } from 'react'
import * as yup from 'yup'

import Seperator from 'components/Seperator'
import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import InputError from 'components/InputError'
import RadioGroup from 'components/RadioGroup'

import styled, { useTheme } from 'styled'
import { useFormFields, useYup, useMedia } from 'hooks'
import { requiredError, tooLongError } from 'messages/errors'
import { useClaim, ClaimStepFooter } from 'claimbuilder'
import {
  isDosingStepDefinition,
  DefaultDosingStepDefinition,
  DosingFrequencyDefinition,
  DosingFrequencyUnit,
  ClaimDefinition,
} from 'claimbuilder/types'
import Input from 'components/Input'
import { ClaimData } from 'claimbuilder/ClaimContext'

export const DefaultDosingStep = () => {
  const { isPhone, isTablet } = useMedia()
  const { spaces } = useTheme()

  const { fields, values, setValue } = useFormFields(initialFormFields)
  const { validate, errors } = useYup(values, validationSchema)

  const claim = useClaim()
  const {
    frequencies,
    dosings,
    unit,
    frequencyUnit,
    description,
  } = claim.definition.steps.find(
    isDosingStepDefinition
  ) as DefaultDosingStepDefinition

  const { setData } = useClaim()
  const handleNext = async (evt: SyntheticEvent<HTMLButtonElement>) => {
    const { isValid } = await validate()
    if (isValid) {
      const dosing = formFieldsToClaimDosing(values, claim.definition)
      setData(prev => ({
        ...prev,
        dosing,
      }))
    } else {
      evt.preventDefault()
    }
  }

  const getFrequencyLabel = () => {
    const frequency = frequencies.find(freq => freq.value === values.frequency)
    return !!frequency ? frequency.label : 'nie'
  }

  const dosingCols = getDosingMatrixColumns(dosings.length)

  let dosingFactor = 0
  if (frequencyUnit === DosingFrequencyUnit.DAY) {
    dosingFactor = 4 * 7
  } else if (frequencyUnit === DosingFrequencyUnit.WEEK) {
    dosingFactor = 4
  } else if (frequencyUnit === DosingFrequencyUnit.MONTH) {
    dosingFactor = 1
  }

  return (
    <>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">Dosierung</FormDescription.Heading>
          <FormDescription.Content>
            {description ||
              'Bitte wählen Sie hier die Frequenz, sowie die Dosierung aus.'}
          </FormDescription.Content>
        </FormDescription>
        <PickArea>
          <div>
            <RadioGroup
              value={values.frequency}
              name="frequency"
              onChange={({ currentTarget: { value } }) => {
                if (value === 'CUSTOM') {
                  setValue('frequency', value)
                } else {
                  setValue('frequency', Number(value))
                }
              }}
            >
              <FrequencyMatrix>
                <DosingLabel>Frequenz</DosingLabel>
                {frequencyUnit === DosingFrequencyUnit.DAY ? (
                  <DayFreequencyOptions frequencies={frequencies} />
                ) : (
                  <WeekFreequencyOptions frequencies={frequencies} />
                )}
              </FrequencyMatrix>
            </RadioGroup>
            {errors.frequency && <InputError>{errors.frequency}</InputError>}
          </div>
          <div>
            <RadioGroup
              onChange={evt =>
                setValue('dose', Number(evt.currentTarget.value))
              }
              {...fields.dose}
            >
              <DosingMatrix cols={dosingCols}>
                <DosingLabel
                  style={{
                    whiteSpace: 'nowrap',
                    gridColumn: `1/${dosingCols + 1}`,
                  }}
                >
                  Dosis in {unit}
                </DosingLabel>
                {dosings.map(dosing => (
                  <RadioGroup.Button
                    key={dosing.value}
                    value={dosing.value}
                    style={{ fontSize: '13px' }}
                    disabled={values.frequency === 'CUSTOM' ? true : false}
                  >
                    {dosing.label || dosing.value}
                  </RadioGroup.Button>
                ))}
              </DosingMatrix>
            </RadioGroup>
            {errors.dose && <InputError>{errors.dose}</InputError>}
          </div>
        </PickArea>
      </FormGrid>
      <Seperator />

      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">Monatsdosis</FormDescription.Heading>
          {values.frequency === 'CUSTOM' ? (
            <FormDescription.Content
              style={!isPhone && !isTablet ? { marginBottom: '0' } : undefined}
            >
              Bei Auswahl einer "Sonstigen" Frequenz is hier die Frequenz und
              Dosis manuell einzugeben, sowie der Verbrauch über den Zeitraum
              von 4 Wochen manuell zu berechnen.
            </FormDescription.Content>
          ) : (
            <FormDescription.Content
              style={!isPhone && !isTablet ? { marginBottom: '0' } : undefined}
            >
              {`Hier wird anhand der ausgewählten Frequenz und Dosis der
              Verbrauch über den Zeitraum von 4 Wochen automatisch
              kalkuliert.`}
            </FormDescription.Content>
          )}
        </FormDescription>
        <div>
          <DosingLabel style={{ margin: spaces.stack.xl }}>
            {!isPhone && !isTablet ? 'Verbrauch über 4 Wochen' : 'Verbrauch'}
          </DosingLabel>
          {values.frequency === 'CUSTOM' ? (
            <div style={{ gridColumn: '2/3' }}>
              <Input type="text" {...fields.custom} />
              {errors.custom && <InputError>{errors.custom}</InputError>}
            </div>
          ) : (
            <SumArea>
              <SumOperand>{getFrequencyLabel()}</SumOperand>
              <SumOperator
                style={isPhone || isTablet ? { fontSize: '22px' } : undefined}
              >
                X
              </SumOperator>
              <SumOperand>
                {fields.dose.value === 0
                  ? `0 ${unit}`
                  : fields.dose.value.toLocaleString('de') + ` ${unit}`}
              </SumOperand>
              <SumOperator>=</SumOperator>
              <SumResult>
                {!values.frequency
                  ? `0 ${unit}`
                  : `${(
                      (dosingFactor / values.frequency) *
                      values.dose
                    ).toLocaleString('de')} ${unit}`}
              </SumResult>
            </SumArea>
          )}
        </div>
      </FormGrid>

      <ClaimStepFooter onCommit={handleNext} />
    </>
  )
}

const getDosingMatrixColumns = (count: number) => {
  const min = 5
  const max = 8
  let bestCol = min
  let minError = Number.MAX_VALUE
  if (count < min) {
    return 4
  }
  for (let i = min; i <= max; i++) {
    const error = count % i
    if (error <= minError) {
      minError = error
      bestCol = i
    }
  }
  return bestCol
}

export const PickArea = styled.div`
  display: grid;

  grid-template-columns: auto auto;
  ${props => props.theme.media.tablet`grid-template-columns: auto;`}

  justify-content: center;
  grid-column-gap: 85px;
  grid-row-gap: ${props => props.theme.spaces.l};
`

export const DosingLabel = styled.div`
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 3px;
  color: ${props => props.theme.colors['grey-70']};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: ${props => props.theme.spaces.s};
  grid-column-start: 1;
  grid-column-end: -1;
`

export const Matrix = styled.div`
  display: grid;

  > * {
    border-radius: 5px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const FrequencyMatrix = styled(Matrix)`
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 4px;
  grid-column-gap: 4px;
  justify-items: stretch;
`

export const DosingMatrix = styled(Matrix)<{ cols?: number }>`
  grid-template-columns: repeat(${props => props.cols || 5}, 40px);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
`

export const SumArea = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  ${props => props.theme.media.tablet`grid-template-columns: auto;`}
  justify-content: center;
  align-items: center;
  grid-column-gap: ${props => props.theme.spaces.l};

  > * {
    font-weight: 600;
    letter-spacing: 3px;
    text-align: center;
  }
`

export const SumOperand = styled.div`
  font-size: 22px;
  color: ${props => props.theme.colors['grey-50']};
`

export const SumOperator = styled.div`
  font-size: 26px;
  color: ${props => props.theme.colors['grey-80']};
`

export const SumResult = styled.div`
  font-size: 26px;
  color: ${props => props.theme.colors['grey-50']};
`

const validationSchema = yup.object().shape({
  frequency: yup
    .number()
    .required(requiredError('Frequenz'))
    .test('gt0', requiredError('Frequenz'), function(value) {
      return value > 0
    }),

  dose: yup.number().test('dose', requiredError('Dosis'), function(value) {
    return this.parent.frequency === 'other' || value
  }),

  custom: yup
    .string()
    .max(100, tooLongError('Verbrauch', 100))
    .test(
      'custom',
      'Bitte geben Sie Frequenz und Dosis, sowie den berechneten Verbrauch über 4 Wochen hier ein,',
      function(value) {
        return this.parent.frequency !== 'other' || value
      }
    ),
})

const initialFormFields = {
  frequency: 0 as number | 'CUSTOM',
  dose: 0,
  custom: '',
}

const formFieldsToClaimDosing = (
  values: typeof initialFormFields,
  definition: ClaimDefinition
): ClaimData['dosing'] => {
  if (values.frequency === 'CUSTOM') {
    return {
      description: values.custom,
      monthDescription: '',
    }
  }

  const step = definition.steps.find(
    isDosingStepDefinition
  ) as DefaultDosingStepDefinition

  const getFrequencyLabel = () => {
    const frequency = step.frequencies.find(
      freq => freq.value === values.frequency
    )
    return !!frequency ? frequency.label || frequency.value : 'ERROR'
  }

  if (step.frequencyUnit === DosingFrequencyUnit.WEEK) {
    const monthDose = (4 / values.frequency) * values.dose
    return {
      description: `${getFrequencyLabel()} ${values.dose} ${step.unit}`,
      monthDescription: `ergibt einen durchschnittlichen Verbrauch über 4 Wochen von ${monthDose.toLocaleString(
        'de'
      )} ${step.unit}`,
    }
  } else if (step.frequencyUnit === DosingFrequencyUnit.DAY) {
    const monthDose = ((4 * 7) / values.frequency) * values.dose
    const label =
      values.frequency === 1 ? 'täglich' : `alle ${values.frequency} Tage`
    return {
      description: `${label} ${values.dose} ${step.unit}`,
      monthDescription: `ergibt einen durchschnittlichen Verbrauch über 4 Wochen von ${monthDose.toLocaleString(
        'de'
      )} ${step.unit}`,
    }
  }

  throw new Error('frequency unit not defined or not implemented')
}

interface FrequencyOptionsProps {
  frequencies: DosingFrequencyDefinition[]
}

const WeekFreequencyOptions = ({ frequencies }: FrequencyOptionsProps) => {
  return (
    <>
      {frequencies.map(frequency => (
        <RadioGroup.Button
          key={frequency.value}
          value={frequency.value}
          style={{ fontSize: '14px', gridColumn: '1/5' }}
        >
          {frequency.label}
        </RadioGroup.Button>
      ))}
    </>
  )
}

const DayFreequencyOptions = ({ frequencies }: FrequencyOptionsProps) => {
  const { spaces } = useTheme()
  const dailyOption = frequencies.find(freq => freq.value === 1)
  const customOption = frequencies.find(freq => freq.value === 'CUSTOM')
  const otherOptions = frequencies.filter(
    freq => freq.value !== 1 && freq.value !== 'CUSTOM'
  )
  return (
    <>
      {!!dailyOption && (
        <RadioGroup.Button
          key={dailyOption.value}
          value={dailyOption.value}
          style={{
            fontSize: '14px',
            gridColumnStart: 1,
            gridColumnEnd: 5,
            margin: spaces.stack.m,
          }}
        >
          {dailyOption.label}
        </RadioGroup.Button>
      )}

      {!!otherOptions.length && (
        <>
          <div
            style={{
              height: '36px',
            }}
          >
            alle:
          </div>
          {otherOptions.map(option => (
            <RadioGroup.Button
              key={option.value}
              value={option.value}
              style={{ fontSize: '14px' }}
            >
              {option.label}
            </RadioGroup.Button>
          ))}
          <div
            style={{
              gridColumnStart: 3,
              gridColumnEnd: 5,
              height: '36px',
            }}
          >
            Tage
          </div>
        </>
      )}
      {!!customOption && (
        <RadioGroup.Button
          key={customOption.value}
          value={customOption.value}
          style={{
            fontSize: '14px',
            gridColumnStart: 1,
            gridColumnEnd: 5,
            marginTop: spaces.m,
          }}
        >
          {customOption.label}
        </RadioGroup.Button>
      )}
    </>
  )
}
