import CuvitruClaimDefinition from './Cuvitru/definition'
import ElmironClaimDefinition from './Elmiron/definition'
import ExempliGratiaClaimDefinition from './ExempliGratia/definition'
import HyQviaClaimDefinition from './HyQvia/definition'
import MetamorphosisClaimDefinition from './Metamorphoses/definition'
import NatparClaimDefinition from './Natpar/definition'
import RevestiveClaimDefinition from './Revestive/definition'
import TakhzyroClaimDefinition from './Takhzyro/definition'

export const allProductDefinitions = [
  ExempliGratiaClaimDefinition,
  MetamorphosisClaimDefinition,

  CuvitruClaimDefinition,
  ElmironClaimDefinition,
  HyQviaClaimDefinition,
  NatparClaimDefinition,
  RevestiveClaimDefinition,
  TakhzyroClaimDefinition,
]
