import styled from 'styled'

const PrescribingInfoLink = styled.a`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 6px;

  color: ${props => props.theme.colors['grey-60']};
  :hover {
    color: ${props => props.theme.colors.primary};
  }
`

export default PrescribingInfoLink
