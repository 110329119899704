import React, { CSSProperties, useCallback, MouseEvent } from 'react'
import { LinkGetProps } from '@reach/router'
import { useTheme } from 'styled'
import { MobileMenuLink } from './styled-menu'

type MobileHeaderMenuLinkProps = {
  to?: string
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

function MobileHeaderMenuLink(props: MobileHeaderMenuLinkProps) {
  const { style, ...linkProps } = props
  const getLinkProps = useLinkProps(style)
  return <MobileMenuLink {...linkProps} getProps={getLinkProps} />
}

function useLinkProps(style?: CSSProperties) {
  const theme = useTheme()
  return useCallback(
    ({ isCurrent }: LinkGetProps) => ({
      // please DO NOT return className here
      // since it would break the styled-components Link element
      style: {
        ...style,
        color: isCurrent
          ? theme.colors['grey-30']
          : style
          ? style.color
          : undefined,
        borderRight: isCurrent
          ? '5px solid'
          : style
          ? style.borderBottom
          : undefined,
        borderColor: isCurrent
          ? theme.colors.primary
          : style
          ? style.borderColor
          : undefined,
        padding: isCurrent
          ? '8px 15px 8px 0'
          : style
          ? style.paddingBottom
          : '8px 20px 8px 0',
        marginLeft: theme.spaces.l,
        marginRight: '13px',
        display: 'flex',
        justifyContent: 'flex-end',
      },
    }),
    [style, theme.colors, theme.spaces.l]
  )
}

export default MobileHeaderMenuLink
