import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { useTheme } from 'styled'

import PageHero from 'components/PageHero'

import DefaultPageLayout from 'layout/DefaultPageLayout'

const Privacy = (props: RouteComponentProps) => {
  const { spaces, colors } = useTheme()
  return (
    <DefaultPageLayout>
      <PageHero>
        <PageHero.Title>Datenschutzerkl&auml;rung</PageHero.Title>
      </PageHero>
      <div style={{ color: colors['grey-30'] }}>
        <div style={{ margin: spaces.stack.m }}>Version vom 13.03.2019</div>
      </div>
      <h2>Datenschutz</h2>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        Wir haben diese Datenschutzerkl&auml;rung verfasst, um Ihnen
        gem&auml;&szlig; der Vorgaben der Datenschutz-Grundverordnung (EU)
        2016/679 und dem{' '}
        <a
          style={{ color: colors['grey-30'] }}
          href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=211089048"
        >
          Datenschutzgesetz (DSG)
        </a>{' '}
        zu erkl&auml;ren, welche Informationen wir sammeln, wie wir Daten
        verwenden und welche Entscheidungsm&ouml;glichkeiten Sie als Besucher
        dieser Webseite haben.
      </p>
      <p
        style={{
          hyphens: 'auto',
          textAlign: 'justify',
          margin: spaces.stack.xl,
        }}
      >
        Leider liegt es in der Natur der Sache, dass diese Erkl&auml;rungen sehr
        technisch klingen, wir haben uns bei der Erstellung jedoch bem&uuml;ht
        die wichtigsten Dinge so einfach und klar wie m&ouml;glich zu
        beschreiben.
      </p>

      <h2>Automatische Datenspeicherung</h2>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
        automatisch erstellt und gespeichert, so auch auf dieser Webseite.
      </p>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
        Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch
        Daten wie
      </p>
      <ul>
        <li>die Adresse (URL) der aufgerufenen Webseite</li>
        <li>Browser und Browserversion</li>
        <li>das verwendete Betriebssystem</li>
        <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
        <li>
          den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen
          wird
        </li>
        <li>Datum und Uhrzeit</li>
      </ul>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        in Dateien (Webserver-Logfiles).
      </p>
      <p
        style={{
          hyphens: 'auto',
          textAlign: 'justify',
          margin: spaces.stack.xl,
        }}
      >
        In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
        danach automatisch gel&ouml;scht. Wir geben diese Daten nicht weiter,
        k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim
        Vorliegen von rechtswidrigem Verhalten eingesehen werden. Die
        Rechtsgrundlage besteht nach{' '}
        <a
          style={{ color: colors['grey-30'] }}
          href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=211089048"
        >
          Artikel 6 Absatz 1 f DSGVO
        </a>{' '}
        (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass berechtigtes
        Interesse daran besteht, den fehlerfreien Betrieb dieser Webseite durch
        das Erfassen von Webserver-Logfiles zu erm&ouml;glichen.
      </p>
      <h2>Speicherung pers&ouml;nlicher Daten</h2>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        Pers&ouml;nliche Daten, die Sie uns auf dieser Website elektronisch
        &uuml;bermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
        andere pers&ouml;nlichen Angaben im Rahmen der &Uuml;bermittlung eines
        Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem
        Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck
        verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
      </p>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        Wir nutzen Ihre pers&ouml;nlichen Daten somit nur f&uuml;r die
        Kommunikation mit jenen Besuchern, die Kontakt ausdr&uuml;cklich
        w&uuml;nschen und f&uuml;r die Abwicklung der auf dieser Webseite
        angebotenen Dienstleistungen und Produkte. Wir geben Ihre
        pers&ouml;nlichen Daten ohne Zustimmung nicht weiter, k&ouml;nnen jedoch
        nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von
        rechtswidrigem Verhalten eingesehen werden.
      </p>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        Wenn Sie uns pers&ouml;nliche Daten per E-Mail schicken &ndash; somit
        abseits dieser Webseite &ndash; k&ouml;nnen wir keine sichere
        &Uuml;bertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen
        Ihnen, vertrauliche Daten niemals unverschl&uuml;sselt per E-Mail zu
        &uuml;bermitteln.
      </p>
      <p
        style={{
          hyphens: 'auto',
          textAlign: 'justify',
          margin: spaces.stack.xl,
        }}
      >
        Die Rechtsgrundlage besteht nach{' '}
        <a
          style={{ color: colors['grey-30'] }}
          href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211089048"
        >
          Artikel 6 Absatz 1 a DSGVO
        </a>{' '}
        (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass Sie uns die
        Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben.
        Sie k&ouml;nnen diesen Einwilligung jederzeit widerrufen &ndash; eine
        formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.
      </p>
      <h2>Rechte laut Datenschutzgrundverordnung</h2>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        Ihnen stehen laut den Bestimmungen der DSGVO und des
        &ouml;sterreichischen{' '}
        <a
          style={{ color: colors['grey-30'] }}
          href="https://www.firmenwebseiten.at/datenschutz-generator/datenschutz-generator-text/"
        >
          Datenschutzgesetzes (DSG)
        </a>{' '}
        grunds&auml;tzlich die folgende Rechte zu:
      </p>
      <ul>
        <li>Recht auf Berichtung (Artikel 16 DSGVO)</li>
        <li>
          Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
        </li>
        <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
        <li>
          Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit
          der Berichtigung oder Löschung personenbezogener Daten oder der
          Einschränkung der Verarbeitung (Artikel 19 DSGVO)
        </li>
        <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
        <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
        <li>
          Recht, nicht einer ausschließlich auf einer automatisierten
          Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
          unterworfen zu werden (Artikel 22 DSGVO)
        </li>
      </ul>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
        sonst in einer Weise verletzt worden sind, können Sie sich bei der
        Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde
        ist, deren Webseite Sie unter{' '}
        <a
          style={{ color: colors['grey-30'] }}
          href="https://www.dsb.gv.at/?tid=211089048"
        >
          https://www.dsb.gv.at/
        </a>{' '}
        finden.
      </p>
      <h2>Auswertung des Besucherverhaltens</h2>
      <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
        In der folgenden Datenschutzerkl&auml;rung informieren wir Sie
        dar&uuml;ber, ob und wie wir Daten Ihres Besuchs dieser Website
        auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel
        anonym und wir k&ouml;nnen von Ihrem Verhalten auf dieser Website nicht
        auf Ihre Person schlie&szlig;en.
      </p>
      <p
        style={{
          hyphens: 'auto',
          textAlign: 'justify',
          margin: spaces.stack.xl,
        }}
      >
        Mehr &uuml;ber M&ouml;glichkeiten dieser Auswertung der Besuchsdaten zu
        widersprechen erfahren Sie in der folgenden Datenschutzerkl&auml;rung.
      </p>
      <h2>TLS-Verschlüsselung mit https</h2>
      <p
        style={{
          hyphens: 'auto',
          textAlign: 'justify',
          margin: spaces.stack.xl,
        }}
      >
        Wir verwenden https um Daten abh&ouml;rsicher im Internet zu
        &uuml;bertragen (Datenschutz durch Technikgestaltung{' '}
        <a
          style={{ color: colors['grey-30'] }}
          href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211089058"
        >
          Artikel 25 Absatz 1 DSGVO
        </a>
        ). Durch den Einsatz von TLS (Transport Layer Security), einem
        Verschl&uuml;sselungsprotokoll zur sicheren Daten&uuml;bertragung im
        Internet k&ouml;nnen wir den Schutz vertraulicher Daten sicherstellen.
        Sie erkennen die Benutzung dieser Absicherung der Daten&uuml;bertragung
        am kleinen Schlosssymbol links oben im Browser und der Verwendung des
        Schemas https (anstatt http) als Teil unserer Internetadresse
      </p>

      <div
        style={{
          margin: spaces.stack.xxl,
        }}
      >
        Quelle: Erstellt mit dem{' '}
        <a
          style={{ color: colors['grey-30'] }}
          href="https://www.firmenwebseiten.at/datenschutz-generator/"
        >
          Datenschutz Generator von firmenwebseiten.at
        </a>{' '}
        in Kooperation mit{' '}
        <a style={{ color: colors['grey-30'] }} href="https://www.movetec.at">
          movetec.at/
        </a>
        .
      </div>
    </DefaultPageLayout>
  )
}

export default Privacy
