import React, { useContext } from 'react'
import {
  ClaimDefinition,
  PharmaCompany,
  ArgumentDefinition,
  ClaimClearance,
  StepDefinitionTypes,
} from './types'

export interface ClaimData {
  doctor: {
    gender: 'male' | 'female'
    name: string
    organisation: string
    department: string
    street: string
    zip: string
    city: string
    phone: string
    fax: string

    isHospital: boolean
  }
  patient: {
    gender: 'male' | 'female'
    name: string
    birthdate: Date
    svn: string
    weight: number
    diagnose: string
  }
  dosing: {
    description: string
    monthDescription: string
  }
  arguments: ArgumentDefinition[]
  dispatch: {
    organisation: string
    department: string
    street: string
    zip: string
    city: string
    phone: string
    fax: string
    email: string

    isSickfundService: boolean
    sickfundServiceEmailAllowed: boolean
    sickfundServiceSecureEmail: boolean
  }
}

export const getInitialClaimData = (): ClaimData => ({
  doctor: {
    gender: 'female',
    name: '',
    organisation: '',
    department: '',
    street: '',
    zip: '',
    city: '',
    phone: '',
    fax: '',

    isHospital: false,
  },
  patient: {
    gender: 'female',
    name: '',
    birthdate: new Date(),
    svn: '',
    weight: NaN,
    diagnose: '',
  },
  dosing: {
    description: '',
    monthDescription: '',
  },
  arguments: [],
  dispatch: {
    organisation: '',
    department: '',
    street: '',
    zip: '',
    city: '',
    phone: '',
    fax: '',
    email: '',

    isSickfundService: false,
    sickfundServiceEmailAllowed: false,
    sickfundServiceSecureEmail: false,
  },
})

export interface CustomStepRegistryItem {
  type: StepDefinitionTypes,
  component: React.ReactNode
}

export interface ContextValue {
  definition: ClaimDefinition
  data: ClaimData
  setData: React.Dispatch<React.SetStateAction<ClaimData>>
  customSteps: CustomStepRegistryItem[]
  registerCustomStep: (item: CustomStepRegistryItem) => void
  unregisterCustomStep: (type: StepDefinitionTypes) => void
}

export const ClaimContext = React.createContext<ContextValue>({
  definition: {
    product: {
      name: 'NO CONTEXT VALUE',
      logo: '',
      substances: ['MOCK'],
      informationDocument: 'not_available',
    },
    owner: PharmaCompany.DEMO,
    clearance: ClaimClearance.DEVELOPMENT,
    steps: [],
  },
  data: getInitialClaimData(),
  setData: () => {},
  customSteps: [],
  registerCustomStep: () => {},
  unregisterCustomStep: () => {}
})

export const ClaimProvider = ClaimContext.Provider

export const useClaim = () => {
  return useContext(ClaimContext)
}
