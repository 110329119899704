import API from '@aws-amplify/api'

import config from 'aws-exports'

const API_URL = config.Api.endpoint
const API_NAME = 'api_gateway'
API.configure({
  endpoints: [
    {
      name: API_NAME,
      endpoint: API_URL,
      region: 'eu-central-1',
    },
  ],
})

async function inviteDemo(object: { email: string }) {
  const data = await API.post(API_NAME, '/invite/demo', {
    body: {...object, email: object.email.toLowerCase() }
  })

  return data
}

async function inviteDoctor(object: { email: string }) {
  const data = await API.post(API_NAME, '/invite/doctor', {
    body: {...object, email: object.email.toLowerCase() }
  })

  return data
}

async function invitePharma(object: { email: string }) {
  const data = await API.post(API_NAME, '/invite/pharma', {
    body: {...object, email: object.email.toLowerCase() }
  })

  return data
}

async function inviteAdmin(object: { email: string }) {
  const data = await API.post(API_NAME, '/invite/admin', {
    body: {...object, email: object.email.toLowerCase() }
  })

  return data
}


export { inviteDoctor, invitePharma, inviteDemo, inviteAdmin }
