import API from '@aws-amplify/api'

import config from 'aws-exports'

const API_URL = config.Api.endpoint
const API_NAME = 'api_gateway'
API.configure({
  endpoints: [
    {
      name: API_NAME,
      endpoint: API_URL,
      region: 'eu-central-1',
    },
  ],
})

type SendMailParams = {
  subject: string
  from: string
  to: string
  selfBcc: boolean
  content: string
  files: File[]
}
function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // @ts-ignore
      let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = error => reject(error);
  });
}

const mapFile = async (file: File) => {
  const base64String = await getBase64(file)
  return {
    name: file.name,
    size: file.size,
    fileBase64: base64String,
    type: file.type,
    lastModified: file.lastModified
  }
}

export const sendMail = async ({ files, ...options }: SendMailParams) => {
  const encodedFiles = await Promise.all(files.map(mapFile))
  const response = await API.post(API_NAME, `/send/mail`, {
    body: {
      ...options,
      files: encodedFiles
    }
  })
  return response as any
}
