import { useEffect } from 'react'

import * as API from 'api'
import { useAlert } from "components/Alert";
import { useApi } from "hooks";

interface UseSignatureStatus {
  quietMode?: boolean
}


const useSignatureStatus = (options: UseSignatureStatus = {}) => {
  const [alert] = useAlert()

  const [
    { data: sigResponse, error: sigStatusError },
    getSignatureStatus,
  ] = useApi(API.getSignatureStatus)

  useEffect(() => {
    getSignatureStatus()
  }, [getSignatureStatus])

  const quietMode = !!options.quietMode
  useEffect(() => {
    if (sigStatusError && !quietMode) {
      alert.error('Fehler beim Laden des Signatur Status')
    }
  }, [alert, sigStatusError, quietMode])

  return [sigResponse ? sigResponse.status : null]
}

export default useSignatureStatus
