import React from 'react'
import { Link, navigate } from '@reach/router'
import Obfuscate from 'react-obfuscate'

import {
  getTermsRoute,
  getDisclosureRoute,
  getDataPrivacyRoute,
} from 'pages/routes'

import {
  Wrapper,
  ContainerGrid,
  LinkList,
  LinkListItem,
  Logo,
} from './styled-footer'

const stage = process.env.REACT_APP_STAGE || ''
const logoPrefix = stage === 'prod' ? '' : `${stage.toUpperCase()} `

const FooterNavigation = () => (
  <LinkList>
    <LinkListItem>
      <Link to={getDisclosureRoute()}>Impressum</Link>
    </LinkListItem>
    <LinkListItem>
      <Link to={getTermsRoute()}>AGB</Link>
    </LinkListItem>
    <LinkListItem>
      <Link to={getDataPrivacyRoute()}>Datenschutzerklärung</Link>
    </LinkListItem>
  </LinkList>
)

const Contact = () => (
  <div style={{ fontSize: 12 }}>
    <div>do.vision GmbH</div>
    <div>
      <Obfuscate email="office@chefarztantrag.at" />
    </div>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://do.vision/"
      >
        https://do.vision
      </a>
    </div>
  </div>
)

function Footer() {
  return (
    <Wrapper>
      <ContainerGrid>
        <Logo onClick={() => navigate('/')}>{logoPrefix}chefarztantrag.at</Logo>
        <FooterNavigation />
        <Contact />
      </ContainerGrid>
    </Wrapper>
  )
}

export default Footer
