import React, { ReactNode } from 'react'

import { InputGridContainer, Item } from './InputGrid'

type InputGridProps = {
  children: ReactNode
}

function InputGrid(props: InputGridProps) {
  return (
    <InputGridContainer>
      {props.children}
    </InputGridContainer>
  )
}

InputGrid.Item = Item

export default InputGrid
