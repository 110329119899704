import React, { useEffect, CSSProperties, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled'
import { Router, RouteComponentProps, navigate } from '@reach/router'

import * as API from 'api'
import { useApi, useFormFields } from 'hooks'
import { getAdministrationRoute } from 'pages/routes'
import DefaultPageLayout from 'layout/DefaultPageLayout'

import PageHero from 'components/PageHero'
import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import InputGrid from 'components/InputGrid'
import Label from 'components/Label'
import Input from 'components/Input'
import Spinner from 'components/Spinner'
import Link from 'components/HeaderMenuLink'
import Button from 'components/Button'
import { SickfundService } from 'types'

import EditSickfundService from './EditSickfundService'

const SickfundAdminEdit = ({
  sickfundId = '',
  uri
}: RouteComponentProps & { sickfundId?: string }) => {
  const { colors } = useTheme()
  const isNew = sickfundId === 'new'
  const [sickfund, loading] = useSickfund(sickfundId)
  const initialValues = useMemo(
    () => ({
      ...initialSickfundFields,
      ...(sickfund || {}),
    }),
    [sickfund]
  )
  const { reset, fields, values } = useFormFields(initialValues)
  useEffect(() => {
    reset()
  }, [reset, initialValues])

  const [delResponse, deleteSickfund] = useApi(API.deleteSickfund)
  const deleted = delResponse.calledOnce && !delResponse.error
  useEffect(() => {
    if (deleted) {
      navigate(getAdministrationRoute())
    }
  }, [deleted])
  const handleDelete = () => {
    if (
      sickfund &&
      // eslint-disable-next-line no-restricted-globals
      confirm(`Sie wollen die Kasse ${sickfund.name} wirklich löschen?`)
    ) {
      deleteSickfund(sickfund.id)
    }
  }

  const [updateReponse, updateSickfund] = useApi(API.updateSickfund)
  const updated = updateReponse.calledOnce && !updateReponse.error
  useEffect(() => {
    if (updated) {
      navigate(getAdministrationRoute())
    }
  }, [updated])
  const handleUpdate = () => {
    updateSickfund({ id: sickfundId, ...values })
  }

  const [createRes, create] = useApi(API.createSickfund)
  const created = createRes.calledOnce && !createRes.error
  useEffect(() => {
    if (created) {
      navigate(getAdministrationRoute())
    }
  }, [created])
  const handleCreate = () => {
    create(values)
  }

  const handleSave = () => {
    if (isNew) {
      handleCreate()
    } else {
      handleUpdate()
    }
  }

  const [serviceListKey, setServiceListKey] = useState(1)
  const newServiceList = () => {
    setServiceListKey(k => k + 1)
  }

  return (
    <DefaultPageLayout>
      <PageHero>
        <PageHero.Title>Krankenkasse ({sickfundId})</PageHero.Title>
      </PageHero>
      {loading && <Spinner />}

      {!loading && (
        <>
          <FormGrid>
            <FormDescription>
              <FormDescription.Heading as="h2">Daten</FormDescription.Heading>
              <FormDescription.Content />
            </FormDescription>

            <InputGrid>
              <InputGrid.Item side={'left'}>
                <Label>Abkürzung (REQUIRED)</Label>
                <Input {...fields.name} type="text" />
              </InputGrid.Item>
              <InputGrid.Item side={'right'}>
                <Label>Name</Label>
                <Input {...fields.longName} type="text" />
              </InputGrid.Item>

              <InputGrid.Item
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {!isNew && (
                  <Button
                    style={{ background: colors.danger, marginRight: 'auto' }}
                    variant="primary"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  variant="secondary"
                  onClick={() => navigate(getAdministrationRoute())}
                  style={{ marginRight: 16 }}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                  Save
                </Button>
              </InputGrid.Item>
            </InputGrid>
          </FormGrid>

          {!isNew && (
            <>
              <FormGrid>
                <FormDescription>
                  <FormDescription.Heading as="h2">
                    Servicestellen
                  </FormDescription.Heading>
                  <FormDescription.Content />
                </FormDescription>
                <div>
                  <SickfundServices
                    sickfundId={sickfundId}
                    key={serviceListKey}
                  />
                  <Button onClick={() => navigate(`${uri}/new`)} variant="primary">New</Button>
                </div>
              </FormGrid>

              <Router>
                <EditSickfundService
                  path={`:serviceId`}
                  onUpdate={newServiceList}
                />
              </Router>
            </>
          )}
        </>
      )}
    </DefaultPageLayout>
  )
}

const initialSickfundFields = {
  name: '',
  longName: '',
}

const useSickfund = (id: string = 'INVALID') => {
  const [response, getSickfunds] = useApi(API.getSickfunds)
  const sickfunds = response.data
  useEffect(() => {
    getSickfunds()
  }, [getSickfunds])

  const sickfund = (sickfunds || []).find(sf => sf.id === id) || null

  return [sickfund, response.loading] as [typeof sickfund, boolean]
}

const Table = styled.table`
  width: 100%;

  & th {
    text-align: left;
  }

  & td {
    padding: 0px 8px;
  }
`

function SickfundServices({ sickfundId }: { sickfundId: string }) {
  const [response, getSickfundService] = useApi(API.getSickfundService)
  const services = response.data || []
  useEffect(() => {
    getSickfundService(sickfundId)
  }, [getSickfundService, sickfundId])

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {services.map((service, idx) => (
          <SickfundServiceRow service={service} index={idx} key={service.id} />
        ))}
      </tbody>
    </Table>
  )
}

interface SickfundServiceRowProps {
  service: SickfundService
  index: number
}

function SickfundServiceRow({ service, index }: SickfundServiceRowProps) {
  const { colors } = useTheme()
  const style: CSSProperties = {}
  if (index % 2) {
    style.background = colors['grey-90']
  }

  return (
    <tr style={style}>
      <td>{service.name}</td>
      <td>
        <Link to={service.id}>Edit</Link>
      </td>
    </tr>
  )
}

export default SickfundAdminEdit
