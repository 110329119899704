import API from '@aws-amplify/api'

import config from 'aws-exports'

const API_URL = config.Api.endpoint
const API_NAME = 'api_gateway'
API.configure({
  endpoints: [
    {
      name: API_NAME,
      endpoint: API_URL,
      region: 'eu-central-1',
    },
  ],
})

export const getSignatureStatus = async () => {
  const response = await API.get(API_NAME, '/signature/status', {})
  return response as { status: 'AVAILABLE' | 'MISSING' }
}
