import {
  ClaimDefinition,
  StepDefinitionTypes,
  PharmaCompany,
  DosingFrequencyUnit,
  ClaimClearance,
} from 'claimbuilder'

import infoDoc from './assets/Fachinformation_Revestive 1.25mg & 5mg Stand 05_20.pdf'
import logo from './assets/revestive.png'

import givenAttachment1 from './assets/F_FI_20-05-20_Revestive_1_25mg.pdf'
import givenAttachment2 from './assets/F_FI_20-05-20_Revestive_5mg.pdf'
import givenAttachment3 from './assets/Teduglutide in ped patients_12 weeks data_Carter BA, et al. J Pediatrics 2016.pdf'
import givenAttachment4 from './assets/Teduglutide in ped patients_24 weeks data_Kocoshis SA et al., J Parenter Enteral Nutr 2019.pdf'
import givenAttachment5 from './assets/STEPS-1 Zulassungsstudie_Jeppesen PB, et al. Gastroenterology. 2012;143.pdf'
import givenAttachment6 from './assets/STEPS-2 Verlängerungsstudie 24 Monate _Schwartz LK et al., Clin Transl Gastroenterol 2016;7.pdf'
import givenAttachment7 from './assets/STEPS-3 Verlängerungsstudie 42 Monate_Seidner DL et al., Nutr Clin Pract 2018;33.pdf'

export const RevestiveClaimDefinition: ClaimDefinition = {
  product: {
    path: 'revestive',
    name: 'Revestive®',
    informationDocument: infoDoc,
    logo: logo,
    substances: ['Teduglutid'],
  },
  clearance: ClaimClearance.APPROVED,
  owner: PharmaCompany.TAKEDA,
  givenAttachments: {
    sendingPortalLabel: 'Fachinformation & Studien anfügen',
    attachments: [
      {
        fileSrc: givenAttachment1,
        label: 'F_FI_20-05-20_Revestive_1_25mg',
      },
      {
        fileSrc: givenAttachment2,
        label: 'F_FI_20-05-20_Revestive_5mg',
      },
      {
        fileSrc: givenAttachment3,
        label:
          'Teduglutide in ped patients_12 weeks data_Carter BA, et al. J Pediatrics 2016',
      },
      {
        fileSrc: givenAttachment4,
        label:
          'Teduglutide in ped patients_24 weeks data_Kocoshis SA et al., J Parenter Enteral Nutr 2019',
      },
      {
        fileSrc: givenAttachment5,
        label:
          'STEPS-1 Zulassungsstudie_Jeppesen PB, et al. Gastroenterology. 2012;143.pdf',
      },
      {
        fileSrc: givenAttachment6,
        label:
          'STEPS-2 Verlängerungsstudie 24 Monate _Schwartz LK et al., Clin Transl Gastroenterol 2016;7',
      },
      {
        fileSrc: givenAttachment7,
        label:
          'STEPS-3 Verlängerungsstudie 42 Monate_Seidner DL et al., Nutr Clin Pract 2018;33',
      },
    ],
  },
  steps: [
    {
      type: StepDefinitionTypes.DOCTOR,
    },
    {
      type: StepDefinitionTypes.PATIENT,
      diagnoseOptions: [
        {
          label: 'KDS-DV',
          value: `Kurzdarmsyndrom mit chronischem Darmversagen und dauerhaftem parenteralen Support bestehend aus:
- Energie (Glucose, Fett- und Aminosäuren)
- Essentiellen Nährstoffen (essentielle Fett- und Aminosäuren)
- Vitaminen (doppelte Tagesdosis an D, A, E, K und wasserlöslicher Komplex)
- Elektrolyten (Calcium, Natrium, Chlorid, Magnesium, Kalium, Phosphat)
- Bicarbonat
- Spurenelementen (Eisen, Selen, Zink, Kupfer, Mangan, Molybdän, Jod, Fluor)
- Flüssigkeit
`
        },
      ],
    },
    {
      type: StepDefinitionTypes.DOSING,
      unit: 'mg',
      frequencyUnit: DosingFrequencyUnit.DAY,
      frequencies: [
        {
          label: 'täglich',
          value: 1,
        },
      ],
      dosings: [
        {
          value: 1.25,
        },
        {
          value: 5,
        },
      ],
    },
    {
      type: StepDefinitionTypes.ARGUMENTS,
      arguments: [
        {
          label: 'Wirkungsweise',
          description:
            'Die Verabreichung von Teduglutid stimuliert die mesenteriale Perfusion, aktiviert proabsorptive Signalwege, verbessert die Nährstoffabsorption, stärkt die Barrierefunktion des Darms und induziert proliferative und zytoprotektive Signalwege im Dünndarm. In klinischen Studien wurde eine verbesserte Resorption von Makronährstoffen und Elektrolyten sowie eine verringerte stomale oder fäkale Flüssigkeits- und Makronährstoffausscheidung und eine verbesserte strukturelle und funktionelle Anpassung der Darmmukosa nachgewiesen.',
        },
        {
          label:
            'Kurzdarmsyndrom mit chronischem Darmversagen und intestinaler Insuffizienz',
        },
        {
          label:
            'Dauerhafter Bedarf einer parenteraler Ernährungstherapie',
        },
        {
          label:
            'Reduktion der parenteralen Ernährungstherapie wird angestrebt',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DISPATCH,
    },
    {
      type: StepDefinitionTypes.SUMMARY,
    },
  ],
}

export default RevestiveClaimDefinition
