import React from 'react'
import styled from 'styled'

import Title from 'components/Title'
import Button from 'components/Button'

const ErrorCode = styled.span`
  color: ${props => props.theme.colors['grey-90']};
  font-size: 132px;
  margin: 20px 0 0 0;
  font-weight: bold;
`

class ContentErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: any) {
    console.error(error, info)
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorCode>Fehler</ErrorCode>
          <Title style={{ marginTop: 0, marginBottom: 10 }}>
            Ein Fehler ist aufgetreten.
          </Title>
          <p style={{ marginTop: 0 }}>
            Es tut uns Leid, dass es zu diesem Fehler gekommen ist.
          </p>
          <div>
            <Button style={{ marginRight: 18 }} onClick={() => window.history.back()} variant="primary" >
              Zurück
            </Button>
            <Button onClick={() => window.location.replace('/')} >
              Seite neu laden
            </Button>
          </div>
        </>
      )
    }
    return this.props.children
  }
}

export default ContentErrorBoundary
