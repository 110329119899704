import styled from 'styled'

const Content = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors['grey-30']};
  line-height: 22px;
  margin: ${props => props.theme.spaces.stack.l};

  > p {
    margin: ${props => props.theme.spaces.stack.xs};
  }
`

export default Content
