import { useState, useEffect, useCallback } from 'react'

function useHover(ref: React.RefObject<HTMLElement>) {
  const [hovered, setHovered] = useState(false)

  const onMouseEnter = useCallback(() => {
    setHovered(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setHovered(false)
  }, [])

  useEffect(() => {
    const { current } = ref
    if (current) {
      current.addEventListener('mouseenter', onMouseEnter)
      current.addEventListener('mouseleave', onMouseLeave)
    }
    return () => {
      if (current) {
        current.removeEventListener('mouseenter', onMouseEnter)
        current.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [onMouseEnter, onMouseLeave, ref])

  return [hovered] as [boolean]
}

export default useHover
