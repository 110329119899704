import styled, { css } from 'styled'
import { ThemeProps } from 'styled-components'
import ThemeInterface from 'styled/themes/ThemeInterface'

type GetThemePropFn = (props: ThemeProps<ThemeInterface>) => string

interface StyledStepProps {
  isActive?: boolean
  disabled?: boolean
  onClick?: () => void
}

const backgroundColor: GetThemePropFn = props => props.theme.colors['grey-80']
const color: GetThemePropFn = props => props.theme.colors['grey-30']

const hoverBackgroundColor: GetThemePropFn = props =>
  props.theme.colors['grey-70']
const hoverColor: GetThemePropFn = props => props.theme.colors['grey-30']

const activeBackgroundColor: GetThemePropFn = props =>
  props.theme.colors.primary
const activeColor = '#fff'

const disabledBackgroundColor: GetThemePropFn = props =>
  props.theme.colors['grey-90']
const disabledColor: GetThemePropFn = props => props.theme.colors['grey-40']

const activeStyle = css`
  a {
    background: ${activeBackgroundColor};
    border-color: ${activeBackgroundColor};
    color: ${activeColor};
  }
`

const hoverStyle = css`
  a {
    cursor: pointer;
  }

  a:hover {
    background: ${hoverBackgroundColor};
    border-color: ${hoverBackgroundColor};
    color: ${hoverColor};
  }
`

const disabledStyle = css`
  a {
    background: ${disabledBackgroundColor};
    border-color: ${disabledBackgroundColor};
    color: ${disabledColor};
  }
`

const Step = styled.li<StyledStepProps>`
  position: relative;
  padding: 0;
  margin: 0 4px 0 0;
  list-style-type: none;
  width: 100%;

  user-select: none;

  a {
    padding-left: 20px;
    height: 46px;
    color: ${color};
    background: ${backgroundColor};
    /* the border color is used to style its ::after pseudo-element */
    border-color: ${backgroundColor};

    text-decoration: none;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: ${props => props.theme.fonts.default};
    font-size: 14px;
    letter-spacing: 1px;

    cursor: default;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:first-of-type > * {
    padding-left: 0;
    border-radius: 5px 0 0 5px;
  }

  &:last-of-type > * {
    border-radius: 0 5px 5px 0;
  }

  &::after,
  > *::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    /* 46px is the height of the <a> element */
    border: 23px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;

    /* this is the colored triangle after each element */
    z-index: 2;
    border-left-color: inherit;
  }

  &::after {
    /* this is the white separator between two items */
    z-index: 1;
    transform: translateX(3px);
    border-left-color: ${props => props.theme.colors.background};
  }

  &:last-of-type::after,
  :last-of-type > *::after {
    /* hide the triangle after the last step */
    display: none;
  }

  ${props => {
    if (props.isActive) {
      return activeStyle
    } else if (props.disabled) {
      return disabledStyle
    } else if (props.onClick) {
      return hoverStyle
    }
  }}
`

export default Step
