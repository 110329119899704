import {
  ClaimDefinition,
  StepDefinitionTypes,
  PharmaCompany,
  DosingFrequencyUnit,
  ClaimClearance,
} from 'claimbuilder'

import infoDoc from './assets/2020-07-24 Takhzyro IB-0016_reduction shelf life_FI.pdf'
import logo from './assets/TAKHZYRO_Global_Logo_Color_RGB.png'

export const TakhzyroClaimDefinition: ClaimDefinition = {
  product: {
    name: 'Takhzyro',
    informationDocument: infoDoc,
    logo: logo,
    substances: ['Lanadelumab'],
  },
  clearance: ClaimClearance.APPROVED,
  owner: PharmaCompany.TAKEDA,
  steps: [
    {
      type: StepDefinitionTypes.DOCTOR,
    },
    {
      type: StepDefinitionTypes.PATIENT,
      diagnoseOptions: [
        {
          label: 'Hereditäres Angioödem Typ I',
          value:
            'Es liegt ein hereditäres Angioödem (HAE) Typ I vor, welches auf einer erniedrigten Konzentration des C1-INH beruht.',
        },
        {
          label: 'Hereditäres Angioödem Typ II',
          value:
            'Es liegt ein hereditäres Angioödem (HAE) Typ II vor, welches auf normalen oder erhöhten Konzentrationen an nicht-funktionsfähigem C1-INH beruht.',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DOSING,
      unit: 'mg',
      frequencyUnit: DosingFrequencyUnit.MONTH,
      frequencies: [
        {
          label: 'alle 2 Wochen',
          value: 2,
        },
        {
          label: 'alle 4 Wochen',
          value: 1,
        },
      ],
      dosings: [
        {
          value: 300,
        },
      ],
    },
    {
      type: StepDefinitionTypes.ARGUMENTS,
      arguments: [
        {
          label: 'Häufige Attacken',
          description: '',
        },
        {
          label: 'Schweregrad der Attacken',
          description: '(hohe Schmerzintensität)',
        },
        {
          label:
            'Larynxattacke mit potentiellem Erstickungstod in der Anamnese',
          description: '',
        },
        {
          label: 'Mangelnde Krankheitskontrolle bei Akuttherapie',
          description: '',
        },
        {
          label:
            'Mangelnde Krankheitskontrolle und Durchbruchsattacken bei Prophylaxe mit C1-INH Therapie',
          description: '',
        },
        {
          label: 'Ungenügendes Ansprechen auf Therapie mit Androgenen',
          description: '',
        },
        {
          label:
            'Kontraindikation von Androgenen aufgrund von Komorbiditäten / Kinderwunsch',
          description: '',
        },
        {
          label:
            'Selbstinjektion durch Patient nicht möglich aufgrund regelmäßig geschwollener Hände bei Attacken',
          description: '',
        },
        {
          label: 'Schlechte Venensituation',
          description: '',
        },
        {
          label:
            'Psychische Belastung: Angstzustände / Depression durch mangelnde Krankheitskontrolle',
          description: '',
        },
        {
          label: 'Einschränkung der Lebensqualität',
          description: '',
        },
        {
          label: 'Hoher Leidensdruck',
          description: '',
        },
        {
          label: 'Fehlzeiten in Arbeit',
          description: '',
        },
        {
          label: 'Fehlzeiten in Ausbildung',
          description: '',
        },
        {
          label: 'Unzureichende Patientenadhärenz unter aktueller Therapie',
          description: '',
        },
        {
          label:
            'Erhöhtes Risiko bei akutem Notfall (z.B. Larynxattacke) aufgrund sprachlicher Barrieren, Kommunikationsproblemen',
          description: '',
        },
        {
          label:
            'Erhöhtes Risiko bei akutem Notfall (z.B. Larynxattacke) aufgrund der Familiensituation (AlleinerzieherIn, kleine Kinder)',
          description: '',
        },
        {
          label:
            'Erhöhtes Risiko bei akutem Notfall (z.B. Larynxattacke) aufgrund von Problemen bei der Selbstinjektion',
          description: '',
        },
        {
          label:
            'Erhöhtes Risiko bei akutem Notfall (z.B. Larynxattacke) aufgrund der großen Entfernung zum nächsten Arzt / Krankenhaus',
          description: '',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DISPATCH,
    },
    {
      type: StepDefinitionTypes.SUMMARY,
    },
  ],
}

export default TakhzyroClaimDefinition
