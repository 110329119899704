import React from 'react'

import PrescribingInfoLink from './styled-prescribing-info'

type PrescribingInfoProps = {
  pdfLink: string
}

function PrescribingInfo({ pdfLink }: PrescribingInfoProps) {
  return (
    <PrescribingInfoLink href={pdfLink} target="_blank">
      Fachinformation
    </PrescribingInfoLink>
  )
}

export default PrescribingInfo
