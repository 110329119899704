import { useContext } from 'react'

import StepsContext from './context'

function useStepControls() {
  const { activeIndex, selectStep } = useContext(StepsContext)

  return {
    nextStep: () => selectStep(activeIndex + 1),
    prevStep: () => selectStep(Math.max(activeIndex - 1, 0)),
    goToStep: (stepIndex: number) => selectStep(stepIndex)
  }
}

export default useStepControls
