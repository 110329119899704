import React, { CSSProperties, useState } from 'react'
import uuid from 'uuid/v4'
import Input from './Input'

type CheckboxProps = {
  id?: string,
  label?: string,
  name?: string,
  checked?: boolean,
  disabled?: boolean,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onBlur?: React.ChangeEventHandler<HTMLInputElement>,
  className?: string,
  style?: CSSProperties,
}

function Checkbox(props: CheckboxProps) {
  const [stateId] = useState(uuid())
  const { id: propId, label, className, style, ...inputProps } = props

  const id = propId || stateId
  return (
    <div>
      <Input {...inputProps} type="checkbox" id={id} />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  )
}

export default Checkbox
