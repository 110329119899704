import { useReducer } from 'react'
import Auth from '@aws-amplify/auth'

type AuthChallenge = 'NEW_PASSWORD_REQUIRED' | 'CUSTOM_CHALLENGE' | undefined

type SignInAction = {
  type: 'SIGN_IN'
}

type SignInFailedAction = {
  type: 'SIGN_IN_FAILURE'
  payload: { error: Error }
}

type SignInSuccessAction = {
  type: 'SIGN_IN_SUCCESS'
  payload: { user: any }
}

type CompleteNewPasswordAction = {
  type: 'COMPLETE_NEW_PASSWORD'
}

type CompleteNewPasswordSuccessAction = {
  type: 'COMPLETE_NEW_PASSWORD_SUCCESS'
  payload: { user: any }
}

type CompleteNewPasswordFailedAction = {
  type: 'COMPLETE_NEW_PASSWORD_FAILURE'
  payload: { error: Error }
}

type AcceptTermsAction = {
  type: 'ACCEPT_TERMS'
}

type AcceptTermsSuccessAction = {
  type: 'ACCEPT_TERMS_SUCCESS'
  payload: { user: any }
}

type AcceptTermsFailedAction = {
  type: 'ACCEPT_TERMS_FAILURE'
  payload: { error: Error }
}

type SignOutAction = {
  type: 'SIGN_OUT'
}

type AuthenticationAction =
  | SignInAction
  | SignInFailedAction
  | SignInSuccessAction
  | CompleteNewPasswordAction
  | CompleteNewPasswordSuccessAction
  | CompleteNewPasswordFailedAction
  | AcceptTermsAction
  | AcceptTermsSuccessAction
  | AcceptTermsFailedAction
  | SignOutAction

type AuthenticationState = {
  user: any
  loading: boolean
  error: Error | null
  challenge: AuthChallenge | null
}

const authenticationReducer = (
  state: AuthenticationState,
  action: AuthenticationAction,
) => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'SIGN_IN_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload.user,
        challenge: action.payload.user.challengeName,
      }
    case 'SIGN_IN_FAILURE':
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.error,
      }
    case 'COMPLETE_NEW_PASSWORD':
      return {
        ...state,
        loading: true,
      }
    case 'COMPLETE_NEW_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload.user,
        challenge: action.payload.user.challengeName,
      }
    case 'COMPLETE_NEW_PASSWORD_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case 'ACCEPT_TERMS':
      return {
        ...state,
        loading: true,
      }
    case 'ACCEPT_TERMS_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload.user,
        challenge: action.payload.user.challengeName,
      }
    case 'ACCEPT_TERMS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case 'SIGN_OUT':
      return {
        loading: false,
        error: null,
        user: null,
        challenge: null,
      }
  }
}

function useAuthentication() {
  const [state, dispatch] = useReducer(authenticationReducer, {
    user: null,
    loading: false,
    error: null,
    challenge: null,
  } as AuthenticationState)

  const signIn = async (username: string, password: string) => {
    try {
      dispatch({ type: 'SIGN_IN' })
      const user = await Auth.signIn(username.toLowerCase(), password)
      dispatch({ type: 'SIGN_IN_SUCCESS', payload: { user } })
      return user
    } catch (error) {
      dispatch({ type: 'SIGN_IN_FAILURE', payload: { error } })
    }
  }

  // hack required to get custom auth challenge ¯\_(ツ)_/¯
  // https://github.com/JuHwon/no-box/issues/140
  const completeNewPasswordChallenge = (password: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      state.user.completeNewPasswordChallenge(password, {}, {
        onSuccess: async () => {
          try {
            const { email } = state.user.challengeParam.userAttributes
            await Auth.signOut()
            const user = await Auth.signIn(email, password)
            resolve(user)
          } catch(error) {
            reject(error)
          }
        },
        onFailure: (error: Error) => {
          reject(error)
        }
      })
    })
  }

  const completeNewPassword = async (password: string) => {
    try {
      dispatch({ type: 'COMPLETE_NEW_PASSWORD' })
      // hack required to get custom auth challenge
      // https://github.com/JuHwon/no-box/issues/140
      // do not use Auth.complete...
      const user = await completeNewPasswordChallenge(password)
      dispatch({ type: 'COMPLETE_NEW_PASSWORD_SUCCESS', payload: { user } })
      return user
    } catch (error) {
      dispatch({ type: 'COMPLETE_NEW_PASSWORD_FAILURE', payload: { error } })
    }
  }

  const acceptTerms = async (accept: boolean) => {
    const answer = accept ? 'ACCEPTED' : 'DECLINED'
    try {
      dispatch({ type: 'ACCEPT_TERMS' })
      const user = await Auth.sendCustomChallengeAnswer(state.user, answer)
      dispatch({ type: 'ACCEPT_TERMS_SUCCESS', payload: { user } })
      return user
    } catch (error) {
      dispatch({ type: 'ACCEPT_TERMS_FAILURE', payload: { error } })
    }
  }

  const signOut = async () => {
    dispatch({ type: 'SIGN_OUT' })
    try {
      const data = await Auth.signOut()
      return data
    } catch (error) {
      console.error('Sign out failed: ', error)
    }
  }

  return {
    signIn,
    completeNewPassword,
    acceptTerms,
    signOut,
    challange: state.challenge,
    error: state.error,
    loading: state.loading,
  }
}

export default useAuthentication
