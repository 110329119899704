import styled from 'styled'

export default styled.label`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${props => props.theme.colors["grey-50"]};
  margin-bottom: 0px;
  padding-left: ${props => props.theme.spaces.xxs};
`
