import styled from 'styled'

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${props => props.theme.media.phone`grid-template-columns: auto;`}
  grid-column-gap: ${props => props.theme.spaces.xl};
  grid-row-gap: ${props => props.theme.spaces.xl};
`

export { ProductGrid }
