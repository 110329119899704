
import { useState, useCallback } from 'react'

type ToggleFn = () => void
type ResetFn = () => void

type ToggleHookResult = [
  boolean, // value
  ToggleFn, // toggle
  ResetFn // reset to initial value
]

function useToggle(initialValue: boolean): ToggleHookResult {
  const [value, setValue] = useState<boolean>(initialValue)
  const toggler: () => void = useCallback<ToggleFn>(() => setValue(value => !value), [])
  const reset: () => void = useCallback<ResetFn>(() => setValue(initialValue), [initialValue])
  return [value, toggler, reset]
}

export default useToggle
