import React, { ChangeEvent } from 'react'

type RadioGroupContextType = {
  name: string,
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export default React.createContext<RadioGroupContextType>({
  name: '',
  value: '',
  onChange: () => {},
})
