import colors from './colors'
import spaces from './spaces'
import radius from './radius'
import media, { screenSizes } from './media'
import ThemeInterface from '../ThemeInterface'

const defaultTheme: ThemeInterface = {
  colors,
  spaces,
  radius,
  screenSizes,
  media,
  fonts: {
    default: '"Inter UI", sans-serif',
    header: '"Inter UI", sans-serif',
  },
  meta: {
    fixedLayoutWidth: 1100
  }
}

export default defaultTheme
