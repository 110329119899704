import React, { useState, ChangeEvent, CSSProperties, ReactNode } from 'react'
import Button from './Button'

import RadioGroupContext from './context'

type RadioGroupProps = {
  children?: ReactNode
  value?: string | number
  defaultValue?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  name: string
  className?: string
  style?: CSSProperties
}

const noop = () => {}

function RadioGroup({ children, ...props }: RadioGroupProps) {
  const [value, setValue] = useState(props.defaultValue || '')
  const isControlled = props.value !== undefined

  const actualValue = isControlled ? (props.value as string) : value
  const handleChange = isControlled
    ? props.onChange || noop
    : (e: ChangeEvent<HTMLInputElement>) => setValue(e.currentTarget.value)

  const contextState = {
    name: props.name,
    value: actualValue,
    onChange: handleChange,
  }

  return (
    <RadioGroupContext.Provider value={contextState}>
      <div className={props.className} style={props.style}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  )
}

RadioGroup.Button = Button
export default RadioGroup
