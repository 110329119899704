import React, {
  useRef,
  CSSProperties,
  ReactNode,
  useState,
  useEffect,
} from 'react'
import styled, { useTheme } from 'styled'
import moment from 'moment'
import ReactPdfRenderer from '@react-pdf/renderer'

import * as API from 'api'
import { Doctor, AddressFields } from 'types'
import FormDescription from 'components/FormDescription'
import Button from 'components/Button'
import Seperator from 'components/Seperator'
import FaIcon from 'components/FaIcon'

import useHover from 'hooks/useHover'
import useMedia from 'hooks/useMedia'
import { useApi } from 'hooks'

const { PDFDownloadLink, BlobProvider } = ReactPdfRenderer

interface ClaimSummaryProps {
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
}

const SummarayLayoutGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  ${props => props.theme.media.tablet`grid-template-columns: auto;`}
  grid-column-gap: ${props => props.theme.spaces.l};
`

const SheetSection = styled.div`
  background: white;
  box-shadow: 0 1px 3px #26262633, 0 1px 2px #1f1f1f45;

  justify-self: center;

  padding-left: 70px;
  padding-right: 70px;
  ${props => props.theme.media.phone`padding-left: 26px;`}
  ${props => props.theme.media.phone`padding-right: 26px;`}

  width: 700px;
  ${props => props.theme.media.phone`width: 270px;`}
  padding-bottom: 26px;
`

interface ClaimSummaryDocumentAddressBlockProps extends AddressFields {
  className?: string
  style?: CSSProperties
}

function ClaimSummaryDocumentAddressBlock(
  props: ClaimSummaryDocumentAddressBlockProps
) {
  return (
    <div className={props.className} style={props.style}>
      <div>{props.name}</div>
      {props.companyName && <div>{props.companyName}</div>}
      {props.department && <div>{props.department}</div>}
      <div>{props.firstAddressLine}</div>
      <div>{props.secondAddressLine}</div>
      {props.phoneNo && <div>Tel: {props.phoneNo}</div>}
      {props.faxNo && <div>Fax: {props.faxNo}</div>}
    </div>
  )
}

function ClaimSummary(props: ClaimSummaryProps) {
  return <SummarayLayoutGrid {...props} />
}

ClaimSummary.AddressBlock = ClaimSummaryDocumentAddressBlock

function ClaimSummaryHeading() {
  const { isPhone } = useMedia()
  return (
    <>
      <div>
        <FormDescription.Heading as="h2">Vorschau</FormDescription.Heading>
        <FormDescription.Content>
          Bitte &uuml;berpr&uuml;fen und korrigieren Sie gegebenenfalls Ihre
          Angaben.
        </FormDescription.Content>
      </div>
      <SheetSection style={{ height: isPhone ? 60 : 175 }} />
    </>
  )
}
ClaimSummary.Heading = ClaimSummaryHeading

interface ClaimSummarySectionProps {
  name: string
  onEdit: () => void
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

function ClaimSummarySection(props: ClaimSummarySectionProps) {
  const btnRef = useRef(null)
  const [btnHovered] = useHover(btnRef)
  const { colors } = useTheme()
  const { isPhone, isTablet } = useMedia()

  const hoverStyle = { background: colors['grey-95'] }

  return (
    <>
      {!isPhone && !isTablet && (
        <div>
          <Seperator style={{ marginBottom: 20 }} />
          <Button
            ref={btnRef}
            onClick={props.onEdit}
            style={{ width: '100%' }}
            variant="tertiary"
          >
            <Button.Icon name="pencil-alt" />
            <span>{`${props.name} editieren`}</span>
          </Button>
        </div>
      )}
      <SheetSection style={btnHovered ? hoverStyle : undefined}>
        {props.children}
      </SheetSection>
    </>
  )
}
ClaimSummary.Section = ClaimSummarySection

const ClaimSummaryDocumentText = styled.div`
  font-size: 13px;
  ${props => props.theme.media.phone`font-size: 6px;`}
`
ClaimSummary.DocumentText = ClaimSummaryDocumentText

interface ClaimSummaryDocumentHeaderProps {
  to: string
  department?: string
  firstAddressLine: string
  secondAddressLine: string
  phoneNo?: string
  faxNo?: string
  sentFromCity: string
}

function ClaimSummaryDocumentHeader(props: ClaimSummaryDocumentHeaderProps) {
  const { isPhone } = useMedia()
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: isPhone ? 18 : 50,
      }}
    >
      <ClaimSummaryDocumentText>
        <div>An:</div>
        <ClaimSummaryDocumentAddressBlock
          name={props.to}
          department={props.department}
          firstAddressLine={props.firstAddressLine}
          secondAddressLine={props.secondAddressLine}
          phoneNo={props.phoneNo}
          faxNo={props.faxNo}
        />
      </ClaimSummaryDocumentText>
      <ClaimSummaryDocumentText style={{ alignSelf: 'flex-end' }}>
        {`${props.sentFromCity}, am ${moment().format('LL')}`}
      </ClaimSummaryDocumentText>
    </div>
  )
}
ClaimSummary.DocumentHeader = ClaimSummaryDocumentHeader

const ClaimSummaryDocumentTitle = styled.h2`
  font-size: 22px;
  ${props => props.theme.media.phone`font-size: 9px;`}
  margin-bottom: 25px;
  ${props => props.theme.media.phone`margin-bottom: 10px;`}
`
ClaimSummary.DocumentTitle = ClaimSummaryDocumentTitle

function ClaimSummaryDocumentGreeting(props: { product: string }) {
  return (
    <>
      <ClaimSummaryDocumentTitle>
        {`Antrag auf Erstattung von ${props.product}`}
      </ClaimSummaryDocumentTitle>
      <ClaimSummaryDocumentText>
        <p>Sehr geehrte Damen und Herren,</p>
        <p>{`hiermit beantrage ich ${
          props.product
        } für folgenden Patienten:`}</p>
      </ClaimSummaryDocumentText>
    </>
  )
}
ClaimSummary.DocumentGreeting = ClaimSummaryDocumentGreeting

const ClaimSummaryDocumentSectionTitle = styled.h4`
  font-size: 14px;
  ${props => props.theme.media.phone`font-size: 7px;`}
`
ClaimSummary.DocumentSectionTitle = ClaimSummaryDocumentSectionTitle

interface ClaimSummaryDocumentPatientData {
  name: string
  birthday: Date
  svn: string
  weight: number
  diagnose: string
}

function ClaimSummaryDocumentPatientData(
  props: ClaimSummaryDocumentPatientData
) {
  return (
    <>
      <ClaimSummaryDocumentSectionTitle>
        Patienten-Daten:
      </ClaimSummaryDocumentSectionTitle>
      <ClaimSummaryDocumentText
        style={{
          display: 'grid',
          gridTemplateColumns: '130px auto',
          gridColumnGap: 20,
        }}
      >
        <div>Name:</div>
        <div>{props.name}</div>
        <div>Geburts-Datum:</div>
        <div>{moment(props.birthday).format('LL')}</div>
        <div>SVN:</div>
        <div>{props.svn}</div>
        <div>Gewicht:</div>
        <div>{`${props.weight} kg`}</div>
      </ClaimSummaryDocumentText>
      <ClaimSummaryDocumentSectionTitle>
        Diagnose:
      </ClaimSummaryDocumentSectionTitle>
      <ClaimSummaryDocumentText>{props.diagnose}</ClaimSummaryDocumentText>
    </>
  )
}
ClaimSummary.DocumentPatientData = ClaimSummaryDocumentPatientData

interface ClaimSummaryDocumentArgumentSectionProps {
  arguments: {
    id: string | number
    label: string
    description?: string
  }[]
  other: string
}

function ClaimSummaryDocumentArgumentSection(
  props: ClaimSummaryDocumentArgumentSectionProps
) {
  return (
    <>
      <ClaimSummaryDocumentSectionTitle>
        {'Begründung:'}
      </ClaimSummaryDocumentSectionTitle>
      <ClaimSummaryDocumentText>
        <ul>
          {props.arguments.map(arg => (
            <li key={arg.id} style={{ marginBottom: 10 }}>
              {arg.label}
              {arg.description ? ` - ${arg.description}` : ''}
            </li>
          ))}
          {props.other && <li style={{ marginBottom: 10 }}>{props.other}</li>}
        </ul>
      </ClaimSummaryDocumentText>
    </>
  )
}
ClaimSummary.DocumentArgumentData = ClaimSummaryDocumentArgumentSection

const SignatureUnderLine = styled.div`
  width: 300px;
  ${props => props.theme.media.phone`width: 100px;`}
  margin-bottom: 20px;
  ${props => props.theme.media.phone`margin-bottom: 8px;`}
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors['grey-10']};
  border-bottom-width: 2px;
  ${props => props.theme.media.phone`border-bottom-width: 1px;`}
`

interface ClaimSummaryDocumentDoctorSectionProps {
  doctor: Pick<Doctor, Exclude<keyof Doctor, 'id' | 'email' | 'oid'>>
  organisation: 'surgery' | 'hospital'
  onSign?: () => void
  signatureSrc?: string
  signatureAvailable: boolean
}

function ClaimSummaryDocumentDoctorSection({
  doctor,
  organisation,
  onSign,
  signatureSrc,
  signatureAvailable,
}: ClaimSummaryDocumentDoctorSectionProps) {
  const { spaces } = useTheme()
  const address =
    organisation === 'surgery'
      ? {
          companyName: doctor.surgery.name,
          firstAddressLine: doctor.surgery.street,
          secondAddressLine: `${doctor.surgery.zip} ${doctor.surgery.city}`,
          phoneNo: doctor.surgery.phone,
          faxNo: doctor.surgery.fax,
        }
      : {
          companyName: doctor.hospital.name,
          department: doctor.hospital.department,
          firstAddressLine: doctor.hospital.street,
          secondAddressLine: `${doctor.hospital.zip} ${doctor.hospital.city}`,
          phoneNo: doctor.hospital.phone,
          faxNo: doctor.hospital.fax,
        }

  return (
    <ClaimSummaryDocumentText>
      <p>Um einen positiven Bescheid wird gebeten.</p>
      <p>{'Mit freundlichen Grüßen'}</p>
      {signatureSrc ? (
        <img alt="Signatur" src={signatureSrc} />
      ) : (
        <Button
          variant="primary"
          onClick={onSign}
          style={{ margin: spaces.stack.s }}
          disabled={!signatureAvailable}
        >
          Unterschreiben
        </Button>
      )}
      <SignatureUnderLine />
      <ClaimSummaryDocumentAddressBlock
        name={`${doctor.title} ${doctor.name} ${doctor.surname}`}
        {...address}
        style={{ marginBottom: 26 }}
      />
    </ClaimSummaryDocumentText>
  )
}
ClaimSummary.DocumentDoctorSection = ClaimSummaryDocumentDoctorSection

interface ClaimSummaryCommitSectionProps {
  document: React.ReactElement<ReactPdfRenderer.DocumentProps>
  documentName: string
  onApplyViaMailClick: (blob: Blob) => void
  sickfundService?: {
    secureEmail: boolean
    emailAllowed: boolean
  }
  customMail?: string
  signed: boolean
}

const useTlsCheck = (email?: string) => {
  const [tlsResponse, checkTls] = useApi(API.checkTls)
  useEffect(() => {
    if (email) {
      checkTls(email)
    }
  }, [checkTls, email])

  const error = tlsResponse.error
  const success = tlsResponse.calledOnce && !error

  return {
    error,
    success,
    loading: tlsResponse.loading,
  }
}

function ClaimSummaryCommitSection(props: ClaimSummaryCommitSectionProps) {
  const { isPhone, isTablet } = useMedia()
  const { spaces, colors } = useTheme()
  const [pdfHackReady, setPdfHackReady] = useState(true)

  const tlsResult = useTlsCheck(props.customMail)

  //////////// PDF LIB HACK ///////////////////////
  // https://github.com/diegomura/react-pdf/issues/420
  // ¯\_(ツ)_/¯
  useEffect(() => {
    setPdfHackReady(false)
    setTimeout(() => setPdfHackReady(true))
  }, [props])

  if (!pdfHackReady) {
    return null
  }
  //////////// END of PDF LIB HACK ///////////////////////

  const secureMail = {
    loading: props.sickfundService ? false : tlsResult.loading,
    // success: props.sickfundService
    //   ? props.sickfundService.secureEmail && props.sickfundService.emailAllowed
    //   : tlsResult.success,

    // TODO: rewrite this section
    // the secureMail flag is not required anymore because of used SES profile, which has required TLS option
    success: props.sickfundService ? props.sickfundService.emailAllowed : true,
  }

  const disableMail =
    tlsResult.loading ||
    (!!props.sickfundService && !props.sickfundService.emailAllowed)

  return (
    <>
      {!isPhone && !isTablet && <div />}
      <div style={{ paddingTop: spaces.m, paddingBottom: spaces.m }}>
        <div>
          <PDFDownloadLink
            document={props.document}
            fileName={props.documentName}
          >
            {({ blob, url, loading, error }) => (
              <Button
                style={{ marginRight: 20 }}
                disabled={loading}
                variant="primary"
              >
                PDF speichern
              </Button>
            )}
          </PDFDownloadLink>
          <BlobProvider document={props.document}>
            {({ blob, url, loading, error }) => (
              <Button
                variant="primary"
                onClick={() => props.onApplyViaMailClick(blob as Blob)}
                disabled={loading || !blob || disableMail}
              >
                per Mail beantragen
              </Button>
            )}
          </BlobProvider>
        </div>
        <div style={{ paddingTop: spaces.m }}>
          {secureMail.loading ? (
            <div>
              <FaIcon name="spinner" spin style={{ marginRight: 16 }} />
              {'Überprüfe Versand E-Mail Adresse...'}
            </div>
          ) : secureMail.success ? (
            <div style={{ color: colors.success }}>
              <FaIcon name="check" style={{ marginRight: 16 }} />
              {'Versand E-Mail Adresse unterstützt eine sichere Übertragung.'}
            </div>
          ) : (
            <div style={{ color: colors.danger }}>
              <FaIcon name="times" style={{ marginRight: 16 }} />
              {
                'Gewählte Versand E-Mail Adresse unterstützt keine sichere Übertragung.'
              }
            </div>
          )}
        </div>
        <div style={{ paddingTop: spaces.m }}>
          {props.signed ? (
            <div style={{ color: colors.success }}>
              <FaIcon name="check" style={{ marginRight: 16 }} />
              {'Dokument unterschrieben.'}
            </div>
          ) : (
            <div style={{ color: colors.warning }}>
              <FaIcon name="times" style={{ marginRight: 16 }} />
              {'Es fehlt noch Ihre Unterschrift.'}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
ClaimSummary.CommitSection = ClaimSummaryCommitSection

export default ClaimSummary
