/* eslint-disable @typescript-eslint/no-angle-bracket-type-assertion */
import { css } from 'styled'

import { ThemeScreenSizes, ThemeMediaFunctions } from '../../ThemeInterface'

const screenSizes: ThemeScreenSizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
}

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
type CssArgs = ArgumentTypes<typeof css>

const screenSizeKeys = <(keyof ThemeScreenSizes)[]>Object.keys(screenSizes)

const media = screenSizeKeys.reduce((acc, label) => {
  acc[label] = ((...args: CssArgs) => css`
    @media (max-width: ${screenSizes[label] / 16}em) {
      ${css(...args)}
    }
  `) as unknown as typeof css
  return acc
}, {} as ThemeMediaFunctions)

export default media
export { screenSizes }
