import React, { useEffect, SyntheticEvent } from 'react'
import * as yup from 'yup'
import { mergeDeepRight } from 'ramda'

import FormDescription from 'components/FormDescription'
import FormGrid from 'components/FormGrid'
import InputGrid from 'components/InputGrid'
import Label from 'components/Label'
import RadioGroup from 'components/RadioGroup'
import InputGroup from 'components/InputGroup'

import * as API from 'api'
import { useFormFields, useYup, useApi } from 'hooks'
import { requiredError, tooLongError } from 'messages/errors'
import { Doctor } from 'types'
import InputError from 'components/InputError'
import Input from 'components/Input'
import { useClaimConfig, ClaimStepFooter } from 'claimbuilder'
import { useClaim, ClaimData } from 'claimbuilder/ClaimContext'
import { useUserInfo, useUserGroups } from 'auth'

export const DoctorStep = () => {
  const { config } = useClaimConfig()
  const { setData } = useClaim()

  const { isDoctor } = useUserGroups()
  const [profile] = useGetProfile()
  const initialValues = config.prefill ? prefilledFormFields : initialFormFields
  const data = isDoctor
    ? mergeDeepRight(initialValues, profile || {})
    : initialValues
  // change organisation tab if profile hospital is set but no surgery
  if (
    profile &&
    profile.hospital &&
    profile.hospital.name &&
    (!profile.surgery || !profile.surgery.name)
  ) {
    data.organisation = 'hospital'
  }

  const { fields, values, setValue } = useFormFields(data)
  const {
    fields: surgeryFields,
    setValue: setSurgeryValue,
    values: surgeryValues,
  } = useFormFields(data.surgery)
  const {
    fields: hospitalFields,
    setValue: setHospitalValue,
    values: hospitalValues,
  } = useFormFields(data.hospital)

  useEffect(() => {
    setValue('surgery', surgeryValues)
    setValue('hospital', hospitalValues)
  }, [hospitalValues, surgeryValues, setValue])

  const { validate, errors } = useYup(values, validationSchema)

  const handleNext = async (evt: SyntheticEvent<HTMLButtonElement>) => {
    const { isValid } = await validate()
    if (isValid) {
      const doctor = formFieldsToData(values)
      setData(prev => ({
        ...prev,
        doctor,
      }))
    } else {
      evt.preventDefault()
    }
  }

  const loadingProfile = false
  const disabled = loadingProfile
  return (
    <>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">Arzt Daten</FormDescription.Heading>
          <FormDescription.Content>
            Bitte geben Sie hier Ihre Arzt Daten an.
          </FormDescription.Content>
        </FormDescription>

        <InputGrid>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Anrede</Label>
            <RadioGroup {...fields.gender}>
              <InputGroup>
                <RadioGroup.Button
                  value="male"
                  style={{ flex: '1', textAlign: 'center' }}
                  disabled={disabled}
                >
                  Herr
                </RadioGroup.Button>
                <RadioGroup.Button
                  value="female"
                  style={{ flex: '1', textAlign: 'center' }}
                  disabled={disabled}
                >
                  Frau
                </RadioGroup.Button>
              </InputGroup>
            </RadioGroup>
            {errors.gender && <InputError>{errors.gender}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Titel</Label>
            <Input type="text" {...fields.title} disabled={disabled} />
            {errors.title && <InputError>{errors.title}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>Vorname</Label>
            <Input type="text" {...fields.name} disabled={disabled} />
            {errors.name && <InputError>{errors.name}</InputError>}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Nachname</Label>
            <Input type="text" {...fields.surname} disabled={disabled} />
            {errors.surname && <InputError>{errors.surname}</InputError>}
          </InputGrid.Item>

          <InputGrid.Item>
            <Label>Organistation</Label>
            <RadioGroup {...fields.organisation}>
              <InputGroup>
                <RadioGroup.Button
                  value="surgery"
                  style={{ flex: '1', textAlign: 'center' }}
                  disabled={disabled}
                >
                  Praxis
                </RadioGroup.Button>
                <RadioGroup.Button
                  value="hospital"
                  style={{ flex: '1', textAlign: 'center' }}
                  disabled={disabled}
                >
                  Krankenhaus
                </RadioGroup.Button>
              </InputGroup>
            </RadioGroup>
          </InputGrid.Item>

          {fields.organisation.value === 'surgery' ? (
            <>
              <InputGrid.Item>
                <Label>Praxis Name</Label>
                <Input
                  type="text"
                  {...surgeryFields.name}
                  disabled={disabled}
                />
                {errors.surgery && errors.surgery.name && (
                  <InputError>{errors.surgery.name}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Strasse</Label>
                <Input
                  type="text"
                  {...surgeryFields.street}
                  disabled={disabled}
                />
                {errors.surgery && errors.surgery.street && (
                  <InputError>{errors.surgery.street}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
                <Label>PLZ</Label>
                <Input
                  type="number"
                  onChange={evt =>
                    setSurgeryValue('zip', Number(evt.currentTarget.value))
                  }
                  name={surgeryFields.zip.name}
                  value={surgeryFields.zip.value || ''}
                  disabled={disabled}
                />
                {errors.surgery && errors.surgery.zip && (
                  <InputError>{errors.surgery.zip}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
                <Label>Ort</Label>
                <Input
                  type="text"
                  {...surgeryFields.city}
                  disabled={disabled}
                />
                {errors.surgery && errors.surgery.city && (
                  <InputError>{errors.surgery.city}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Telefon</Label>
                <Input
                  type="text"
                  {...surgeryFields.phone}
                  disabled={disabled}
                />
                {errors.surgery && errors.surgery.phone && (
                  <InputError>{errors.surgery.phone}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Fax</Label>
                <Input type="text" {...surgeryFields.fax} disabled={disabled} />
                {errors.surgery && errors.surgery.fax && (
                  <InputError>{errors.surgery.fax}</InputError>
                )}
              </InputGrid.Item>
            </>
          ) : (
            <>
              <InputGrid.Item>
                <Label>Krankenhaus Name</Label>
                <Input
                  type="text"
                  {...hospitalFields.name}
                  disabled={disabled}
                />
                {errors.hospital && errors.hospital.name && (
                  <InputError>{errors.hospital.name}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Abteilung</Label>
                <Input
                  type="text"
                  {...hospitalFields.department}
                  disabled={disabled}
                />
                {errors.hospital && errors.hospital.department && (
                  <InputError>{errors.hospital.department}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Strasse</Label>
                <Input
                  type="text"
                  {...hospitalFields.street}
                  disabled={disabled}
                />
                {errors.hospital && errors.hospital.street && (
                  <InputError>{errors.hospital.street}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
                <Label>PLZ</Label>
                <Input
                  type="number"
                  onChange={evt =>
                    setHospitalValue('zip', Number(evt.currentTarget.value))
                  }
                  name={hospitalFields.zip.name}
                  value={hospitalFields.zip.value || ''}
                  disabled={disabled}
                />
                {errors.hospital && errors.hospital.zip && (
                  <InputError>{errors.hospital.zip}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
                <Label>Ort</Label>
                <Input
                  type="text"
                  {...hospitalFields.city}
                  disabled={disabled}
                />
                {errors.hospital && errors.hospital.city && (
                  <InputError>{errors.hospital.city}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Telefon</Label>
                <Input
                  type="text"
                  {...hospitalFields.phone}
                  disabled={disabled}
                />
                {errors.hospital && errors.hospital.phone && (
                  <InputError>{errors.hospital.phone}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Fax</Label>
                <Input
                  type="text"
                  {...hospitalFields.fax}
                  disabled={disabled}
                />
                {errors.hospital && errors.hospital.fax && (
                  <InputError>{errors.hospital.fax}</InputError>
                )}
              </InputGrid.Item>
            </>
          )}
        </InputGrid>
      </FormGrid>
      <ClaimStepFooter disablePrev onCommit={handleNext} />
    </>
  )
}

const useGetProfile = () => {
  const [
    // { data: profile, loading: loadingProfile, error: loadingProfileError },
    response,
    getProfile,
  ] = useApi<[string], Doctor>(API.getProfile)
  const userInfo = useUserInfo()
  useEffect(() => {
    if (userInfo) {
      getProfile(userInfo.attributes.sub)
    }
  }, [getProfile, userInfo])
  return [response.data, response] as [
    (typeof response)['data'],
    typeof response
  ]
}

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .max(40, tooLongError('Titel', 40))
    .required(requiredError('Titel')),
  gender: yup.string().required(requiredError('Anrede')),
  name: yup
    .string()
    .max(50, tooLongError('Vorname', 50))
    .required(requiredError('Vorname')),
  surname: yup
    .string()
    .max(50, tooLongError('Nachname', 50))
    .required(requiredError('Nachname')),
  organisation: yup.string().oneOf(['surgery', 'hospital']),
  surgery: yup
    .object()
    .when(
      'organisation',
      (organisation: 'surgery' | 'hospital', schema: yup.ObjectSchema<{}>) => {
        if (organisation === 'surgery') {
          return schema.shape({
            name: yup
              .string()
              .required(requiredError('Praxis Name'))
              .max(100, tooLongError('Praxis Name', 100)),
            street: yup
              .string()
              .required(requiredError('Straße'))
              .max(50, tooLongError('Strasse', 50)),
            zip: yup
              .number()
              .required(requiredError('PLZ'))
              .min(1010, 'Bitte geben Sie eine gültige PLZ an.')
              .max(9999, 'Bitte geben Sie eine gültige PLZ an.'),
            city: yup
              .string()
              .required(requiredError('Ort'))
              .max(50, tooLongError('Ort', 50)),
            phone: yup
              .string()
              .required(requiredError('Telefon'))
              .max(50, tooLongError('Telefon', 50)),
            fax: yup.string().max(50, tooLongError('Fax', 50)),
          })
        }
        return schema
      }
    ),
  hospital: yup.object().when('organisation', {
    is: val => val === 'hospital',
    then: yup.object().shape({
      name: yup
        .string()
        .required(requiredError('Krankenhaus Name'))
        .max(100, tooLongError('Krankenhaus Name', 100)),
      department: yup
        .string()
        .required(requiredError('Abteilung'))
        .max(100, tooLongError('Abteilung', 100)),
      street: yup
        .string()
        .required(requiredError('Straße'))
        .max(50, tooLongError('Straße', 50)),
      zip: yup
        .number()
        .required(requiredError('PLZ'))
        .min(1010, 'Bitte geben Sie eine gültige PLZ an.')
        .max(9999, 'Bitte geben Sie eine gültige PLZ an.'),
      city: yup
        .string()
        .required(requiredError('Ort'))
        .max(50, tooLongError('Ort', 50)),
      phone: yup
        .string()
        .required(requiredError('Telefon'))
        .max(50, tooLongError('Telefon', 50)),
      fax: yup.string().max(50, tooLongError('Fax', 50)),
    }),
    otherwise: yup.object(),
  }),
})

const initialFormFields: Omit<Doctor, 'id'> & {
  organisation: 'surgery' | 'hospital'
} = {
  organisation: 'surgery',
  gender: 'female',
  title: '',
  name: '',
  surname: '',
  email: '',
  oid: '',
  hospital: {
    name: '',
    department: '',
    street: '',
    zip: 0,
    city: '',
    phone: '',
    fax: '',
  },
  surgery: {
    name: '',
    street: '',
    zip: 0,
    city: '',
    phone: '',
    fax: '',
  },
}

const prefilledFormFields: Omit<Doctor, 'id'> & {
  organisation: 'surgery' | 'hospital'
} = {
  organisation: 'surgery',
  gender: 'female',
  title: 'Dr.',
  name: 'Martina',
  surname: 'Musterfrau',
  email: 'martina@musterfrau.at',
  oid: '123456',
  hospital: {
    name: 'Muster Krankenhaus',
    department: 'Musterabteilung',
    street: 'Musterstraße 31',
    zip: 1234,
    city: 'Musterstadt',
    phone: '012 3456789',
    fax: '012 3456789 1',
  },
  surgery: {
    name: 'Musterpraxis',
    street: 'Musterstraße 21',
    zip: 1010,
    city: 'Musterstadt',
    phone: '012 3456789',
    fax: '012 3456789 1',
  },
}

const formFieldsToData = (
  values: typeof initialFormFields
): ClaimData['doctor'] => {
  const doctor = {
    gender: values.gender,
    name: `${values.title} ${values.name} ${values.surname}`,
  } as ClaimData['doctor']

  let org = null
  if (values.organisation === 'surgery') {
    org = values.surgery
    doctor.department = ''
    doctor.isHospital = false
  } else {
    org = values.hospital
    doctor.department = values.hospital.department
    doctor.isHospital = true
  }
  doctor.organisation = org.name
  doctor.street = org.street
  doctor.zip = `${org.zip}`
  doctor.city = org.city
  doctor.phone = org.phone
  doctor.fax = org.fax

  return doctor
}
