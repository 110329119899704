import React, { useContext, useEffect, useRef, MouseEvent } from 'react'
import { useTheme } from 'styled'

import Label from 'components/Label'
import Button from 'components/Button'

import useFormFields from 'hooks/useFormFields'

import { LoginFlowContext } from './index'
import { LogInInput } from './styled-login'

type UsernamePasswordFormProps = {
  login: (username: string, password: string) => any
  loading: boolean
}

function UsernamePasswordForm(props: UsernamePasswordFormProps) {
  const { setState: setLoginState } = useContext(LoginFlowContext)
  const { fields, values } = useFormFields({
    username: '',
    password: '',
  })

  const usernameInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (usernameInputRef.current) {
      usernameInputRef.current.focus()
    }
  }, [])
  const { spaces } = useTheme()

  const handleForgotPassword = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setLoginState('FORGOT_PASSWORD')
  }

  return (
    <form onSubmit={event => event.preventDefault()}>
      <div>
        <Label>E-Mail</Label>
        <LogInInput
          type="text"
          ref={usernameInputRef}
          {...fields.username}
          disabled={props.loading}
        />
      </div>
      <div>
        <Label>Passwort</Label>
        <LogInInput
          style={{ margin: spaces.stack.s }}
          type="password"
          {...fields.password}
          disabled={props.loading}
        />
      </div>
      <div style={{ margin: spaces.stack.s }}>
        <Button
          variant="tertiary"
          style={{ fontSize: 12, fontWeight: 500, marginLeft: spaces.s }}
          as="a"
          onClick={handleForgotPassword}
        >
          Passwort vergessen?
        </Button>
      </div>
      <div>
        <Button
          style={{ width: '100%', margin: spaces.stack.s }}
          type="submit"
          variant="primary"
          onClick={() =>
            props.login(values.username.toLowerCase(), values.password)
          }
          disabled={props.loading}
        >
          Anmelden
        </Button>
        <Button
          variant="tertiary"
          as="a"
          style={{ fontSize: 12, fontWeight: 500 }}
          onClick={e => e.preventDefault()}
          hidden={true}
        >
          noch kein Account?
        </Button>
      </div>
    </form>
  )
}

export default UsernamePasswordForm
