import React from 'react'

import { CognitoUserInfo } from 'types'

type AuthContextType = {
  initialized: boolean,
  authenticated: boolean,
  userInfo?: CognitoUserInfo
}

export const defaultContextState: AuthContextType = {
  initialized: false,
  authenticated: false,
}

const context = React.createContext(defaultContextState)

export default context
