import React from 'react'
import { lighten } from 'polished'
import FaIcon from 'components/FaIcon'

import { useTheme } from 'styled'

// function Icon(p)

const Icon: typeof FaIcon = ({ style, ...props }) => {
  const theme = useTheme()
  const color = lighten(0.1, theme.colors['grey-50'])
  return (
    <FaIcon
      size="2x"
      {...props}
      style={{ transform: 'scale(0.8)', color,  ...style }}
    />
  )
}

export default Icon
