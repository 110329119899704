import styled from 'styled'

const FormGrid = styled.div`
  display: grid;

  grid-template-columns: 300px auto;
  ${props => props.theme.media.desktop`grid-template-columns: 200px auto;`}
  ${props => props.theme.media.tablet`grid-template-columns: auto;`}

  grid-column-gap: ${props => props.theme.spaces.xxxl};
  ${props => props.theme.media.desktop`grid-column-gap: ${props => props.theme.spaces.xxl};`}

  margin-bottom: ${props => props.theme.spaces.xxl};
`
export default FormGrid
