import styled from 'styled'

const Container = styled.div<{ onClick?: any }>`
  display: grid;
  grid-column: auto;
  background: ${props => props.theme.colors.background};
  box-shadow: 0 1px 3px #26262633, 0 1px 2px #1f1f1f45;
  border-radius: ${props => props.theme.radius.s};

  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  user-select: none;
  overflow: auto;

  :hover {
    box-shadow: 0 1px 2px #1f1f1f45;
  }
`

const TopLine = styled.div`
  background: ${props => props.theme.colors.primary};
  height: 5px;
`

const CompanyLogo = styled.img`
  justify-self: end;
  height: 16px;
  margin: ${props => props.theme.spaces.insetSquish.s};
`

const ProductLogoWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  height: 80px;
`

const ProductLogo = styled.img`
  justify-self: center;
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
`

const Footer = styled.div`
  background: ${props => props.theme.colors['grey-90']};
  display: flex;
`

export {
  Container,
  TopLine,
  CompanyLogo,
  ProductLogo,
  ProductLogoWrapper,
  Footer,
}
