import React from 'react'
import { useTheme } from 'styled'

export const OidLink = () => {
  const { spaces } = useTheme()
  return (
    <a
      href={'https://www.aerztekammer.at/abfrage-arztnummer'}
      target="_blank"
      rel="noopener noreferrer"
      style={{ paddingLeft: spaces.xxs }}
    >
      {'hier erfahren Sie Ihre ÖÄK Nummer'}
    </a>
  )
}

export default OidLink
