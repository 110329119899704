import React, { useContext, MouseEventHandler } from 'react'
import styled from 'styled'

import context from './context'

const StyledMenuListItem = styled.li`
  white-space: nowrap;
  padding: ${props => props.theme.spaces.insetSquish.s};

  background: ${props => props.theme.colors.background};

  :hover {
    cursor: pointer;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.background};
  }

  /*:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors['grey-80']};
  } */
`

type MenuListItemProps = {
  onClick?: MouseEventHandler<HTMLLIElement>
  children?: React.ReactNode
}

function MenuListItem(props: MenuListItemProps) {
  const { setIsOpen } = useContext(context)
  return (
    <StyledMenuListItem
      role="menuitem"
      tabIndex={-1}
      onClick={event => {
        if (props.onClick) {
          props.onClick(event)
        }
        setIsOpen(false)
      }}
    >
      {props.children}
    </StyledMenuListItem>
  )
}

export default MenuListItem
