import React, { useState, ReactNode, CSSProperties } from 'react'

import StepsContext from './context'
import useStepControls from './useStepControls'
import StepList from './StepList'
import Step from './Step'
import StepsPanes from './Panes'
import StepsPane from './Pane'

type StepsProps = {
  defaultActiveIndex?: number
  activeIndex?: number
  onChange?: (index: number) => void
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

const noop = () => {}

function Steps(props: StepsProps) {
  const [activeIndex, setActiveIndex] = useState(props.defaultActiveIndex || 0)
  const isControlled = props.activeIndex !== undefined
  const actualIndex = isControlled ? (props.activeIndex as number) : activeIndex

  const contextState = {
    activeIndex: actualIndex,
    selectStep: isControlled ? props.onChange || noop : setActiveIndex,
  }

  return (
    <StepsContext.Provider value={contextState}>
      <div className={props.className} style={props.style}>
        {props.children}
      </div>
    </StepsContext.Provider>
  )
}

Steps.StepList = StepList
Steps.Step = Step
Steps.Panes = StepsPanes
Steps.Pane = StepsPane
Steps.Context = StepsContext

export default Steps

export { StepList, Step, StepsPanes, StepsPane, StepsContext, useStepControls }
