import React, { CSSProperties, ReactNode, useMemo } from 'react'
import { H1, H2, H3 } from './styled-headers'

type TitleComponentTypes = 'h1' | 'h2' | 'h3'

type TitleProps = {
  as?: TitleComponentTypes
  children: ReactNode
  className?: string
  style?: CSSProperties
}

function Title(props: TitleProps) {
  const { as = 'h1', ...componentProps } = props
  const Component = useComponent(as)
  return <Component {...componentProps} />
}

function useComponent(as: TitleComponentTypes) {
  return useMemo(
    () => {
      switch (as) {
        case 'h1':
          return H1
        case 'h2':
          return H2
        case 'h3':
          return H3
      }
    },
    [as]
  )
}

export default Title
