import React, { useEffect } from 'react'
import * as yup from 'yup'
import { mergeDeepRight } from 'ramda'
import { useTheme } from 'styled'

import { AlertBox } from 'components/Alert'
import PageHero from 'components/PageHero'
import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import Input from 'components/Input'
import Label from 'components/Label'
import RadioGroup from 'components/RadioGroup'
import InputGroup from 'components/InputGroup'
import InputGrid from 'components/InputGrid'
import Button from 'components/Button'
import InputError from 'components/InputError'

import { requiredError, tooLongError } from 'messages/errors'
import { useApi, useYup, useFormFields } from 'hooks'
import useUserInfo from 'auth/useUserInfo'
import * as API from 'api'

import { Pharma, UserGroup } from 'types'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
const initialFormFields: Omit<Pharma, 'id'> = {
  gender: '',
  title: '',
  name: '',
  surname: '',
  email: '',
  company: '',
  department: '',
}

function ProfilePharmaForm() {
  const { spaces } = useTheme()
  const [
    { data: profile, loading: loadingProfile, error: loadingProfileError },
    getProfile,
  ] = useApi<[string], Pharma>(API.getProfile)
  const { fields, values } = useFormFields(
    mergeDeepRight(initialFormFields, profile || {})
  )

  const { validate, errors } = useYup(values, validationSchema)

  const userInfo = useUserInfo()
  const [
    {
      data: saveProfileResult,
      loading: savingProfile,
      error: saveProfileError,
    },
    saveProfile,
  ] = useApi(API.saveProfile)

  useEffect(() => {
    if (userInfo) {
      getProfile(userInfo.attributes.sub)
    }
  }, [getProfile, userInfo])

  const handleProfileSave = async () => {
    const { isValid } = await validate()
    if (isValid) {
      if (!userInfo) {
        throw new Error('no user info available.')
      }
      saveProfile({
        ...values,
        id: userInfo.attributes.sub,
        type: UserGroup.PHARMA,
      })
    }
  }

  const disabled = loadingProfile || savingProfile
  return (
    <>
      <PageHero>
        <PageHero.Title>Profil - Pharma</PageHero.Title>
      </PageHero>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">
            Personen Daten
          </FormDescription.Heading>
          <FormDescription.Content>
            Bitte geben Sie hier Ihre personenbezogenen Daten an.
          </FormDescription.Content>
        </FormDescription>
        <form onSubmit={evt => evt.preventDefault()}>
          <InputGrid>
            {saveProfileResult && !saveProfileError && (
              <AlertBox type="success">
                Profildaten wurden erfolgreich gespeichert.
              </AlertBox>
            )}
            {saveProfileError && (
              <AlertBox type="error">
                Beim Speichern der Profildaten ist leider ein Fehler
                aufgetreten.
              </AlertBox>
            )}
            {loadingProfileError && (
              <AlertBox type="error">
                Beim Laden der Profildaten ist leider ein Fehler aufgetreten.
              </AlertBox>
            )}
            <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
              <Label>Anrede</Label>
              <RadioGroup {...fields.gender}>
                <InputGroup>
                  <RadioGroup.Button
                    value="male"
                    style={{ flex: '1', textAlign: 'center' }}
                    disabled={disabled}
                  >
                    Herr
                  </RadioGroup.Button>
                  <RadioGroup.Button
                    value="female"
                    style={{ flex: '1', textAlign: 'center' }}
                    disabled={disabled}
                  >
                    Frau
                  </RadioGroup.Button>
                </InputGroup>
              </RadioGroup>
              {errors.gender && <InputError>{errors.gender}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
              <Label>Titel</Label>
              <Input type="text" {...fields.title} disabled={disabled} />
              {errors.title && <InputError>{errors.title}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
              <Label>Vorname</Label>
              <Input type="text" {...fields.name} disabled={disabled} />
              {errors.name && <InputError>{errors.name}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
              <Label>Nachname</Label>
              <Input type="text" {...fields.surname} disabled={disabled} />
              {errors.surname && <InputError>{errors.surname}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
              <Label>Firma</Label>
              <Input type="text" {...fields.company} disabled={disabled} />
              {errors.company && <InputError>{errors.company}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
              <Label>Abteilung</Label>
              <Input type="text" {...fields.department} disabled={disabled} />
              {errors.department && (
                <InputError>{errors.department}</InputError>
              )}
            </InputGrid.Item>
            <div style={{ margin: spaces.stack.l }}>
              <Label>Email</Label>
              <Input
                type="text"
                value={userInfo ? userInfo.attributes.email : ''}
                disabled
              />
            </div>
            <Button
              type="submit"
              variant="primary"
              style={{ justifySelf: 'start' }}
              onClick={handleProfileSave}
              disabled={disabled}
            >
              <span>Profil speichern</span>
            </Button>
          </InputGrid>
        </form>
      </FormGrid>
    </>
  )
}

const validationSchema = yup.object().shape({
  title: yup.string().max(40, tooLongError('Titel', 40)),
  gender: yup.string().required(requiredError('Anrede')),
  name: yup
    .string()
    .max(50, tooLongError('Vorname', 50))
    .required(requiredError('Vorname')),
  surname: yup
    .string()
    .max(50, tooLongError('Nachname', 50))
    .required(requiredError('Nachname')),
  company: yup
    .string()
    .max(50, tooLongError('Firma', 50))
    .required(requiredError('Firma')),
  department: yup
    .string()
    .max(50, tooLongError('Abteilung', 50))
    .required(requiredError('Abteilung')),
})

export default ProfilePharmaForm
