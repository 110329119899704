import React, { ReactNode } from 'react'
import moment from 'moment'
import {
  Page,
  Text,
  View,
  // Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer'

// const SIGNATURE_HEIGHT = 50

const DocumentTitle = (props: { children: ReactNode }) => (
  <Text style={styles.letterTitel}>{props.children}</Text>
)
const SubTitle = (props: { children: ReactNode }) => (
  <Text style={styles.subTitle}>{props.children}</Text>
)
const LetterText = (props: { children: ReactNode }) => (
  <Text style={styles.letterText}>{props.children}</Text>
)
const LetterHeadAddress = (props: { children: ReactNode }) => (
  <Text style={styles.letterHeadAddressor}>{props.children}</Text>
)

const TableRows = (props: { children: ReactNode }) => (
  <View style={styles.tableRows}>{props.children}</View>
)
const ListItem = (props: { children: ReactNode }) => (
  <View style={styles.listItem} wrap={false}>
    <Text style={styles.listItemBullet}>{`\u2022 `}</Text>
    <Text style={styles.letterText}>{props.children}</Text>
  </View>
)

interface AddressData {
  name: string
  department?: string
  street: string
  zip: string | number
  city: string
  phone?: string
  fax?: string
}

const Receiver = ({
  address,
  originCity,
}: {
  address: AddressData
  originCity: string
}) => {
  return (
    <View style={styles.letterHead}>
      <LetterHeadAddress>An:</LetterHeadAddress>
      <LetterHeadAddress>{address.name}</LetterHeadAddress>
      {!!address.department && (
        <LetterHeadAddress>{address.department}</LetterHeadAddress>
      )}
      <LetterHeadAddress>{address.street}</LetterHeadAddress>
      <LetterHeadAddress>{`${address.zip} ${address.city}`}</LetterHeadAddress>
      {!!address.phone && (
        <LetterHeadAddress>{address.phone}</LetterHeadAddress>
      )}
      {!!address.fax && <LetterHeadAddress>{address.fax}</LetterHeadAddress>}
      <Text style={styles.letterHeadDate}>
        {`${originCity}, am ${moment().format('LL')}`}
      </Text>
    </View>
  )
}

interface PdfDocProps {
  children: ReactNode
}

const PdfDoc = (props: PdfDocProps) => {
  return (
    <Document>
      <Page style={styles.body}>{props.children}</Page>
    </Document>
  )
}

PdfDoc.Title = DocumentTitle
PdfDoc.SubTitle = SubTitle
PdfDoc.Text = LetterText
PdfDoc.HeadAddress = LetterHeadAddress
PdfDoc.TableRow = TableRows
PdfDoc.ListItem = ListItem

PdfDoc.Receiver = Receiver

export default PdfDoc

const baseFontSize = 10

const styles = StyleSheet.create({
  body: {
    paddingTop: 50,
    paddingBottom: 65,
    paddingLeft: 65,
    paddingRight: 65,
    fontSize: baseFontSize,
  },
  letterHead: {
    marginTop: 90,
  },
  letterHeadToSymbol: {
    fontSize: baseFontSize - 1,
  },
  letterHeadAddressor: {
    fontSize: baseFontSize,
    lineHeight: 1.4,
  },
  letterHeadDate: {
    textAlign: 'right',
    marginTop: -14,
  },
  letterTitel: {
    marginTop: 75,
    marginBottom: 14,
    fontSize: baseFontSize + 10,
  },
  subTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: baseFontSize + 4,
  },
  letterText: {
    fontSize: baseFontSize,
    lineHeight: 1.4,
  },
  tableRows: {
    display: 'flex',
    flexDirection: 'row',
  },
  listItem: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  listItemBullet: {
    fontSize: baseFontSize,
    lineHeight: 1.4,
    width: 12,
  },
  signatureBox: {
    width: 250,
    marginBottom: 15,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: baseFontSize - 2,
    bottom: 30,
    left: 0,
    right: 0,
    paddingHorizontal: 35,
    textAlign: 'right',
    color: 'grey',
  },
})
