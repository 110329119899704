import { useState, RefObject, useLayoutEffect, useCallback } from 'react'


const props = ['width', 'height', 'top', 'right', 'bottom', 'left', 'x', 'y'];
// @ts-ignore
const rectChanged = (a = {}, b = {}) => props.some(prop => a[prop] !== b[prop]);

function getRect(el: HTMLElement | null) {
  if (!el) {
    return null
  }
  return el.getBoundingClientRect() as (DOMRect & ClientRect)
}

// type UseElementRectOptions = {
//   observe?: boolean
// }

// const defaultOptions: UseElementRectOptions = {
//   observe: false,
// }

// TODO: observer required ? 
// https://github.com/reach/observe-rect/blob/master/src/index.js
// https://github.com/reach/reach-ui/blob/master/packages/rect/src/index.js

function useElementRect(
  ref: RefObject<HTMLElement>
) {
  const [rect, setRect] = useState(getRect(ref.current))

  const handleResize = useCallback(() => {
    if (ref && ref.current) {
      const newRect = getRect(ref.current)
      if (rectChanged(rect || {}, newRect || {})) {
        setRect(newRect)
      }
    }
  }, [rect, ref])

  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return rect
}

export default useElementRect
