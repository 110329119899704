import React, { ReactNode } from 'react'

import { useTheme } from 'styled'
import { lighten } from 'polished'
import useDocumentTitle from 'hooks/useDocumentTitle'
import TitleComponent from 'components/Title'

type Props = {
  children: ReactNode
}

function Title({ children }: Props) {
  useChildAsDocumentTitle(children)
  const theme = useTheme()

  return (
    <TitleComponent
      as="h2"
      style={{ margin: 0, color: lighten(0.1, theme.colors['grey-50']) }}
    >
      {children}
    </TitleComponent>
  )
}

function useChildAsDocumentTitle(children: ReactNode) {
  const title = React.Children.toArray(children).reduce(
    (res: string, child) => {
      if (typeof child === 'string') {
        res += child
      }
      return res
    },
    ''
  )

  useDocumentTitle(title)
}

export default Title
