/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { CSSProperties } from 'react'

import  { StepListChildProps } from '../StepList'
import StyledStep from './Step'

export interface StepProps extends StepListChildProps {
  children: React.ReactNode,
  className?: string,
  style?: CSSProperties
}


function Step(props: StepProps) {
  return (
    <StyledStep
      onClick={props.disabled ? undefined : props.onSelect}
      isActive={props.isActive}
      disabled={props.disabled}
      className={props.className}
      style={props.style}
      role="tab"
    >
      <a href="#" onClick={e => e.preventDefault()}>
        {props.children}
      </a>
    </StyledStep>
  )
}

export default Step
