
const identityPoolId = process.env.REACT_APP_IDENTITY_POOL
const userPoolId = process.env.REACT_APP_USER_POOL
const userPoolWebClientId = process.env.REACT_APP_COGNITO_CLIENT_ID

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT

const stage = process.env.REACT_APP_STAGE
const storage = stage === 'dev' ? window.localStorage : window.sessionStorage

// this workaround is important to fix amplify bug !
// in the current version they check for Object.keys(storage).length,
// which can be 0 with the localStorage/sessionStorage object
// a better validity check is already in the sourcecode, though not released
storage.setItem('amplify-1.1.28-FIX', 'set my length > 0')


const Auth = {
  region: 'eu-central-1',
  identityPoolId,
  userPoolId,
  userPoolWebClientId,
  // mandatorySignIn: true,
  authenticationFlowType: 'CUSTOM_AUTH',
  storage: storage,
}

const Api = {
  endpoint: apiEndpoint
}

export default {
  Auth,
  Api
}

export { Auth as AuthConfig, Api as ApiConfig }
