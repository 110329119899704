import { useContext } from 'react'

import authContext from './context'

function useUserInfo() {
  const context = useContext(authContext)

  return context.userInfo
}

export default useUserInfo
