import styled from 'styled'

const InputAddon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  color: ${props => props.theme.colors['grey-50']};
  border: 1px solid ${props => props.theme.colors["grey-70"]};
  border-left: 0;
  border-radius: ${props => props.theme.radius.s};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
`

export default InputAddon
