import React, { MutableRefObject, RefObject } from 'react'

type MenuContextType = {
    isOpen: boolean,
    toggleOpen: () => void,
    setIsOpen: (value: boolean) => void,
    registerButton: (ref: RefObject<HTMLElement>) => void
    buttonRef: MutableRefObject<HTMLElement | null>,
    registerMenuList: (ref: RefObject<HTMLElement>) => void,
    menuListRef: MutableRefObject<HTMLElement | null>,
}

const context = React.createContext<MenuContextType>({
    isOpen: false,
    toggleOpen: () => {},
    setIsOpen: () => {},
    registerButton: () => {},
    buttonRef: { current: null },
    registerMenuList: () => {},
    menuListRef: { current: null }
});

export default context

export const MenuProvider = context.Provider
