import styled from 'styled'

const PharmaArea = styled.div`
  display: grid;

  grid-column-gap: ${props => props.theme.spaces.xs};

  grid-template-columns: auto auto;
  ${props => props.theme.media.phone`grid-template-columns: auto;`};

  justify-items: end;
  align-items: end;

  margin: 0;
`
export default PharmaArea
