import React, { lazy, Suspense, useState, useMemo } from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import LoadingPage from 'containers/LoadingPage'
import ClaimSendWarning from 'containers/ClaimSendWarning'

import DefaultPageLayout from 'layout/DefaultPageLayout'

import NotFound from 'pages/NotFound'
import { ClaimConfigProvider, useInitialClaimConfig } from 'claimbuilder'
import { useUserInGroup } from 'auth'
import { UserGroup } from 'types'

const Cuvitru = lazy(() => import('./Cuvitru'))
const Elmiron = lazy(() => import('./Elmiron'))
const ExempliGratia = lazy(() => import('./ExempliGratia'))
const HyQvia = lazy(() => import('./HyQvia'))
const Metamorphoses = lazy(() => import('./Metamorphoses'))
const Natpar = lazy(() => import('./Natpar'))
const Revestive = lazy(() => import('./Revestive'))
const Takhzyro = lazy(() => import('./Takhzyro'))

function ClaimRouter(props: RouteComponentProps) {
  const initialConfig = useInitialClaimConfig()
  const [config, setConfig] = useState(initialConfig)
  const isDoctor = useUserInGroup(UserGroup.DOCTOR)

  const configContextValue = useMemo(
    () => ({
      config,
      setConfig,
    }),
    [config, setConfig]
  )

  return (
    <DefaultPageLayout>
      <Suspense fallback={<LoadingPage />}>
        <ClaimConfigProvider value={configContextValue}>
          {isDoctor && ( 
            <ClaimSendWarning />
          )}
          <Router>
            <ExempliGratia path="exempligratia" />
            <Metamorphoses path="metamorphoses" />

            <Cuvitru path="cuvitru" />
            <Elmiron path="elmiron" />
            <HyQvia path="hyqvia" />
            <Natpar path="natpar" />
            <Revestive path="revestive" />
            <Takhzyro path="takhzyro" />

            <NotFound default />
          </Router>
        </ClaimConfigProvider>
      </Suspense>
    </DefaultPageLayout>
  )
}

export default ClaimRouter
