import React, { CSSProperties, ReactNode } from 'react'
import styled from 'styled'

import Button from 'components/Button'
import FaIcon from 'components/FaIcon'

type AlertBoxType = 'error' | 'warning' | 'success'
type AlertBoxProps = {
  type: AlertBoxType
  children: ReactNode
  onClose?: () => void
  className?: string
  style?: CSSProperties
}

const AlertContainer = styled.div<{ type: AlertBoxType }>`
  z-index: 10001;
  width: 100%;
  padding: ${props => props.theme.spaces.insetSquish.l};

  background: ${props => {
    if (props.type === 'error') {
      return props.theme.colors.danger
    }
    if (props.type === 'warning') {
      return props.theme.colors.warning
    }
    return props.theme.colors.success
  }};

  border-radius: ${props => props.theme.radius.s};

  color: #ffffff;
  display: relative;
  display: flex;

  > *:not(:last-child) {
    margin: ${props => props.theme.spaces.inline.m};
  }
`

const AlertBoxTitle = styled.div`
  font-weight: 600;
  margin: ${props => props.theme.spaces.stack.xxs};
`

const titleMap = {
  error: 'Fehler',
  warning: 'Warnung',
  success: 'Erfolgreich',
}

const iconMap = {
  error: 'exclamation-circle' as 'exclamation-circle',
  warning: 'exclamation-circle' as 'exclamation-circle',
  success: 'check-circle' as 'check-circle',
}

const AlertBox = ({ type, children: message, ...props }: AlertBoxProps) => {
  const title = titleMap[type]
  const iconName = iconMap[type]

  return (
    <AlertContainer type={type} className={props.className} style={props.style} role="alert">
      <div>
        <FaIcon name={iconName} size="2x" />
      </div>
      <div style={{ flex: 1 }}>
        <AlertBoxTitle>{title}</AlertBoxTitle>
        <div>{message}</div>
      </div>
      {props.onClose && (
        <div>
          <Button
            type="button"
            onClick={props.onClose}
            variant="tertiary"
            style={{ color: 'inherit', padding: 2 }}
          >
            <FaIcon
              name="times"
              size="1x"
              style={{ transform: 'scale(1.2)' }}
            />
          </Button>
        </div>
      )}
    </AlertContainer>
  )
}

export default AlertBox
