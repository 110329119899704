import React, { ReactNode, CSSProperties } from 'react'

import {
  BadgeContainer,
  BadgeTextArea,
  BadgeIconTextArea,
  BadgeIcon,
} from './styled-badge'
import FaIcon, { FaIconProps } from 'components/FaIcon'

type IconName = FaIconProps["name"]

type BadgeProps = {
  children?: ReactNode
  className?: string
  style?: CSSProperties
  icon?: IconName 
}

function Badge(props: BadgeProps) {
  return (
    <>
      <BadgeContainer>
        {props.icon ? (
          <>
            <BadgeIcon>
              <FaIcon name={props.icon} />
            </BadgeIcon>
            <BadgeIconTextArea>{props.children}</BadgeIconTextArea>
          </>
        ) : (
          <BadgeTextArea>{props.children}</BadgeTextArea>
        )}
      </BadgeContainer>
    </>
  )
}

export default Badge
