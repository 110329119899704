import React, { useEffect } from 'react'
import { useTheme } from 'styled'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
// import Input from 'components/Input'
// import Label from 'components/Label'
import InputGrid from 'components/InputGrid'
// import InputError from 'components/InputError'
import Button from 'components/Button'
import { AlertBox, useAlert } from 'components/Alert'

import * as API from 'api'
import { useApi } from 'hooks'
import { useUserInfo } from 'auth'

type VerifiedType = 'VERIFIED' | 'PENDING' | 'UNVERIFIED'

// TODO: revoke mail verification

function ValidateEmailForm() {
  const { colors } = useTheme()
  const userInfo = useUserInfo()
  const userId = userInfo ? userInfo.attributes.sub : ''
  const [alert] = useAlert()
  const [
    {
      data: verificationStatusArray,
      loading: statusLoading,
      error: statusError,
    },
    emailVerified,
  ] = useApi(API.emailVerified)

  useEffect(() => {
    if (statusError) {
      alert.error('Fehler beim laden des E-Mail Verifikations-Status')
    }
  }, [alert, statusError])

  useEffect(() => {
    if (userId) {
      emailVerified(userId)
    }
  }, [emailVerified, userId])

  const verificationStatus: VerifiedType | null = verificationStatusArray
    ? verificationStatusArray[0].status
    : null

  const getVerifiedText = (status: VerifiedType | null) => {
    if (null) {
      return 'n/a'
    }
    switch (status) {
      case 'VERIFIED':
        return 'E-Mail Adresse ist verifizert'
      case 'PENDING':
        return 'Bitte bestätigen Sie die Verifizerungs-Email.'
      default:
        return 'E-Mail Adresse ist nicht verifiziert, daher können Anträge noch nicht elektronisch übermittelt werden.'
    }
  }

  return (
    <form onSubmit={evt => evt.preventDefault()}>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading>E-Mail verifizieren</FormDescription.Heading>
          <FormDescription.Content>
            {
              'Hier können Sie Ihre E-Mail Adresse verifizieren, um Anträge elektronisch an die Krankenkassen zu übermitteln'
            }
          </FormDescription.Content>
        </FormDescription>
        <InputGrid>
          <FormDescription.Content style={{ margin: 0 }}>
            {!statusLoading && (
              <strong
                style={{
                  color:
                    verificationStatus === 'VERIFIED'
                      ? colors.success
                      : colors.warning,
                }}
              >
                {getVerifiedText(verificationStatus)}
              </strong>
            )}
          </FormDescription.Content>
          {!statusLoading && verificationStatus === 'VERIFIED' && (
            <RevokeVerification
              onStatusChange={() => {
                emailVerified(userId)
              }}
            />
          )}

          {!statusLoading && verificationStatus === 'UNVERIFIED' && (
            <VerifyMail
              onStatusChange={() => {
                emailVerified(userId)
              }}
            />
          )}
          <div></div>
        </InputGrid>
      </FormGrid>
    </form>
  )
}

function RevokeVerification(props: { onStatusChange: () => void }) {
  const userInfo = useUserInfo()
  const [revokeResponse, revokeEmailVerification] = useApi(
    API.revokeEmailVerification
  )

  useEffect(() => {
    if (revokeResponse.data) {
      props.onStatusChange()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revokeResponse.data])

  const handleRevoke = () => {
    const confirm = window.confirm('Wollen sie die E-Mail Verifizerung wirklich löschen?')
    if (confirm && userInfo) {
      revokeEmailVerification(userInfo.attributes.sub)
    }
  }
  return (
    <>
      {revokeResponse.error && (
        <AlertBox type="error">
          {'Ein Fehler ist beim Löschen der Verifizierung aufgetreten.'}
        </AlertBox>
      )}
      <Button
        style={{ justifySelf: 'start' }}
        variant="secondary"
        onClick={handleRevoke}
        disabled={revokeResponse.loading}
      >
        <span>{'E-Mail Verifizierung löschen'}</span>
      </Button>
    </>
  )
}

function VerifyMail(props: { onStatusChange: () => void }) {
  const [
    { data: verifyResponse, loading: verifyLoading, error: verifyError },
    verifyEmail,
  ] = useApi(API.verifyEmail)
  const userInfo = useUserInfo()

  const handleVerify = () => {
    if (userInfo) {
      verifyEmail(userInfo.attributes.sub)
    }
  }

  useEffect(() => {
    if (verifyResponse) {
      props.onStatusChange()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyResponse])
  return (
    <>
      {verifyError && (
        <AlertBox type="error">
          Ein Fehler ist bei der E-Mail verifizierung aufgetreten.
        </AlertBox>
      )}
      <Button
        style={{ justifySelf: 'start' }}
        variant="primary"
        onClick={handleVerify}
        disabled={verifyLoading}
      >
        <span>E-Mail verifizieren</span>
      </Button>
    </>
  )
}

export default ValidateEmailForm
