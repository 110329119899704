/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
} from 'react'
import { equals } from 'ramda'

type InitalValuesType = { [key: string]: any }
type SetValueFn<T> = <K extends keyof T>(key: K, value: T[K]) => void
type StringInputFieldBindings<T, K extends keyof T> = {
  name: K
  value: T[K]
  onChange: ChangeEventHandler<HTMLElement>
}
type InputFieldBindings<T, K extends keyof T> = { name: K; value: T[K] }
type Fields<T> = {
  [Key in keyof T]: T[Key] extends string
    ? StringInputFieldBindings<T, Key>
    : T[Key] extends number
    ? InputFieldBindings<T, Key>
    : T[Key] extends boolean
    ? InputFieldBindings<T, Key>
    : T[Key] extends Date
    ? InputFieldBindings<T, Key>
    : Fields<T[Key]>
}

function useFormFields<T extends InitalValuesType, K extends keyof T>(
  initalValues: T
) {
  const [state, setState] = useState(initalValues)
  const isDirty = useMemo(() => !equals(state, initalValues), [
    state,
    initalValues,
  ])
  const reset = useCallback((values?: T) => setState(values || initalValues), [
    JSON.stringify(initalValues),
  ])
  const setValue = useCallback<SetValueFn<T>>(
    (key, value) => setState(prevState => ({ ...prevState, [key]: value })),
    []
  )
  const onChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.currentTarget
    if (!(name in initalValues)) {
      throw new Error(
        `Field name "${name}" is not a valid value. Must be one of [${keys}].`
      )
    }
    setValue(name as K, value as T[K])
  }, [])

  // set new initial values if provided
  useEffect(
    () => {
      setState(initalValues)
    },
    [JSON.stringify(initalValues)]
  )

  // THIS RECURSION DOES NOT WORK AT ALL 
  // eslint-disable-next-line @typescript-eslint/no-angle-bracket-type-assertion
  const keys = <K[]>Object.keys(initalValues)
  const reduceFn = (res: any, key: any) => {
    if (typeof state[key] === 'object') {
      const subKeys = Object.keys(state[key])
      res[key] = subKeys.reduce(reduceFn, {})
    } else {
      // @ts-ignore
      res[key] = {
        name: key,
        value: state[key],
      }
      if (typeof state[key] === 'string') {
        // @ts-ignore
        res[key].onChange = onChange
      }
    }
    return res
  }

  const fields = keys.reduce(reduceFn, {}) as Fields<T>

  return {
    values: state,
    isDirty,
    reset,
    setValue,
    fields,
  }
}

export default useFormFields
