import React, { SyntheticEvent, ChangeEvent } from 'react'
import * as yup from 'yup'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import TextArea from 'components/TextArea'
import {
  ArgumentGrid,
  Argument,
  ArgumentHeadline,
  ArgumentSubline,
} from 'containers/ClaimArguments/styled-arguments'

import { useTheme } from 'styled'
import { ArgumentDefinition, useClaim, ClaimStepFooter } from 'claimbuilder'
import { useFormFields, useYup } from 'hooks'
import { tooLongError } from 'messages/errors'
import CharacterCounter from 'components/CharacterCounter'
import InputError from 'components/InputError'
import {
  isArgumentsStepDefinition,
  DefaultArgumentsStepDefinition,
} from 'claimbuilder/types'
import Checkbox from 'components/Checkbox'

export const DefaultArgumentStep = () => {
  const { spaces } = useTheme()

  const { values, fields, setValue } = useFormFields(initialFormFields)
  const { errors, validate } = useYup(values, validationSchema)

  const claim = useClaim()
  const step = claim.definition.steps.find(
    isArgumentsStepDefinition
  ) as DefaultArgumentsStepDefinition
  const claimArguments = step.arguments

  const getArgByLabel = (label: string, args = claimArguments) =>
    args.find(arg => arg.label === label)

  function handleArgChange(evt: ChangeEvent<HTMLInputElement>) {
    const { checked, name: label } = evt.currentTarget
    if (checked) {
      const newArg = getArgByLabel(label)
      if (!newArg) {
        throw new Error('Unable to find argument in definition')
      }
      setValue('arguments', [...values.arguments, newArg])
    } else {
      setValue('arguments', values.arguments.filter(arg => arg.label !== label))
    }
  }

  const { setData } = useClaim()
  const handleNext = async (evt: SyntheticEvent<HTMLButtonElement>) => {
    const { isValid } = await validate()
    if (isValid) {
      const args: ArgumentDefinition[] = [{ label: values.other }].concat(values.arguments)
        .filter(arg => !!arg && !!arg.label)
      setData(prev => ({
        ...prev,
        arguments: args
      }))
    } else {
      evt.preventDefault()
    }
  }

  return (
    <>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">
            {'Argumente'}
          </FormDescription.Heading>
          <FormDescription.Content>
            <p>
              {'Bitte begründen Sie den Antrag auf Bewilligung des Präparates.'}
            </p>
            <p>
              {
                'Exemplarisch finden Sie unten eine Liste an Argumenten, die gegebenenfalls gewählt werden können.'
              }
            </p>
          </FormDescription.Content>
        </FormDescription>
        <div>
          <Argument style={{ margin: spaces.stack.l }}>
            <ArgumentHeadline style={{ padding: '0', margin: spaces.stack.xs }}>
              {'Begründung'}
            </ArgumentHeadline>
            <TextArea {...fields.other} />
            <CharacterCounter
              maxCharacters={500}
              currentCharacters={fields.other.value.length}
            />
            {errors.arguments && <InputError>{errors.arguments}</InputError>}
            {errors.other && <InputError>{errors.other}</InputError>}
          </Argument>
          <ArgumentGrid>
            {claimArguments.map(arg => (
              <React.Fragment key={arg.label}>
                <Checkbox
                  name={arg.label}
                  checked={!!getArgByLabel(arg.label, values.arguments)}
                  onChange={handleArgChange}
                />
                <Argument>
                  <ArgumentHeadline>{arg.label}</ArgumentHeadline>
                  <ArgumentSubline>{arg.description}</ArgumentSubline>
                </Argument>
              </React.Fragment>
            ))}
          </ArgumentGrid>
        </div>
      </FormGrid>
      <ClaimStepFooter onCommit={handleNext} />
    </>
  )
}

const validationSchema = yup.object().shape({
  arguments: yup
    .string()
    .test(
      'other',
      'Bitte geben Sie eine Begründung an, und/oder wählen Sie ein Argument aus.',
      function() {
        return (
          this.parent.arguments.length > 0 ||
          (this.parent.other && this.parent.other.trim())
        )
      }
    ),
  other: yup.string().max(500, tooLongError('Sonstiges', 500)),
})

const initialFormFields = {
  arguments: [] as ArgumentDefinition[],
  other: '',
}
