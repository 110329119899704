import React, { CSSProperties, useCallback, MouseEvent } from 'react'
import { LinkGetProps } from '@reach/router'
import { useTheme } from 'styled'
import Link from './Link'

type HeaderMenuLinkProps = {
  to?: string
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

function HeaderMenuLink(props: HeaderMenuLinkProps) {
  const { style, ...linkProps } = props
  const getLinkProps = useLinkProps(style)
  return <Link {...linkProps} getProps={getLinkProps} />
}

function useLinkProps(style?: CSSProperties) {
  const theme = useTheme()
  return useCallback(
    ({ isPartiallyCurrent, href, isCurrent }: LinkGetProps) => {
      const isHighlighted = (isPartiallyCurrent && href !== '/') || isCurrent
      return {
        // please DO NOT return className here
        // since it would break the styled-components Link element
        style: {
          ...style,
          color: isHighlighted
            ? theme.colors['grey-30']
            : style
            ? style.color
            : undefined,
          borderBottom: isHighlighted
            ? '5px solid'
            : style
            ? style.borderBottom
            : undefined,
          borderColor: isHighlighted
            ? theme.colors.primary
            : style
            ? style.borderColor
            : undefined,
          padding: isHighlighted
            ? '8px 0 3px 0'
            : style
            ? style.paddingBottom
            : '8px 0 8px 0',
          marginLeft: theme.spaces.xl,
        },
      }
    },
    [style, theme.colors, theme.spaces.xl]
  )
}

export default HeaderMenuLink
