import React from 'react'
import styled from 'styled'
import { navigate, RouteComponentProps } from '@reach/router'

import Title from 'components/Title'
import Button from 'components/Button'

import DefaultLayout from 'layout/DefaultPageLayout'

const Container = styled.div``

const ErrorCode = styled.span`
  color: ${props => props.theme.colors['grey-90']};
  font-size: 132px;
  margin: 20px 0 0 0;
  font-weight: bold;
`

const NotFoundPage = (props: RouteComponentProps) => (
  <DefaultLayout>
    <Container>
      <ErrorCode>403</ErrorCode>
      <Title style={{ marginTop: 0, marginBottom: 10 }}>
        Zugriff verweigert
      </Title>
      <p style={{ marginTop: 0 }}>
        Sie haben keine Berechtigung diese Seite zu besuchen.
      </p>
      <Button onClick={() => navigate('/')} variant="primary">
        Zurück
      </Button>
    </Container>
  </DefaultLayout>
)

export default NotFoundPage
