import { css } from 'styled'

const StyledInput = css`
  border: 1px solid ${props => props.theme.colors["grey-70"]};

  border-radius: ${props => props.theme.radius.s};

  height: 36px;
  font-size: 16px;
  padding: ${props=> props.theme.spaces.xs} 12px ${props=> props.theme.spaces.xs} 12px;
  width: 100%;

  &:focus {
    border: 1px solid ${props => props.theme.colors["grey-30"]};
  }

  &:disabled, &:read-only {
    background: ${props => props.theme.colors['grey-95']};
  }
`

export default StyledInput;
