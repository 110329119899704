import { intersection } from 'ramda'

import useUserInfo from './useUserInfo'

import { UserGroup } from 'types'

function useUserInGroup(group?: UserGroup | UserGroup[]) {
  const userInfo = useUserInfo()
  if (!userInfo) {
    return false
  }
  if (!group) {
    return true
  }

  const requiredGroups = Array.isArray(group) ? group : [group]
  const userIsInGroup = intersection(requiredGroups, userInfo.groups).length > 0
  return userIsInGroup
}

export { UserGroup }

export default useUserInGroup;
