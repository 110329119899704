import styled from 'styled'

import Container from '../Container'

export const Wrapper = styled.div`
  background: ${props => props.theme.colors['grey-20']};
  color: ${props => props.theme.colors['grey-80']};

  a {
    color: ${props => props.theme.colors['grey-80']};
    text-decoration: none;

    &:visited {
      color: ${props => props.theme.colors['grey-80']};
      text-decoration: none;
    }

    &:hover {
      color: ${props => props.theme.colors['grey-90']};
      text-decoration: none;
    }
  }
`
// @ts-ignore
export const ContainerGrid = styled(Container)`
  display: grid;
  margin-top: ${props => props.theme.spaces.m};
  margin-bottom: ${props => props.theme.spaces.m};

  grid-template-columns: 170px auto 170px;
  ${props => props.theme.media.phone`grid-template-columns: auto;`}

  align-items: center;

  > :nth-child(2n) {
    justify-self: center;
    ${props => props.theme.media.phone`justify-self: start;`}
  }

  > :nth-child(3n) {
    justify-self: end;
    ${props => props.theme.media.phone`justify-self: start;`}
  }
`

export const LinkList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const LinkListItem = styled.li`
  position: relative;
  margin: ${props => props.theme.spaces.inset.s};

  ${props =>
    props.theme.media.phone`margin: ${props => props.theme.spaces.inset.xs};`}

  &:not(:last-child):after {
    content: ' ';
    position: absolute;
    right: -${props => props.theme.spaces.s};
    ${props => props.theme.media.phone`right: -9px;`}
    top: 20%;
    height: 60%;
    width: 2px;
    background: ${props => props.theme.colors['grey-80']};
  }

  &:first-child {
    ${props => props.theme.media.phone`margin-left: 0;`}
  }
`

export const Logo = styled.div`
  justify-self: start;
  color: ${props => props.theme.colors['grey-80']};
  font-weight: 600;
  cursor: pointer;
`
