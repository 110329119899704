import { useState } from 'react'

import { forgotPassword as forgotPasswordFunction } from './util'

export interface ForgotPasswordError extends Error {
  name: 'UserNotFoundException' | 'InvalidParameterException' | 'NotAuthorizedException'
}

/**
 * react hook to request a new cognito password
 * 
 * error.name types:
 *  - UserNotFoundException: user not found
 *  - InvalidParameterException: Cannot reset password for the user as there is no registered/verified email or phone_number
 *  - NotAuthorizedException: User password cannot be reset in the current state.
 */
function useForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ForgotPasswordError | null>(null)
  const [success, setSuccess] = useState<boolean | null>(null)

  const forgotPassword = async (username: string) => {
    let err: ForgotPasswordError | null = null
    try {
      setLoading(true)
      await forgotPasswordFunction(username.toLowerCase())
    } catch (error) {
      err = error
    } finally {
      setError(err)
      setLoading(false)
      setSuccess(err === null)
    }
    return { error: err, success: err === null}
  }

  return {
    forgotPassword,
    loading,
    success,
    error
  }
}

export default useForgotPassword
