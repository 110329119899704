import React, { useEffect } from 'react'
import * as yup from 'yup'
import { mergeDeepRight } from 'ramda'
import { useTheme } from 'styled'

import { AlertBox } from 'components/Alert'
import PageHero from 'components/PageHero'
import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import Input from 'components/Input'
import Label from 'components/Label'
import RadioGroup from 'components/RadioGroup'
import InputGroup from 'components/InputGroup'
import InputGrid from 'components/InputGrid'
import Button from 'components/Button'
import Seperator from 'components/Seperator'
import InputError from 'components/InputError'
import OidLink from 'containers/OidLink'

import { requiredError, tooLongError } from 'messages/errors'
import { useApi, useYup, useFormFields } from 'hooks'
import useUserInfo from 'auth/useUserInfo'
import * as API from 'api'

import { Doctor, UserGroup } from 'types'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
const initialFormFields: Omit<Doctor, 'id'> = {
  gender: '',
  title: '',
  name: '',
  surname: '',
  email: '',
  oid: '',
  hospital: {
    name: '',
    department: '',
    street: '',
    zip: 0,
    city: '',
    phone: '',
    fax: '',
  },
  surgery: {
    name: '',
    street: '',
    zip: 0,
    city: '',
    phone: '',
    fax: '',
  },
}

function ProfileDoctorForm() {
  const { spaces } = useTheme()
  const userInfo = useUserInfo()

  const [
    { data: profile, loading: loadingProfile, error: loadingProfileError },
    getProfile,
  ] = useApi<[string], Doctor>(API.getProfile)
  const { fields, values } = useFormFields(
    mergeDeepRight(initialFormFields, profile || {})
  )

  const {
    fields: surgeryFields,
    setValue: setSurgeryValue,
    values: surgeryValues,
  } = useFormFields(
    mergeDeepRight(
      initialFormFields.surgery,
      profile ? profile.surgery || {} : {}
    )
  )
  const {
    fields: hospitalFields,
    setValue: setHospitalValue,
    values: hospitalValues,
  } = useFormFields(
    mergeDeepRight(
      initialFormFields.hospital,
      profile ? profile.hospital || {} : {}
    )
  )

  const { validate, errors } = useYup(
    {
      ...values,
      surgery: surgeryValues,
      hospital: hospitalValues,
    },
    validationSchema
  )

  const [
    {
      data: saveProfileResult,
      loading: savingProfile,
      error: saveProfileError,
    },
    saveProfile,
  ] = useApi(API.saveProfile)

  useEffect(() => {
    if (userInfo) {
      getProfile(userInfo.attributes.sub)
    }
  }, [getProfile, userInfo])

  const handleProfileSave = async () => {
    const { isValid } = await validate()
    if (isValid) {
      if (!userInfo) {
        throw new Error('no user info available.')
      }
      saveProfile({
        ...values,
        id: userInfo.attributes.sub,
        surgery: surgeryValues,
        hospital: hospitalValues,
        type: UserGroup.DOCTOR,
      })
    }
  }

  const disabled = loadingProfile || savingProfile
  return (
    <>
      <PageHero>
        <PageHero.Title>Profil - Arzt</PageHero.Title>
      </PageHero>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">
            Personen Daten
          </FormDescription.Heading>
          <FormDescription.Content>
            Bitte geben Sie hier Ihre personenbezogenen Daten an.
          </FormDescription.Content>
        </FormDescription>
        <form onSubmit={evt => evt.preventDefault()}>
          <InputGrid>
            {saveProfileResult && !saveProfileError && (
              <AlertBox type="success">
                Profildaten wurden erfolgreich gespeichert.
              </AlertBox>
            )}
            {saveProfileError && (
              <AlertBox type="error">
                Beim Speichern der Profildaten ist leider ein Fehler
                aufgetreten.
              </AlertBox>
            )}
            {loadingProfileError && (
              <AlertBox type="error">
                Beim Laden der Profildaten ist leider ein Fehler aufgetreten.
              </AlertBox>
            )}
            <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
              <Label>Anrede</Label>
              <RadioGroup {...fields.gender}>
                <InputGroup>
                  <RadioGroup.Button
                    value="male"
                    style={{ flex: '1', textAlign: 'center' }}
                    disabled={disabled}
                  >
                    Herr
                  </RadioGroup.Button>
                  <RadioGroup.Button
                    value="female"
                    style={{ flex: '1', textAlign: 'center' }}
                    disabled={disabled}
                  >
                    Frau
                  </RadioGroup.Button>
                </InputGroup>
              </RadioGroup>
              {errors.gender && <InputError>{errors.gender}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
              <Label>Titel</Label>
              <Input type="text" {...fields.title} disabled={disabled} />
              {errors.title && <InputError>{errors.title}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
              <Label>Vorname</Label>
              <Input type="text" {...fields.name} disabled={disabled} />
              {errors.name && <InputError>{errors.name}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
              <Label>Nachname</Label>
              <Input type="text" {...fields.surname} disabled={disabled} />
              {errors.surname && <InputError>{errors.surname}</InputError>}
            </InputGrid.Item>
            <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
              <Label>Email</Label>
              <Input
                type="text"
                value={userInfo ? userInfo.attributes.email : ''}
                disabled
              />
            </InputGrid.Item>
            <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
              <Label>&ouml;&auml;K Nr.</Label>
              <Input
                type="text"
                {...fields.oid}
                disabled={disabled}
                style={{ margin: spaces.stack.xs }}
              />
              <OidLink />
              {errors.oid && <InputError>{errors.oid}</InputError>}
            </InputGrid.Item>
          </InputGrid>
        </form>
      </FormGrid>
      <Seperator />
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading>Praxis</FormDescription.Heading>
          <FormDescription.Content>
            Geben Sie hier bitte Ihre Praxis Informationen an.
          </FormDescription.Content>
        </FormDescription>
        <InputGrid>
          <div>
            <Label>Praxis</Label>
            <Input type="text" {...surgeryFields.name} disabled={disabled} />
            {errors.surgery && errors.surgery.name && (
              <InputError>{errors.surgery.name}</InputError>
            )}
          </div>
          <div>
            <Label>Strasse</Label>
            <Input type="text" {...surgeryFields.street} disabled={disabled} />
            {errors.surgery && errors.surgery.street && (
              <InputError>{errors.surgery.street}</InputError>
            )}
          </div>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>PLZ</Label>
            <Input
              type="number"
              onChange={evt =>
                setSurgeryValue('zip', Number(evt.currentTarget.value))
              }
              name={surgeryFields.zip.name}
              value={surgeryFields.zip.value || ''}
              disabled={disabled}
            />
            {errors.surgery && errors.surgery.zip && (
              <InputError>{errors.surgery.zip}</InputError>
            )}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Ort</Label>
            <Input type="text" {...surgeryFields.city} disabled={disabled} />
            {errors.surgery && errors.surgery.city && (
              <InputError>{errors.surgery.city}</InputError>
            )}
          </InputGrid.Item>
          <div>
            <Label>Telefon</Label>
            <Input type="text" {...surgeryFields.phone} disabled={disabled} />
            {errors.surgery && errors.surgery.phone && (
              <InputError>{errors.surgery.phone}</InputError>
            )}
          </div>
          <div>
            <Label>Fax</Label>
            <Input type="text" {...surgeryFields.fax} disabled={disabled} />
            {errors.surgery && errors.surgery.fax && (
              <InputError>{errors.surgery.fax}</InputError>
            )}
          </div>
        </InputGrid>
      </FormGrid>
      <Seperator />
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading>Krankenhaus</FormDescription.Heading>
          <FormDescription.Content>
            Geben Sie hier bitte Ihre Krankenhaus Informationen an.
          </FormDescription.Content>
        </FormDescription>
        <InputGrid>
          <div>
            <Label>Krankenhaus</Label>
            <Input type="text" {...hospitalFields.name} disabled={disabled} />
            {errors.hospital && errors.hospital.name && (
              <InputError>{errors.hospital.name}</InputError>
            )}
          </div>
          <div>
            <Label>Abteilung</Label>
            <Input
              type="text"
              {...hospitalFields.department}
              disabled={disabled}
            />
            {errors.hospital && errors.hospital.department && (
              <InputError>{errors.hospital.department}</InputError>
            )}
          </div>
          <div>
            <Label>Strasse</Label>
            <Input type="text" {...hospitalFields.street} disabled={disabled} />
            {errors.hospital && errors.hospital.street && (
              <InputError>{errors.hospital.street}</InputError>
            )}
          </div>
          <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
            <Label>PLZ</Label>
            <Input
              type="number"
              onChange={evt =>
                setHospitalValue('zip', Number(evt.currentTarget.value))
              }
              name={hospitalFields.zip.name}
              value={hospitalFields.zip.value || ''}
              disabled={disabled}
            />
            {errors.hospital && errors.hospital.zip && (
              <InputError>{errors.hospital.zip}</InputError>
            )}
          </InputGrid.Item>
          <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
            <Label>Ort</Label>
            <Input type="text" {...hospitalFields.city} disabled={disabled} />
            {errors.hospital && errors.hospital.city && (
              <InputError>{errors.hospital.city}</InputError>
            )}
          </InputGrid.Item>
          <div>
            <Label>Telefon</Label>
            <Input type="text" {...hospitalFields.phone} disabled={disabled} />
            {errors.hospital && errors.hospital.phone && (
              <InputError>{errors.hospital.phone}</InputError>
            )}
          </div>
          <div style={{ margin: spaces.stack.l }}>
            <Label>Fax</Label>
            <Input type="text" {...hospitalFields.fax} disabled={disabled} />
            {errors.hospital && errors.hospital.fax && (
              <InputError>{errors.hospital.fax}</InputError>
            )}
          </div>
          <Button
            type="submit"
            variant="primary"
            style={{ justifySelf: 'start' }}
            onClick={handleProfileSave}
            disabled={disabled}
          >
            <span>Profil speichern</span>
          </Button>
        </InputGrid>
      </FormGrid>
    </>
  )
}

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .max(40, tooLongError('Titel', 40))
    .required(requiredError('Titel')),
  gender: yup.string().required(requiredError('Anrede')),
  name: yup
    .string()
    .max(50, tooLongError('Vorname', 50))
    .required(requiredError('Vorname')),
  surname: yup
    .string()
    .max(50, tooLongError('Nachname', 50))
    .required(requiredError('Nachname')),
  oid: yup
    .string()
    .max(50, tooLongError('ÖÄK-Arztnummer', 50))
    .required(requiredError('ÖÄK-Arztnummer')),

  hospital: yup.object().shape({
    name: yup.string().max(100, tooLongError('Krankenhaus Name', 100)),
    department: yup
      .string()
      .max(100, tooLongError('Krankenhaus Abteilung', 100)),
    street: yup.string().max(50, tooLongError('Krankenhaus Straße', 50)),
    zip: yup
      .number()
      .test(
        'zip-validation',
        'Bitte geben Sie eine gültige PLZ an.',
        zip => zip === 0 || (1009 < zip && zip < 10000)
      ),
    city: yup.string().max(50, tooLongError('Krankenhaus Ort', 50)),
    phone: yup.string().max(50, tooLongError('Krankenhaus Telefon', 50)),
    fax: yup.string().max(50, tooLongError('Krankenhaus Fax', 50)),
  }),

  surgery: yup.object().shape({
    name: yup.string().max(100, tooLongError('Praxis Name', 100)),
    street: yup.string().max(50, tooLongError('Praxis Strasse', 50)),
    zip: yup
      .number()
      .test(
        'zip-validation',
        'Bitte geben Sie eine gültige PLZ an.',
        zip => zip === 0 || (1009 < zip && zip < 10000)
      ),
    city: yup.string().max(50, tooLongError('Praxis Ort', 50)),
    phone: yup.string().max(50, tooLongError('Praxis Telefon', 50)),
    fax: yup.string().max(50, tooLongError('Praxis Fax', 50)),
  }),
})

export default ProfileDoctorForm
