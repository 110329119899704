import React, { useEffect, useRef, useContext, useMemo, FormEvent } from 'react'

import Label from 'components/Label'
import Button from 'components/Button'
import InputError from 'components/InputError'

import { useTheme } from 'styled'

import useFormFields from 'hooks/useFormFields'
import useForgotPassword, { ForgotPasswordError } from 'auth/useForgotPassword'

import { LogInInput, LogInDescriptionText } from './styled-login'
import { LoginFlowContext } from './index'

function ForgotPasswordForm({ onCancel }: { onCancel: () => void }) {
  const { setState: setLoginState, setEmail } = useContext(LoginFlowContext)
  const { forgotPassword, loading, error, success } = useForgotPassword()
  const {
    fields: fieldBindings,
    values: { email },
  } = useFormFields({ email: '' })

  const errorMessage = useMemo(() => (error ? getErrorMessage(error) : null), [
    error,
  ])

  useEffect(() => {
    if (success) {
      setLoginState('FORGOT_PASSWORD_SUBMIT')
    }
  }, [setLoginState, success])

  const usernameInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (usernameInputRef.current) {
      usernameInputRef.current.focus()
    }
  }, [])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    setEmail(email.toLowerCase())
    forgotPassword(email.toLowerCase())
  }

  const { spaces } = useTheme()

  return (
    <>
      {errorMessage && <InputError>{errorMessage}</InputError>}
      <form onSubmit={handleSubmit}>
        <LogInDescriptionText style={{ lineHeight: '1.4' }}>
          <p style={{ marginTop: '0' }}>
            Bitte geben Sie hier Ihre E-Mail Adresse ein, mit der Sie bei
            chefarztantrag.at registriert sind.
          </p>
          <p>
            Wir werden Ihnen eine E-Mail mit einem Verifizerungscode zum
            Zur&uuml;cksetzten Ihrese Passwortes senden.
          </p>
        </LogInDescriptionText>
        <div>
          <Label>E-Mail</Label>
          <LogInInput
            type="text"
            ref={usernameInputRef}
            {...fieldBindings.email}
          />
        </div>
        <div style={{ margin: spaces.stack.m }}>
          <Button
            type="submit"
            variant="primary"
            style={{ width: '100%' }}
            disabled={loading}
          >
            Verifizierungscode anfordern
          </Button>
        </div>
        <div>
          <Button
            variant="tertiary"
            style={{ fontSize: 12, fontWeight: 500, marginLeft: spaces.s }}
            as="a"
            onClick={onCancel}
          >
            Zur&uuml;ck
          </Button>
        </div>
      </form>
    </>
  )
}

function getErrorMessage(error: ForgotPasswordError) {
  switch (error.name) {
    case 'InvalidParameterException':
      return 'Der angegebene User ist nicht verifiziert.'
    case 'NotAuthorizedException':
      return 'Ihr Passwort kann nicht zurückgesetzt werden. Bitte kontrollieren Sie ihre E-Mails.'
    case 'UserNotFoundException':
      return 'Die angegebene E-Mail Adresse ist ungültig.'
  }
}

export default ForgotPasswordForm
