import {
  ClaimDefinition,
  StepDefinitionTypes,
  PharmaCompany,
  DosingFrequencyUnit,
  ClaimClearance,
} from 'claimbuilder'

import infoDoc from './assets/F_FI_19-07-18_Natpar.pdf'
import logo from './assets/natpar.png'

export const NatparClaimDefinition: ClaimDefinition = {
  product: {
    name: 'Natpar',
    informationDocument: infoDoc,
    logo: logo,
    substances: ['Parathyroidhormon (rDNA)'],
  },
  clearance: ClaimClearance.DEVELOPMENT, // cancelled by Sonja Schauerhuber, 2021-03-29
  owner: PharmaCompany.TAKEDA,
  steps: [
    {
      type: StepDefinitionTypes.DOCTOR,
    },
    {
      type: StepDefinitionTypes.PATIENT,
      diagnoseOptions: [
        {
          label: 'chronischer Hypoparathyreoidismus',
          value:
            'Natpar ist als Zusatztherapie bei erwachsenen Patienten mit chronischem Hypoparathyreoidismus angezeigt, deren Erkrankung sich durch die Standardtherapie alleine nicht hinreichend kontrollieren lässt.',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DOSING,
      unit: 'µg',
      frequencyUnit: DosingFrequencyUnit.DAY,
      frequencies: [
        {
          label: 'täglich',
          value: 1,
        },
      ],
      dosings: [
        {
          value: 25,
        },
        {
          value: 50,
        },
        {
          value: 75,
        },
        {
          value: 100,
        },
      ],
    },
    {
      type: StepDefinitionTypes.ARGUMENTS,
      arguments: [
        {
          label: 'Unzureichende Standardtherapie',
          description:
            'Tägliche orale Kalzium/Vitamin-D Supplementierung von >2,5g Kalzium, > 1,5µg aktives Vitamin D, Malabsorptionssyndrom',
        },
        {
          label: 'Serumkalzium-Spiegel außerhalb Normbereich',
          description: 'Serumkalzium-Spiegel <2,0mmol/L (Albumin-korrigiert)',
        },
        {
          label: 'Parathormon außerhalb Normbereich',
          description: 'Parathormon-Mangel (<16-20pg/ml)',
        },
        {
          label: 'Hypokalzämie mit Muskelkrämpfen',
          description: '',
        },
        {
          label: 'Hypokalzämie mit Parästhesien',
          description: '',
        },
        {
          label: 'Hypokalzämie mit Tetanischen Anfällen',
          description: '',
        },
        {
          label: 'Depression und Angstzustände',
        },
        {
          label: 'Kognitive Beschwerden',
          description: 'z.B.: Konzentrationsschwäche, Vergesslichkeit, Brain Fog',
        },
        {
          label: 'Reduzierte körperliche Verfassung',
          description: '',
        },
        {
          label: 'Einschränkungen bei sozialer Interaktion',
          description: '',
        },
        {
          label: 'Nephrokalzinose und Niereninsuffizienz als Begleiterkrankung',
          description: '',
        },
        {
          label:
            'Ektope Kalzifizierungen (Gefäße, Katarakt) als Begleiterkrankung',
          description: '',
        },
        {
          label:
            'Notfälle aufgrund von Hypo-/Hyperkalzämien als Begleiterkrankung',
          description: '',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DISPATCH,
    },
    {
      type: StepDefinitionTypes.SUMMARY,
    },
  ],
}

export default NatparClaimDefinition
