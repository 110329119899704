import React, { useContext, useMemo, useRef, FormEvent, useEffect } from 'react'
import * as yup from 'yup'
import { useTheme } from 'styled'

import InputGrid from 'components/InputGrid'
import Label from 'components/Label'
import Input from 'components/Input'
import InputError from 'components/InputError'
import Button from 'components/Button'

import { requiredError, tooShortError } from 'messages/errors'
import useForgotPasswordSubmit, {
  ForgotPasswordSubmitError,
} from 'auth/useForgotPasswordSubmit'
import useFormFields from 'hooks/useFormFields'
import useYup from 'hooks/useYup'

import { LogInDescriptionText } from './styled-login'
import { LoginFlowContext } from './index'

function ForgotPasswordSubmitForm() {
  const { setState: setLoginState, email } = useContext(LoginFlowContext)
  const {
    forgotPasswordSubmit,
    error,
    loading,
    success,
  } = useForgotPasswordSubmit()
  const { fields, values } = useFormFields({
    code: '',
    password: '',
    passwordRepeat: '',
  })
  const { errors, validate } = useYup(values, validationSchema)
  const { spaces } = useTheme()

  const errorMessage = useMemo(() => (error ? getErrorMessage(error) : null), [
    error,
  ])

  useEffect(() => {
    if (success) {
      setTimeout(() => setLoginState('LOGIN'), 3000)
    }
  }, [setLoginState, success])

  const codeInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (codeInputRef.current) {
      codeInputRef.current.focus()
    }
  }, [])

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const { isValid } = await validate()
    if (isValid && email) {
      await forgotPasswordSubmit(
        email.toLowerCase(),
        values.code,
        values.password
      )
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <InputGrid>
        {!success && (
          <LogInDescriptionText>
            <p style={{ marginTop: '0' }}>
              Verwenden Sie den Verifizerungscode, den Sie per E-Mail von unser
              erhalten haben.
            </p>
            <p>
              In seltenen F&auml;llen kann dies einige Minuten dauern. Bitte
              kontrollieren Sie auch Ihren Spam Ordner.
            </p>
            Das neue Passwort muss folgende Bedingungen erfüllen:
            <ul>
              <li>8 Zeichen oder mehr</li>
              <li>Groß- und Klein-Buchstaben</li>
              <li>Mindestens eine Zahl</li>
            </ul>
            Verwenden Sie das selbe Passwort niemals für mehrere Accounts!
          </LogInDescriptionText>
        )}
        {errorMessage && <InputError>{errorMessage}</InputError>}
        {!success ? (
          <>
            <div>
              <Label>Code</Label>
              <Input
                type="text"
                ref={codeInputRef}
                {...fields.code}
                disabled={success || loading}
              />
              {errors.code && <InputError>{errors.code}</InputError>}
            </div>
            <div>
              <Label>Neues Passwort</Label>
              <Input
                type="password"
                {...fields.password}
                disabled={success || loading}
              />
              {errors.password && <InputError>{errors.password}</InputError>}
            </div>
            <div>
              <Label>Passwort wiederholen</Label>
              <Input
                type="password"
                {...fields.passwordRepeat}
                style={{ margin: spaces.stack.xl }}
                disabled={success || loading}
              />
              {errors.passwordRepeat && (
                <InputError>{errors.passwordRepeat}</InputError>
              )}
            </div>
            <Button
              style={{ width: '100%', margin: spaces.stack.s }}
              type="submit"
              variant="primary"
              disabled={success || loading}
            >
              Passwort ändern
            </Button>
          </>
        ) : (
          <div>
            Sie haben Ihr Passwort erfolgreich geändert. Sie werden in 3
            Sekunden weitergeleitet.
          </div>
        )}
      </InputGrid>
    </form>
  )
}

const validationSchema = yup.object().shape({
  code: yup.string().required(requiredError('Code')),
  password: yup
    .string()
    .required(requiredError('Neues Passwort'))
    .min(8, tooShortError('Neues Passwort', 8))
    .matches(/[a-z]/, 'Es muss mindestens ein Kleinbuchstabe enthalten sein.')
    .matches(/[A-Z]/, 'Es muss mindestens ein Großbuchstabe enthalten sein.')
    .matches(/\d/, 'Es muss mindestens eine Zahl enthalten sein.'),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwort stimmt nicht überein'),
})

function getErrorMessage(error: ForgotPasswordSubmitError) {
  switch (error.name) {
    case 'CodeMismatchException':
      return 'Falscher verifizierungs Code.'
    case 'ExpiredCodeException':
      return 'Dieser Code ist bereits abgelaufen.'
    case 'InvalidParameterException':
      return 'Es ist ein Fehler aufgetreten.'
    default:
      return `${error.name}: ${error.message}`
  }
}

export default ForgotPasswordSubmitForm
