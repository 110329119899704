import React, { useState, useRef, useEffect } from 'react'
import { useTheme } from 'styled'
import ReactCrop from 'react-image-crop'
import { Storage } from 'aws-amplify'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
// import Input from 'components/Input'
// import Label from 'components/Label'
import FaIcon from 'components/FaIcon'
import InputGrid from 'components/InputGrid'
// import InputError from 'components/InputError'
import Button from 'components/Button'
import { useAlert } from 'components/Alert'

import * as API from 'api'
import { useFileSelect, useApi, useSignature as useLoadSignature } from 'hooks'
// import { useUserInfo } from 'auth'

import 'react-image-crop/dist/ReactCrop.css'

import useSignature from './useSignature'

const stage = process.env.REACT_APP_STAGE

Storage.configure({
  AWSS3: {
    bucket: `nobox-user-bucket-${stage}`,
    region: 'eu-central-1',
  },
})

function UploadSignatureForm() {
  const { colors } = useTheme()
  const [alert] = useAlert()
  const [signatureStatusResponse, getSignatureStatus] = useApi(
    API.getSignatureStatus
  )
  useEffect(() => {
    getSignatureStatus()
  }, [getSignatureStatus])

  const {
    data: statusData,
    loading: statusLoading,
    error: statusError,
  } = signatureStatusResponse

  useEffect(() => {
    if (statusError) {
      alert.error('Fehler beim laden des Signatur-Status.')
    }
  }, [alert, statusError])

  const handleSignatureStateChanged = () => {
    getSignatureStatus()
  }

  const signatureAvailable = statusData && statusData.status === 'AVAILABLE'

  return (
    <form onSubmit={evt => evt.preventDefault()}>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading>Signatur hochladen</FormDescription.Heading>
          <FormDescription.Content>
            <p>
              {
                'Hier können Sie einen Scan Ihrer Unterschrift hochladen, um Anträge zu signieren und per Mail an die Krankenkassen zu übermitteln.'
              }
            </p>
            <p>
              {'Folgende Bild-Formate werden unterstützt:'}{' '}
              <b>{'PNG, JPG, JPEG'}</b>
              {'.'}
            </p>
            <p>
              <b>{'WICHTIG:'}</b>{' '}
              {
                'Bitte verwenden Sie ausschließlich Scans oder Photos (bei guter Beleuchtung) Ihrer Unterschrift mit schwarzer oder dunkelblauer Farbe auf weißem Hintergrund, da es sonst zu Darstellungsproblemen kommen kann.'
              }
            </p>
          </FormDescription.Content>
        </FormDescription>
        <InputGrid>
          <FormDescription.Content style={{ margin: 0 }}>
            {statusLoading && <strong>Signaturdaten werden geldaen</strong>}
            {!statusLoading && !signatureAvailable && (
              <strong style={{ color: colors.warning }}>
                {
                  'Sie haben noch keine Signatur hochgeladen, daher können Anträge noch nicht elektronisch übermittelt werden.'
                }
              </strong>
            )}
            {!statusLoading && signatureAvailable && (
              <SignaturePreview onStateChange={handleSignatureStateChanged} />
            )}
            {!statusLoading && (
              <UploadSignature onStateChange={handleSignatureStateChanged} />
            )}
          </FormDescription.Content>
        </InputGrid>
      </FormGrid>
    </form>
  )
}

function SignaturePreview(props: { onStateChange: () => void }) {
  const { colors, spaces } = useTheme()
  const [showSignature, setShowSignature] = useState(false)
  const [loadSignature, signatureData] = useLoadSignature()

  useEffect(() => {
    if (showSignature) {
      loadSignature()
    }
  }, [loadSignature, showSignature])

  const toggleShowSignature = () => {
    setShowSignature(prev => !prev)
  }

  const handleDeleteSignature = async () => {
    const confirmed = window.confirm(
      'Wollen Sie die Signatur wirklich löschen?'
    )
    if (confirmed) {
      await Storage.vault.remove('signature.png')
      props.onStateChange()
    }
  }

  return (
    <>
      <p style={{ marginTop: 0 }}>
        <strong style={{ color: colors.success }}>
          Sie haben bereits eine Signature hochgeladen.
        </strong>
      </p>

      {signatureData.loading && <div>Signatur wird geladen.</div>}
      {showSignature && signatureData.src && (
        <img alt="Signatur" src={signatureData.src} />
      )}

      <p style={{ display: 'flex' }}>
        <Button
          onClick={handleDeleteSignature}
          style={{ margin: spaces.inline.l }}
          variant="secondary"
          type="button"
        >
          {'Signatur löschen'}
        </Button>
        <Button onClick={toggleShowSignature} variant="tertiary">
          <FaIcon name="eye" />
          <span>Signatur {showSignature ? 'verstecken' : 'anzeigen'}</span>
        </Button>
      </p>
    </>
  )
}

function UploadSignature(props: { onStateChange: () => void }) {
  const [selectFile] = useFileSelect({ accept: 'image/png, image/jpeg' })
  const [uploadingSignature, setUploadingSignature] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [filteredFileBuffer, filteringImage] = useSignature(selectedFile, {
    width: 600,
  })
  const [crop, setCrop] = useState<ReactCrop.Crop>({
    x: 10,
    y: 10,
    width: 580,
    height: 300,
  })
  const imageRef = useRef<HTMLImageElement>(null) as React.MutableRefObject<
    HTMLImageElement
  >

  const handleSelectFile = async () => {
    const files = await selectFile()
    if (files && files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  const handleSignatureUpload = async () => {
    if (!imageRef.current) {
      throw new Error('no image loaded yet')
    }
    if (!crop) {
      throw new Error('no crop dimensions set')
    }
    setUploadingSignature(true)
    const cropedImage = await getCroppedImg(
      imageRef.current,
      crop,
      'signature.png'
    )
    await Storage.vault.put('signature.png', cropedImage, {
      contentType: 'image/png',
    })
    setUploadingSignature(false)
    props.onStateChange()
  }

  return (
    <>
      <FormDescription.Content>
        <div>
          {filteringImage && (
            <span>Signatur wird aufbereitet... Dies kann etwas dauern.</span>
          )}
          {!filteringImage && filteredFileBuffer && (
            <ReactCrop
              src={filteredFileBuffer}
              style={{
                backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
          linear-gradient(to right, black 50%, white 50%),
          linear-gradient(to bottom, black 50%, white 50%)`,
                backgroundBlendMode: 'normal, difference, normal',
                backgroundSize: '2em 2em',
              }}
              imageStyle={{ maxHeight: 'unset' }}
              keepSelection={true}
              crop={crop}
              onChange={crop => setCrop(crop)}
              onImageLoaded={img => (imageRef.current = img)}
            />
          )}
        </div>
      </FormDescription.Content>
      {selectedFile ? (
        <>
          {uploadingSignature && <p>{'Die Signatur wird hochgeladen...'}</p>}
          <Button
            style={{ justifySelf: 'start' }}
            variant="primary"
            onClick={handleSignatureUpload}
            disabled={
              filteringImage || !filteredFileBuffer || uploadingSignature
            }
          >
            <span>{'Signatur hochladen'}</span>
          </Button>
        </>
      ) : (
        <>
          <p>
            {
              'Klicken Sie auf "Datei auswählen" um eine neue Signatur hochzuladen.'
            }
          </p>
          <Button
            style={{ justifySelf: 'start' }}
            variant="primary"
            onClick={handleSelectFile}
            disabled={filteringImage}
          >
            <span>{'Datei auswählen'}</span>
          </Button>
        </>
      )}
    </>
  )
}

export default UploadSignatureForm

function getCroppedImg(
  image: HTMLImageElement,
  crop: ReactCrop.Crop,
  fileName: string
) {
  if (!crop.width || !crop.height || !crop.y || !crop.x) {
    throw new Error('no crop dimensions set')
  }
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('no canvas 2d context')
  }
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        throw new Error('could not convert canvas to blob')
      }
      ;(blob as any).name = fileName
      resolve(blob as Blob)
    }, 'image/png')
  })
}
