import useUserInfo from './useUserInfo'

import { UserGroup } from 'types'

function useUserGroups() {
  const userInfo = useUserInfo()
  const groups = userInfo ? userInfo.groups || [] : []
  return {
    groups,
    isAdmin: groups.indexOf(UserGroup.ADMIN) >= 0,
    isPharma: groups.indexOf(UserGroup.PHARMA) >= 0,
    isDoctor: groups.indexOf(UserGroup.DOCTOR) >= 0,
    isDemo: groups.indexOf(UserGroup.DEMO) >= 0,
  }
}

export { UserGroup }

export default useUserGroups
