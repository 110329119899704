import React, { CSSProperties } from 'react'
import styled from 'styled'

import Container from './Container'

type ContentAreaProps = {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

const ContentContainer = styled(Container)`
  padding-top: ${props => props.theme.spaces.xl};
  padding-bottom: ${props => props.theme.spaces.xxl};
`

function ContentArea(props: ContentAreaProps) {
  return (
    <ContentContainer className={`${props.className || ''}`} style={props.style}>
      {props.children}
    </ContentContainer>
  )
}

export default ContentArea
