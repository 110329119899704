import React from 'react'
import { RouteComponentProps } from '@reach/router'

import PageHero from 'components/PageHero'
import Seperator from 'components/Seperator'

import DefaultPageLayout from 'layout/DefaultPageLayout'

import useUserGroups from 'auth/useUserGroups'

import ProfileDoctorForm from './ProfileDoctorForm'
import ProfilePharmaForm from './ProfilePharmaForm'
import ProfileAdminForm from './ProfileAdminForm'
import NewPasswordForm from './NewPasswordForm'
import DeleteAccountForm from './DeleteAccountForm'
import ValidateEmailForm from './ValidateEmailForm'
import SignatureUploadForm from './UploadSignatureForm'

const ProfilePage = (props: RouteComponentProps) => {
  const { isAdmin, isPharma, isDoctor } = useUserGroups()

  return (
    <DefaultPageLayout>
      {isAdmin && <ProfileAdminForm />}
      {isPharma && <ProfilePharmaForm />}
      {isDoctor && <ProfileDoctorForm />}

      <PageHero>
        <PageHero.Title>Account</PageHero.Title>
      </PageHero>
      {isDoctor && <ValidateEmailForm />}
      {isDoctor && <SignatureUploadForm />}
      <NewPasswordForm />
      <Seperator />
      <DeleteAccountForm />
    </DefaultPageLayout>
  )
}

export default ProfilePage
