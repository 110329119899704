import React, { useMemo, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled'
import { transparentize, darken, lighten } from 'polished'

import InputError from 'components/InputError'
import FaIcon from 'components/FaIcon'
import Title from 'components/Title'
import { getChangePasswordApiError, getLoginApiError } from 'messages/password'

import Footer from 'layout/DefaultPageLayout/Footer'
import { useAuthentication } from 'auth'
import { useTheme } from 'styled'
import useMedia from 'hooks/useMedia'

import { LogInSubText } from './styled-login'
import { LogoText } from 'layout/DefaultPageLayout/TopMenuBar/styled-menu'
import UsernamePasswordForm from './UsernamePasswordForm'
import NewPasswordRequiredForm from './NewPasswordRequiredForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import ForgotPasswordSubmitForm from './ForgotPasswordSubmitForm'
import AcceptTerms from './AcceptTerms'

type LoginState =
  | 'LOGIN'
  | 'NEW_PASSWORD_REQUIRED'
  | 'FORCE_ACCEPT_TERMS'
  | 'FORGOT_PASSWORD'
  | 'FORGOT_PASSWORD_SUBMIT'
const LoginFlowContext = React.createContext({
  state: 'LOGIN' as LoginState,
  setState: (state: LoginState) => {},
  email: null as string | null,
  setEmail: (email: string) => {},
})

const LoginPage = (props: RouteComponentProps) => {
  const {
    signIn,
    challange,
    completeNewPassword,
    acceptTerms,
    error,
    loading,
  } = useAuthentication()
  const [loginState, setLoginState] = useState<LoginState>('LOGIN')
  const [email, setEmail] = useState<string | null>(null)

  const { isPhone, isTablet } = useMedia()

  useEffect(() => {
    if (loginState === 'LOGIN' && challange === 'NEW_PASSWORD_REQUIRED') {
      setLoginState('NEW_PASSWORD_REQUIRED')
    }
    if (
      (loginState === 'LOGIN' || loginState === 'NEW_PASSWORD_REQUIRED') &&
      challange === 'CUSTOM_CHALLENGE'
    ) {
      setLoginState('FORCE_ACCEPT_TERMS')
    }
  }, [challange, loginState])

  const content = useMemo(() => {
    switch (loginState) {
      case 'LOGIN':
        return <UsernamePasswordForm login={signIn} loading={loading} />
      case 'NEW_PASSWORD_REQUIRED':
        return (
          <NewPasswordRequiredForm
            confirm={completeNewPassword}
            loading={loading}
          />
        )
      case 'FORCE_ACCEPT_TERMS':
        return (
          <AcceptTerms confirm={() => acceptTerms(true)} loading={loading} />
        )
      case 'FORGOT_PASSWORD':
        return <ForgotPasswordForm onCancel={() => setLoginState('LOGIN')} />
      case 'FORGOT_PASSWORD_SUBMIT':
        return <ForgotPasswordSubmitForm />
    }
  }, [loginState, signIn, loading, completeNewPassword, acceptTerms])

  const errorMessage = useMemo(() => {
    if (loginState === 'NEW_PASSWORD_REQUIRED') {
      return getChangePasswordApiError(error as any)
    } else if (loginState === 'LOGIN') {
      return getLoginApiError(error as any)
    }
  }, [error, loginState])

  return (
    <LoginFlowContext.Provider
      value={{ state: loginState, setState: setLoginState, email, setEmail }}
    >
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          background: '#f8fafc',
        }}
      >
        <div style={{ flex: 'auto' }}>
          <LogInContainer>
            {!isPhone && !isTablet && (
              <BackbroundImage>
                <Arguments />
              </BackbroundImage>
            )}

            <FormContainer>
              <LogInLogo>chefarztantrag.at</LogInLogo>

              <div>{content}</div>
              {errorMessage &&
                (loginState === 'LOGIN' ||
                  loginState === 'NEW_PASSWORD_REQUIRED') && (
                  <InputError>{errorMessage}</InputError>
                )}

              <DataProtect />
            </FormContainer>
          </LogInContainer>
        </div>
        <Footer />
      </div>
    </LoginFlowContext.Provider>
  )
}

const DataProtect = () => {
  const { spaces } = useTheme()
  return (
    <div>
      <LogInSubText style={{ margin: spaces.stack.xxs }}>
        beraten von
      </LogInSubText>
      <div
        style={{
          display: 'block',
          margin: 'auto',
          width: '70px',
        }}
      >
        <a
          href="https://www.dataprotect.at/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ width: '100%', margin: spaces.stack.xxs }}
            src="/images/logos/dataprotect.png"
            alt="DataProtect Logo"
          />
        </a>
      </div>

      <LogInSubText>
        ein Spezialist im Bereich Datenschutz &amp; DSGVO
      </LogInSubText>
    </div>
  )
}

const Arguments = () => {
  return (
    <div style={{ display: 'grid', alignItems: 'center', height: '100%' }}>
      <ArgumentContainer>
        <FaIcon name={'check-square'} size={'2x'} />
        <div>
          <ArgumentTitle as={'h2'}>Einfach</ArgumentTitle>
          <SubArgumentsWrapper>
            <SubArgument>{'Produkt-spezifische Informationen'}</SubArgument>
            <SubArgument>{'Vollständige Empfängerliste'}</SubArgument>
            <SubArgument>{'Dosierungs-Matrix'}</SubArgument>
          </SubArgumentsWrapper>
        </div>
        <FaIcon name={'rabbit-fast'} size={'2x'} />
        <div>
          <ArgumentTitle as={'h2'}>Schnell</ArgumentTitle>
          <SubArgumentsWrapper>
            <SubArgument>{'Automatische PDF Generierung'}</SubArgument>
            <SubArgument>{'Antrag in wenigen Schritten'}</SubArgument>
            <SubArgument>{'Digitale Übermittlung'}</SubArgument>
          </SubArgumentsWrapper>
        </div>
        <FaIcon name={'shield-alt'} size={'2x'} />
        <div>
          <ArgumentTitle as={'h2'}>Sicher</ArgumentTitle>
          <SubArgumentsWrapper>
            <SubArgument>{'Kein Speichern sensibler Daten'}</SubArgument>
            <SubArgument>{'Verschlüsselte Übertragung'}</SubArgument>
            <SubArgument>{'DSGVO konform'}</SubArgument>
          </SubArgumentsWrapper>
        </div>
      </ArgumentContainer>
    </div>
  )
}

const LogInContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  border: 1px solid ${props => props.theme.colors['grey-90']};
  border-radius: ${props => props.theme.radius.s};
  max-width: 800px;
  min-height: 550px;
  margin: 20px auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.05);
  margin-top: 10vh;

  ${props => props.theme.media.tablet`grid-template-columns: 100%;`}
  ${props => props.theme.media.tablet`max-width: 400px;`}
  ${props => props.theme.media.phone`margin: 0;`}
  ${props => props.theme.media.phone`border: none;`}
  ${props => props.theme.media.phone`box-shadow: none;`}
  ${props => props.theme.media.phone`width: 100%;`}
  ${props => props.theme.media.phone`max-width: 100%;`}
  ${props => props.theme.media.phone`background: #f8fafc;`}
`
const BackbroundImage = styled.div`
  background-image: linear-gradient(
      to right top,
      ${props => transparentize(0.3, darken(0.2, props.theme.colors.primary))},
      ${props => transparentize(0.3, darken(0.1, props.theme.colors.primary))}
        70%,
      ${props => transparentize(0.3, lighten(0.05, props.theme.colors.primary))}
    ),
    url('/images/media/stethoscope.png');
  background-size: cover;
  border-radius: ${props => props.theme.radius.s} 0 0
    ${props => props.theme.radius.s};
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${props => props.theme.spaces.insetSquish.xxxl};
`

const LogInLogo = styled(LogoText)`
  ${props => props.theme.media.phone`margin-top: 50px;`};
  margin-top: ${props => props.theme.spaces.m};
  text-align: center;
  margin-bottom: ${props => props.theme.spaces.xs};
`
const ArgumentContainer = styled.div`
  padding: ${props => props.theme.spaces.inset.xxl};
  color: white;
  display: grid;
  grid-template-columns: 30px auto;
  grid-row-gap: ${props => props.theme.spaces.l};
  grid-column-gap: ${props => props.theme.spaces.l};
`

const ArgumentTitle = styled(Title)`
  margin: 0;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 4px;
`

const SubArgumentsWrapper = styled.ul`
  padding-left: ${props => props.theme.spaces.m};
`

const SubArgument = styled.li`
  margin: ${props => props.theme.spaces.stack.xs};
`

export default LoginPage

export { LoginFlowContext }
