import styled from 'styled'

export const H1 = styled.h1`
  font-size: 24px;
  line-height: 36px;
  font-family: "Inter UI",sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const H2 = styled.h2`
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter UI",sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
`

export const H3 = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter UI",sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
`

  