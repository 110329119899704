import React from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import { PrivateRoute } from 'auth'

import { UserGroup } from 'types'

import DefaultLayout from 'layout/DefaultPageLayout'

import Home from './Home'
import Claims from './Claims'
import Profile from './Profile'
import Privacy from './Privacy'
import Terms from './Terms'
import Disclosure from './Disclosure'
import Administration from './Administration'
import SickfundEdit from './Administration/sickfund'
import VerifySuccess from './VerifySuccess'
import NotFound from './NotFound'

import {
  getHomeRoute,
  getClaimRoute,
  getProfileRoute,
  getAdministrationRoute,
  getDataPrivacyRoute,
  getTermsRoute,
  getDisclosureRoute,
  getVerifySuccessRoute,
  getSickfundEditRoute
} from './routes'

const RootNotFound = (props: RouteComponentProps) => (
  <DefaultLayout>
    <NotFound />
  </DefaultLayout>
)

const Pages = () => (
  <Router>
    <PrivateRoute as={Home} path={getHomeRoute()} />
    <PrivateRoute as={Claims} path={getClaimRoute('*')} />
    <PrivateRoute as={Profile} path={getProfileRoute()} />
    <PrivateRoute as={SickfundEdit} withGroup={[UserGroup.ADMIN]} path={`${getSickfundEditRoute(':sickfundId')}/*`} />
    <PrivateRoute
      as={Administration}
      withGroup={[UserGroup.ADMIN, UserGroup.PHARMA]}
      path={getAdministrationRoute()}
    />
    <Privacy path={getDataPrivacyRoute()} />
    <Terms path={getTermsRoute()} />
    <Disclosure path={getDisclosureRoute()} />
    <VerifySuccess path={getVerifySuccessRoute()} />
    <RootNotFound default />
  </Router>
)

export default Pages
