import React, { CSSProperties, ChangeEventHandler } from 'react'
import StyledTextArea from './TextArea'

type TextAreaProps = {
  name: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  placeholder?: string
  style?: CSSProperties
}

function TextArea(props: TextAreaProps) {
  return <StyledTextArea {...props} />
}

export default TextArea
