import React, { useRef, useEffect } from 'react'
import * as yup from 'yup'
import { useTheme } from 'styled'

import InputGrid from 'components/InputGrid'
import Label from 'components/Label'
import Input from 'components/Input'
import InputError from 'components/InputError'
import Button from 'components/Button'

import { requiredError, tooShortError } from 'messages/errors'
import useFormFields from 'hooks/useFormFields'
import useYup from 'hooks/useYup'

import { LogInDescriptionText } from './styled-login'

type NewPasswordRequiredFormProps = {
  confirm: (password: string) => any
  loading: boolean
}

function NewPasswordRequiredForm(props: NewPasswordRequiredFormProps) {
  const { fields, values } = useFormFields({
    password: '',
    passwordRepeat: '',
  })
  const { errors, validate } = useYup(values, validationSchema)

  const passwordInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus()
    }
  }, [])

  const onSubmit = async () => {
    const { isValid } = await validate()
    if (isValid) {
      props.confirm(values.password)
    }
  }

  const { spaces } = useTheme()

  return (
    <form onSubmit={event => event.preventDefault()}>
      <InputGrid>
        <LogInDescriptionText>
          Bitte definieren Sie hier Ihr neues Passwort.
          <ul>
            <li>8 Zeichen oder mehr</li>
            <li>Groß- und Klein-Buchstaben</li>
            <li>Mindestens eine Zahl</li>
          </ul>
          Verwenden Sie das selbe Passwort niemals für mehrere Accounts!
        </LogInDescriptionText>
        <div>
          <Label>Neues Passwort</Label>
          <Input
            type="password"
            ref={passwordInputRef}
            {...fields.password}
            disabled={props.loading}
          />
          {errors.password && <InputError>{errors.password}</InputError>}
        </div>
        <div>
          <Label>Passwort wiederholen</Label>
          <Input
            type="password"
            {...fields.passwordRepeat}
            style={{ margin: spaces.stack.xl }}
            disabled={props.loading}
          />
          {errors.passwordRepeat && (
            <InputError>{errors.passwordRepeat}</InputError>
          )}
        </div>
        <Button
          style={{ width: '100%', margin: spaces.stack.s }}
          type="submit"
          variant="primary"
          onClick={onSubmit}
          disabled={props.loading}
        >
          Passwort ändern
        </Button>
      </InputGrid>
    </form>
  )
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(requiredError('Neues Passwort'))
    .min(8, tooShortError('Neues Passwort', 8))
    .matches(/[a-z]/, 'Es muss mindestens ein Kleinbuchstabe enthalten sein.')
    .matches(/[A-Z]/, 'Es muss mindestens ein Großbuchstabe enthalten sein.')
    .matches(/\d/, 'Es muss mindestens eine Zahl enthalten sein.'),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwort stimmt nicht überein'),
})

export default NewPasswordRequiredForm
