import React, { ReactNode } from 'react'
import moment from 'moment'
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer'

import PdfDoc from 'containers/PdfDoc'
import { ClaimData } from 'claimbuilder'
import {
  ClaimDefinition,
  isDosingStepDefinition,
  isArgumentsStepDefinition,
} from 'claimbuilder/types'

type PdfDocumentProps = ClaimData & {
  signatureSrc?: string
  signatureWidth?: number
  signatureHeight?: number
  definition: ClaimDefinition
}

const SIGNATURE_HEIGHT = 50

const DocumentTitle = (props: { children: ReactNode }) => (
  <Text style={styles.letterTitel}>{props.children}</Text>
)
const SubTitle = (props: { children: ReactNode }) => (
  <Text style={styles.subTitle}>{props.children}</Text>
)
const LetterText = (props: { children: ReactNode }) => (
  <Text style={styles.letterText}>{props.children}</Text>
)
const LetterHeadAddress = (props: { children: ReactNode }) => (
  <Text style={styles.letterHeadAddressor}>{props.children}</Text>
)

const TableRows = (props: { children: ReactNode }) => (
  <View style={styles.tableRows}>{props.children}</View>
)
const ListItem = (props: { children: ReactNode }) => (
  <View style={styles.listItem} wrap={false}>
    <Text style={styles.listItemBullet}>{`\u2022 `}</Text>
    <Text style={styles.letterText}>{props.children}</Text>
  </View>
)

const PdfDocument = (props: PdfDocumentProps) => {
  const {
    signatureSrc,
    signatureHeight,
    signatureWidth,
    definition,
    ...data
  } = props

  const { doctor, patient, dosing, arguments: args, dispatch } = data

  const dosingStepEnabled = !!definition.steps.find(isDosingStepDefinition)
  const argumentsStepEnabled = !!definition.steps.find(
    isArgumentsStepDefinition
  )

  return (
    <PdfDoc>
      <PdfDoc.Receiver
        address={{
          name: dispatch.organisation,
          department: dispatch.department,
          street: dispatch.street,
          zip: dispatch.zip,
          city: dispatch.city,
          phone: dispatch.phone,
          fax: dispatch.fax,
        }}
        originCity={doctor.city}
      />

      <DocumentTitle>{`Antrag auf Erstattung von ${definition.product.name}`}</DocumentTitle>

      <LetterText>Sehr geehrte Damen und Herren,</LetterText>
      <LetterText>
        {`hiermit beantrage ich ${definition.product.name} für folgenden Patienten:`}
      </LetterText>

      <SubTitle>Patienten-Daten:</SubTitle>
      <TableRows>
        <View style={{ width: 110 }}>
          <LetterText>Name:</LetterText>
          <LetterText>Geburts-Datum:</LetterText>
          <LetterText>SVN:</LetterText>
          <LetterText>Gewicht:</LetterText>
        </View>
        <View>
          <LetterText>{patient.name}</LetterText>
          <LetterText>{moment(patient.birthdate).format('LL')}</LetterText>
          <LetterText>{patient.svn}</LetterText>
          <LetterText>{patient.weight} kg</LetterText>
        </View>
      </TableRows>

      <SubTitle>Diagnose:</SubTitle>
      <LetterText>{patient.diagnose}</LetterText>

      {dosingStepEnabled && (
        <>
          <SubTitle>Verbrauchsdaten:</SubTitle>
          {!!dosing.description && (
            <LetterText>{dosing.description}</LetterText>
          )}
          {!!dosing.monthDescription && (
            <LetterText>{dosing.monthDescription}</LetterText>
          )}
        </>
      )}

      {argumentsStepEnabled && (
        <>
          <SubTitle>{'Begründung:'}</SubTitle>
          {args.map(arg => (
            <ListItem key={arg.label}>
              {arg.label}
              {!!arg.description && ` - ${arg.description}`}
            </ListItem>
          ))}
        </>
      )}

      <View wrap={false}>
        <View style={{ marginTop: SIGNATURE_HEIGHT }}>
          <LetterText>Um einen positiven Bescheid wird gebeten.</LetterText>
        </View>
        <View style={{ marginTop: 10 }}>
          <LetterText>{'Mit freundlichen Grüßen'}</LetterText>
          <View
            style={{
              ...styles.signatureBox,
              height: SIGNATURE_HEIGHT,
              borderBottomWidth: signatureSrc ? 0 : 1,
            }}
          >
            {signatureSrc && signatureHeight && signatureWidth ? (
              <Image
                src={signatureSrc}
                style={{
                  width: (signatureWidth * SIGNATURE_HEIGHT) / signatureHeight,
                }}
              />
            ) : (
              undefined
            )}
          </View>
        </View>

        <LetterHeadAddress>{doctor.name}</LetterHeadAddress>
        <LetterHeadAddress>{doctor.organisation}</LetterHeadAddress>
        {!!doctor.department && (
          <LetterHeadAddress>{doctor.department}</LetterHeadAddress>
        )}
        <LetterHeadAddress>{doctor.street}</LetterHeadAddress>
        <LetterHeadAddress>
          {doctor.zip} {doctor.city}
        </LetterHeadAddress>
        {!!doctor.phone && (
          <LetterHeadAddress>Tel: {doctor.phone}</LetterHeadAddress>
        )}
        {!!doctor.fax && (
          <LetterHeadAddress>Fax: {doctor.fax}</LetterHeadAddress>
        )}
      </View>

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Seite ${pageNumber} / ${totalPages}`
        }
        fixed
      />
    </PdfDoc>
  )
}

// Font.register(
//   'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
//   { family: 'Oswald' },
// );

const baseFontSize = 10

const styles = StyleSheet.create({
  body: {
    paddingTop: 50,
    paddingBottom: 65,
    paddingLeft: 65,
    paddingRight: 65,
    fontSize: baseFontSize,
  },
  letterHead: {
    marginTop: 90,
  },
  letterHeadToSymbol: {
    fontSize: baseFontSize - 1,
  },
  letterHeadAddressor: {
    fontSize: baseFontSize,
    lineHeight: 1.4,
  },
  letterHeadDate: {
    textAlign: 'right',
    marginTop: -14,
  },
  letterTitel: {
    marginTop: 75,
    marginBottom: 14,
    fontSize: baseFontSize + 10,
  },
  subTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: baseFontSize + 4,
  },
  letterText: {
    fontSize: baseFontSize,
    lineHeight: 1.4,
  },
  tableRows: {
    display: 'flex',
    flexDirection: 'row',
  },
  listItem: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  listItemBullet: {
    fontSize: baseFontSize,
    lineHeight: 1.4,
    width: 12,
  },
  signatureBox: {
    width: 250,
    marginBottom: 15,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: baseFontSize - 2,
    bottom: 30,
    left: 0,
    right: 0,
    paddingHorizontal: 35,
    textAlign: 'right',
    color: 'grey',
  },
})

export default PdfDocument
