import React, {
  useMemo,
  CSSProperties,
  ReactNode,
  FocusEventHandler,
  MouseEventHandler,
} from 'react'

import FaIcon, { FaIconProps } from 'components/FaIcon'

import { PrimaryButton, SecondaryButton, TertiaryButton } from './Button'

export type ButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  onBlur?: FocusEventHandler<HTMLElement>
  type?: 'button' | 'submit' | 'reset'
  children?: ReactNode
  disabled?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  hidden?: boolean
  className?: string
  style?: CSSProperties
}

const buttons = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
}

export const getButtonComponent = (
  variant: keyof typeof buttons = 'secondary'
) => {
  return buttons[variant]
}

const Button = (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const Component = useMemo(() => getButtonComponent(props.variant), [
    props.variant,
  ])
  return <Component {...props} ref={ref} />
}

export const Icon = ({ style, ...props }: FaIconProps) => {
  return <FaIcon style={{ transform: 'scale(1.1)', ...style }} {...props} />
}

type ButtonType = React.ForwardRefExoticComponent<
  ButtonProps & React.RefAttributes<HTMLButtonElement>
> & {
  Icon: typeof Icon
}

const ForwardRefButton = React.forwardRef(Button) as ButtonType
ForwardRefButton.Icon = Icon

ForwardRefButton.defaultProps = {
  variant: 'secondary',
} as Partial<ButtonProps>

export default ForwardRefButton
