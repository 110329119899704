import React, { useEffect, useMemo, useRef } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { useTheme } from 'styled'

import * as API from 'api'
import { useApi, useFormFields } from 'hooks'
import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import Spinner from 'components/Spinner'
import InputGrid from 'components/InputGrid'
import Label from 'components/Label'
import Input from 'components/Input'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import { SickfundService } from 'types'

function EditSickfundService(
  props: RouteComponentProps & {
    serviceId?: string
    sickfundId?: string
    onUpdate?: () => void
  }
) {
  const updateRef = useRef(() => {})
  updateRef.current = props.onUpdate || (() => {})
  const { colors } = useTheme()
  const isNew = props.serviceId === 'new'
  const [service, loading] = useSickfundService(
    props.sickfundId || '',
    props.serviceId
  )
  const initialValues = useMemo(
    () => ({
      ...initialFormFields,
      ...(service || {}),
    }),
    [service]
  )
  const { fields, values, setValue, reset } = useFormFields(initialValues)
  useEffect(() => {
    reset()
  }, [reset, initialValues])

  const [delResponse, deleteService] = useApi(API.deleteSickfundService)
  const deleted = delResponse.calledOnce && !delResponse.error
  useEffect(() => {
    if (deleted) {
      updateRef.current && updateRef.current()
      navigate('./')
    }
  }, [deleted])
  const handleDelete = () => {
    if (
      service &&
      // eslint-disable-next-line no-restricted-globals
      confirm(`Si wollen die Servicestelle ${service.name} wirklich löschen?`)
    ) {
      deleteService(service.sickfundId, service.id)
    }
  }

  const [updateResponse, update] = useApi(API.updateSickfundService)
  const updated = updateResponse.calledOnce && !updateResponse.error
  useEffect(() => {
    if (updated) {
      updateRef.current && updateRef.current()
      navigate('./')
    }
  }, [updated])
  const handleUpdate = () => {
    if (props.sickfundId && props.serviceId) {
      update({ id: props.serviceId, sickfundId: props.sickfundId, ...values })
    }
  }

  const [createRes, create] = useApi(API.createSickfundService)
  const created = createRes.calledOnce && !createRes.error
  useEffect(() => {
    if (created) {
      updateRef.current && updateRef.current()
      navigate('./')
    }
  }, [created])
  const handleCreate = () => {
    if (props.sickfundId) {
      create({ sickfundId: props.sickfundId, ...values })
    }
  }

  const handleSave = () => {
    return isNew ? handleCreate() : handleUpdate()
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <FormGrid>
      <FormDescription>
        <FormDescription.Heading as="h2">Servicestelle</FormDescription.Heading>
        <FormDescription.Content>
          Hier können Sie die Servicestelle editieren.
        </FormDescription.Content>
      </FormDescription>

      <InputGrid>
        <InputGrid.Item>
          <Label>Name</Label>
          <Input {...fields.name} type="text" />
        </InputGrid.Item>
        <InputGrid.Item>
          <Label>Straße</Label>
          <Input {...fields.street} type="text" />
        </InputGrid.Item>

        <InputGrid.Item side={'left'}>
          <Label>PLZ</Label>
          <Input
            value={values.zip ? values.zip : ''}
            onChange={evt => setValue('zip', Number(evt.currentTarget.value))}
            type="number"
          />
        </InputGrid.Item>
        <InputGrid.Item side={'right'}>
          <Label>Stadt</Label>
          <Input {...fields.city} type="text" />
        </InputGrid.Item>

        <InputGrid.Item side={'left'}>
          <Label>Phone</Label>
          <Input {...fields.phone} type="phone" />
        </InputGrid.Item>
        <InputGrid.Item side={'right'}>
          <Label>fax</Label>
          <Input {...fields.fax} type="phone" />
        </InputGrid.Item>

        <InputGrid.Item>
          <Label>E-Mail</Label>
          <Input {...fields.email} type="email" />
        </InputGrid.Item>

        <InputGrid.Item>
          <Label>Note</Label>
          <Input {...fields.note} type="text" />
        </InputGrid.Item>

        <InputGrid.Item side="left">
          <Checkbox
            label="E-Mail Allowed"
            checked={values.emailAllowed}
            onChange={({ currentTarget }) =>
              setValue('emailAllowed', currentTarget.checked)
            }
          />
        </InputGrid.Item>
        <InputGrid.Item side="right">
          <Checkbox
            label="Fax Preferred"
            checked={values.faxPreferred}
            onChange={({ currentTarget }) =>
              setValue('faxPreferred', currentTarget.checked)
            }
          />
        </InputGrid.Item>

        <InputGrid.Item side="left">
          <Checkbox label="Mail Secure" checked={values.secureMail} disabled />
        </InputGrid.Item>

        <InputGrid.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!isNew && (
            <Button
              style={{ background: colors.danger, marginRight: 'auto' }}
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
          <Button
            variant="secondary"
            style={{ marginRight: 16 }}
            onClick={() => navigate('./')}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </InputGrid.Item>
      </InputGrid>
    </FormGrid>
  )
}

const initialFormFields: Omit<SickfundService, 'id' | 'sickfundId'> = {
  name: '',
  street: '',
  zip: 0,
  city: '',
  phone: '',
  fax: '',
  email: '',
  note: '',
  secureMail: false,
  emailAllowed: false,
  faxPreferred: false,
}

const useSickfundService = (
  sickfundId: string,
  serviceId: string = 'INVALID'
) => {
  const [response, getServices] = useApi(API.getSickfundService)
  const services = response.data
  useEffect(() => {
    getServices(sickfundId)
  }, [getServices, sickfundId])

  const service = (services || []).find(s => s.id === serviceId) || null

  return [service, response.loading] as [typeof service, boolean]
}

export default EditSickfundService
