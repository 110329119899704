import React from 'react';
import moment from 'moment'
import 'moment/locale/de'

import theme from 'styled/themes/default'
import { ThemeProvider } from 'styled'
import { AuthProvider } from 'auth'
import AppStyles from './AppStyles'
import Pages from './pages'

moment.locale('de')

const App = () => (
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <>
        <AppStyles />
        <Pages />
      </>
    </ThemeProvider>
  </AuthProvider>
)

export default App;
