import styled from 'styled'

import Button from '../../components/Button'

const Background = styled.div`
  position: fixed;
  overflow: scroll;
  top: 0px;
  min-width: 100vw;
  height: 300px;
  background: ${props => props.theme.colors.background};
  z-index: 5;
  min-height: 100vh;
`

const MailHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.theme.spaces.stack.l};
`

const MailInfos = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 87px auto;
  grid-row-gap: ${props => props.theme.spaces.xs};
  grid-column-gap: ${props => props.theme.spaces.l};
  align-items: center;
  justify-items: stretch;
`

const MailInfoLabel = styled.label`
  text-align: right;
`

const CancelButton = styled(Button)`
  margin: ${props => props.theme.spaces.xs} 0;
  padding: ${props => props.theme.spaces.insetSquish.s};

  :hover {
    background: ${props => props.theme.colors.danger};
    color: #fff;
  }
`

const AttachmentArea = styled.div`
  display: grid;
  grid-template-columns: 170px auto;
  grid-column-gap: ${props => props.theme.spaces.l};
  align-items: center;
  margin: ${props => props.theme.spaces.stack.m};
`

const Attachments = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

const GivenAttachmentsGrid = styled.div`
  display: grid;
  grid-template-columns: 35px auto;
  grid-column-gap: ${props => props.theme.spaces.xs};
  align-items: center;
`

const GivenAttachmentLink = styled.a`
  font-weight: 600;
  margin-bottom: 4px;

  color: ${props => props.theme.colors['grey-40']};
  :hover {
    color: ${props => props.theme.colors.primary};
  }
`

const GivenAttachmentsLabel = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors['grey-60']};
  padding-top: 4px;
  margin: ${props => props.theme.spaces.stack.s};
`

const Mail = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
`

export {
  Background,
  MailHeader,
  MailInfos,
  MailInfoLabel,
  CancelButton,
  AttachmentArea,
  Attachments,
  GivenAttachmentsGrid,
  GivenAttachmentLink,
  GivenAttachmentsLabel,
  Mail,
}
