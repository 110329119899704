import React, { useContext } from 'react'

import stepsContext from '../context'

type StepsPanesProps = {
  children?: React.ReactNode
}

const StepsPanes: React.FC<StepsPanesProps> = (props) => {
  const { activeIndex }= useContext(stepsContext)
  const panes = React.Children.map(props.children, (child, idx) => (
    <div style={{ display: idx === activeIndex ? 'block' : 'none' }}>
      {child}
    </div>
  )) 
  return (
    <>{panes}</>
  )
}

export default StepsPanes
