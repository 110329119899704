/* eslint-disable no-restricted-globals */
import {useState, useEffect} from 'react';

export const isClient = typeof window === 'object';

export const on = (obj: any, ...args: any[]) => obj.addEventListener(...args);

export const off = (obj: any, ...args: any[])  => obj.removeEventListener(...args);

type PatchedHistoryMethodNames =  'pushState' | 'replaceState';

const patchHistoryMethod = (method: PatchedHistoryMethodNames) => {
  const original = history[method];

  history[method] = function (...args: string[]) {
    // @ts-ignore
    const result = original.apply(this, args);
    const event = new Event(method.toLowerCase());

    (event as any).state = args[0];

    window.dispatchEvent(event);

    return result;
  };
};

if (isClient) {
  patchHistoryMethod('pushState');
  patchHistoryMethod('replaceState');
}

export interface LocationSensorState {
  trigger: string;
  state?: any;
  length?: number;
  hash?: string;
  host?: string;
  hostname?: string;
  href?: string;
  origin?: string;
  pathname?: string;
  port?: string;
  protocol?: string;
  search?: string;
}

const useLocation = (): LocationSensorState => {
  const buildState = (trigger: string) => {
    const {
      state,
      length
    } = history;

    const {
      hash,
      host,
      hostname,
      href,
      origin,
      pathname,
      port,
      protocol,
      search
    } = location;

    return {
      trigger,
      state,
      length,
      hash,
      host,
      hostname,
      href,
      origin,
      pathname,
      port,
      protocol,
      search
    };
  };

  const [state, setState] = useState(isClient
    ? buildState('load')
    : {
      trigger: 'load',
      length: 1
    }
  );

  const onChange = (trigger: string) =>
    setState(buildState(trigger));

  const onPopstate = () => onChange('popstate');
  const onPushstate = () => onChange('pushstate');
  const onReplacestate = () => onChange('replacestate');

  useEffect(() => {
    on(window, 'popstate', onPopstate);
    on(window, 'pushstate', onPushstate);
    on(window, 'replacestate', onReplacestate);

    return () => {
      off(window, 'popstate', onPopstate);
      off(window, 'pushstate', onPushstate);
      off(window, 'replacestate', onReplacestate);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};

export default useLocation;
