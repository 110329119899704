import React, { ReactNode, useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useTheme } from 'styled'

import TopMenuBar from './TopMenuBar'
import ContentArea from './ContentArea'
import Footer from './Footer'
import ContentErrorBoundary from './ContentErrorBoundary'

import { AlertProvider, AlertFeed } from 'components/Alert'

type DefaultPageLayoutProps = RouteComponentProps & {
  children: ReactNode
}

function DefaultPageLayout({ children }: DefaultPageLayoutProps) {
  const { colors, meta, spaces } = useTheme()
  const alertPadding = useMemo(() => {
    return `calc((100% - ${meta.fixedLayoutWidth}px) / 2 + ${spaces.l})`
  }, [meta.fixedLayoutWidth, spaces.l])

  return (
    <AlertProvider>
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          background: colors.background,
        }}
      >
        <TopMenuBar />
        <AlertFeed
          alertStyle={{
            borderRadius: 0,
            paddingLeft: alertPadding,
            paddingRight: alertPadding,
          }}
        />
        <ContentArea style={{ flex: 'auto' }}>
          <ContentErrorBoundary>{children}</ContentErrorBoundary>
        </ContentArea>
        <Footer />
      </div>
    </AlertProvider>
  )
}

export default DefaultPageLayout
