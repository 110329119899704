import {
  ClaimDefinition,
  StepDefinitionTypes,
  PharmaCompany,
  DosingFrequencyUnit,
  ClaimClearance,
} from 'claimbuilder'

import infoDoc from './assets/AT_0631_001_DC_F_FI_19-07-08_Cuvitru.pdf'
import logo from './assets/cuvitru.png'

export const CuvitruClaimDefinition: ClaimDefinition = {
  product: {
    name: 'Cuvitru',
    informationDocument: infoDoc,
    logo: logo,
    substances: ['normales Immunglobulin vom Menschen 20'],
  },
  clearance: ClaimClearance.APPROVED,
  owner: PharmaCompany.TAKEDA,
  steps: [
    {
      type: StepDefinitionTypes.DOCTOR,
    },
    {
      type: StepDefinitionTypes.PATIENT,
      diagnoseOptions: [
        {
          label: 'PID',
          value:
            'PID: Primärem Immundefektsyndrom mit unzureichender Antikörperbildung.',
        },
        {
          label: 'SID bei CLL',
          value:
            'SID bei CLL: Hypogammaglobulinämie und rezidivierenden bakteriellen Infektionen bei Patienten mit chronischer lymphatischer Leukämie (CLL), bei denen eine prophylaktische Antibiotikatherapie fehlgeschlagen oder kontraindiziert ist.',
        },
        {
          label: 'SID bei MM',
          value:
            'SID bei MM: Hypogammaglobulinämie und rezidivierenden bakteriellen Infektionen bei Patienten mit multiplem Myelom.',
        },
        {
          label: 'HSCT',
          value:
            'HSCT: Hypogammaglobulinämie bei Patienten vor und nach allogener hämatopoetischer Stammzelltransplantation (HSCT)',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DOSING,
      unit: 'Gramm',
      frequencyUnit: DosingFrequencyUnit.DAY,
      frequencies: [
        {
          label: 'täglich',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5',
          value: 5,
        },
        {
          label: '6',
          value: 6,
        },
        {
          label: '7',
          value: 7,
        },
        {
          label: '8',
          value: 8,
        },
        {
          label: '9',
          value: 9,
        },
        {
          label: '10',
          value: 10,
        },
        {
          label: '11',
          value: 11,
        },
        {
          label: '12',
          value: 12,
        },
        {
          label: '13',
          value: 13,
        },
        {
          label: '14',
          value: 14,
        },
        {
          label: 'sonstiges',
          value: 'CUSTOM',
        },
      ],
      dosings: [
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
        {
          value: 5,
        },
        {
          value: 6,
        },
        {
          value: 7,
        },
        {
          value: 8,
        },
        {
          value: 9,
        },
        {
          value: 10,
        },
        {
          value: 11,
        },
        {
          value: 12,
        },
        {
          value: 13,
        },
        {
          value: 14,
        },
        {
          value: 15,
        },
      ],
    },
    {
      type: StepDefinitionTypes.ARGUMENTS,
      arguments: [
        {
          label: 'Subcuvia läuft aus',
          description: '(März 2020)',
        },
        {
          label:
            'Subcuvia war das einzige subkutane Immunglobulin, das vom Patienten vertragen wurde',
          description:
            'Cuvitru ist das Nachfolgepräparat von Subcuvia (20% statt 16%), gleiches Plasma, optimierter Herstellungsprozess, verbesserte Formulierung',
        },
        {
          label: 'Baugleiche Pumpe, gewohnter Patientenservice',
          description: 'Patient wird nicht verunsichert',
        },
        {
          label: 'Pädiatrischer Patient',
          description:
            'höhere Infusionsgeschwindigkeit, kürzere Infusionsdauer, bedeutet höhere Therapieakzeptanz',
        },
        {
          label: 'Patient mit besonderen Bedürfnissen',
          description:
            'höhere Infusionsgeschwindigkeit, kürzere Infusionsdauer, bedeutet höhere Therapieakzeptanz',
        },
        {
          label: 'Mangelnde Verträglichkeit von anderem SCIG',
          description:
            'sc-Immunglobulin-Präparat verursacht Schmerzen an der Einstichstelle oder andere signifikante Nebenwirkungen',
        },
        {
          label:
            'Nachweisbarer Masern-Antikörper-Titer in Takeda IG-Präparaten',
          description: '(M. Farcet Vaccine 2019 ; 33: 3151–3153)',
        },
        {
          label: 'Polysorbatunverträglichkeit',
          description: '',
        },
        {
          label:
            'Mangelnde Infektkontrolle unter bisherigem subkutanem Immunglobulin',
          description: 'hoher Antibiotikaeinsatz',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DISPATCH,
    },
    {
      type: StepDefinitionTypes.SUMMARY,
    },
  ],
}

export default CuvitruClaimDefinition
