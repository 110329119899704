import React, { useEffect, useReducer, useState, useCallback, } from 'react'

type FetchInitAction = {
  type: 'FETCH_INIT'
}

type FetchSuccessAction<T> = {
  type: 'FETCH_SUCCESS',
  payload: { data: T }
}

type FetchErrorAction = {
  type: 'FETCH_ERROR',
  payload: { error: Error }
}

type ApiAction<T> = FetchInitAction | FetchSuccessAction<T> | FetchErrorAction

type ApiState<T> = {
  data: T | null,
  loading: boolean,
  error: Error | null,
  calledOnce: boolean
}

const apiReducer = <T>(state: ApiState<T>, action: ApiAction<T>): ApiState<T> => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        error: null
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data,
        calledOnce: true
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        calledOnce: true
      }
  }
}

type ApiFunction<A extends any[], R> = (...args: A) => Promise<R>

// useApi should be generic of <ApiFunction<A,R>>
const useApi = <A extends any[], R>(apiFunction: ApiFunction<A, R>, initialCallParams?: A) => {
  const [params, setParams] = useState(initialCallParams)
  const [state, dispatch] = useReducer(
    apiReducer as React.Reducer<ApiState<R>, ApiAction<R>>, 
    { data: null, loading: false, error: null } as ApiState<R>
  )
  const [refetchHelper, setRefetchHelper] = useState(0)

  useEffect(() => {
    let didCancel = false

    const fetchData = async (...args: A) => {
      dispatch({ type: 'FETCH_INIT' })

      try {
        const data = await apiFunction(...args)
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: { data }})
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_ERROR', payload: { error } })
        }
      }
    }

    if (params) {
      fetchData(...params)
    }

    return () => { didCancel = true }
  }, [apiFunction, params, refetchHelper])

  const callApi = useCallback((...callParams: A) => setParams(callParams), [])

  const refetch = useCallback(() => setRefetchHelper(c => c+1), [])

  return [state, callApi, refetch] as [ApiState<R>, typeof callApi, typeof refetch]
}

export default useApi
