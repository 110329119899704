import React, { useMemo } from 'react'
import * as yup from 'yup'
import { useTheme } from 'styled'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import Input from 'components/Input'
import Label from 'components/Label'
import InputGrid from 'components/InputGrid'
import InputError from 'components/InputError'
import Button from 'components/Button'
import { AlertBox } from 'components/Alert'

import { requiredError, tooShortError } from 'messages/errors'
import { getChangePasswordApiError } from 'messages/password'
import { useChangePassword } from 'auth'
import useFormFields from 'hooks/useFormFields'
import useYup from 'hooks/useYup'

const initalFormState = {
  password: '',
  newPassword: '',
  newPasswordRepeat: '',
}

function NewPasswordForm() {
  const { fields, values, reset } = useFormFields(initalFormState)
  const { validate, errors } = useYup(values, validationSchema)
  const { changePassword, loading, success, error } = useChangePassword()

  const { spaces } = useTheme()

  const errorMessage = useMemo(() => getChangePasswordApiError(error), [error])


  const handleSetNewPassword = async () => {
    const { isValid } = await validate()
    if (isValid) {
      const { success } = await changePassword(
        values.password,
        values.newPassword
      )
      if (success) {
        reset(initalFormState)
      }
    }
  }

  return (
    <form onSubmit={evt => evt.preventDefault()}>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading>
            Passwort &auml;ndern
          </FormDescription.Heading>
          <FormDescription.Content>
            <p>Das Passwort muss folgenden Anforderungen entsprechen:</p>
            <ul>
              <li>8 Zeichen oder mehr</li>
              <li>Gro&szlig;- und Klein-Buchstaben</li>
              <li>Mindestens eine Zahl</li>
            </ul>
            <p>
              Verwenden Sie das selbe Passwort niemals für mehrere Accounts!
            </p>
          </FormDescription.Content>
        </FormDescription>
        <InputGrid>
          {success && (
            <AlertBox type="success">
              Sie haben erfolgreich Ihr Passwort geändert
            </AlertBox>
          )}
          {errorMessage && <AlertBox type="error">{errorMessage}</AlertBox>}
          <div>
            <Label>Aktuelles Passwort</Label>
            <Input type="password" disabled={loading} {...fields.password} />
            {errors.password && <InputError>{errors.password}</InputError>}
          </div>
          <div>
            <Label>Neues Passwort</Label>
            <Input type="password" disabled={loading} {...fields.newPassword} />
            {errors.newPassword && (
              <InputError>{errors.newPassword}</InputError>
            )}
          </div>
          <div style={{ margin: spaces.stack.l }}>
            <Label>Passwort best&auml;tigen</Label>
            <Input
              type="password"
              disabled={loading}
              {...fields.newPasswordRepeat}
            />
            {errors.newPasswordRepeat && (
              <InputError>{errors.newPasswordRepeat}</InputError>
            )}
          </div>
          <Button
            onClick={handleSetNewPassword}
            variant="primary"
            disabled={loading}
            style={{ justifySelf: 'start' }}
            type="submit"
          >
            <span>Passwort &auml;ndern</span>
          </Button>
        </InputGrid>
      </FormGrid>
    </form>
  )
}

const validationSchema = yup.object().shape({
  password: yup.string().required(requiredError('Aktuelles Passwort')),
  newPassword: yup
    .string()
    .min(8, tooShortError('Neues Passwort', 8))
    .matches(/[a-z]/, 'Es muss mindestens ein Kleinbuchstabe enthalten sein.')
    .matches(/[A-Z]/, 'Es muss mindestens ein Großbuchstabe enthalten sein.')
    .matches(/\d/, 'Es muss mindestens eine Zahl enthalten sein.')
    .required(requiredError('Neues Passwort')),
  newPasswordRepeat: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwort stimmt nicht überein'),
})


export default NewPasswordForm
