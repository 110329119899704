import React, { ReactNode } from 'react'

import MaterialUIDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface DialogProps {
  open: boolean
  onClose: () => void
  ariaLabelledby: string
  ariaDescribedby: string
  children: ReactNode
}

const Dialog = (props: DialogProps) => {
  return (
    <MaterialUIDialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby={props.ariaLabelledby}
      aria-describedby={props.ariaDescribedby}
    >
      {props.children}
    </MaterialUIDialog>
  )
}

Dialog.Actions = DialogActions
Dialog.Content = DialogContent
Dialog.ContentText = DialogContentText
Dialog.Title = DialogTitle

export default Dialog
