import React from 'react'
import { navigate } from '@reach/router'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import InputGrid from 'components/InputGrid'
import Button from 'components/Button'

import { useApi } from 'hooks'
import { useUserInfo, useAuthentication } from 'auth'
import * as API from 'api'

function DeleteAccountForm() {
  const userInfo = useUserInfo()
  const { signOut } = useAuthentication()
  const [{ error }, deleteProfile] = useApi(API.deleteProfile)

  const handleDeleteAccountButton = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm('Wollen Sie den Account wirklich löschen?')
    if (confirmed) {
      if (userInfo) {
        await deleteProfile(userInfo.attributes.sub)
        signOut()
        navigate('/')
      }
    }
  }

  return (
    <FormGrid>
      <FormDescription>
        <FormDescription.Heading>Account l&ouml;schen</FormDescription.Heading>
        <FormDescription.Content>
          Hier k&ouml;nnen Sie Ihren Account l&ouml;schen.
        </FormDescription.Content>
      </FormDescription>
      <InputGrid>
        <FormDescription.Content style={{ margin: 0 }}>
          <p>
            Bitte beachten Sie, dass mit dem L&ouml;schen s&auml;mtliche
            Informationen und Einstellungen Ihres Accounts unwiederbringlich
            gel&ouml;scht werden.
          </p>
          <p>
            <strong>
              Eine nachtr&auml;gliche Wiederherstellung Ihres Accounts ist nicht
              m&ouml;glich !
            </strong>
          </p>
        </FormDescription.Content>
        {error && (
          <FormDescription.Content>
            Ein Fehler ist beim löschen des Accounts aufgetreten. Bitte melden
            Sie sich bei dem Adminsitrator. <br />
            {error.message}
          </FormDescription.Content>
        )}
        <Button
          style={{ justifySelf: 'start' }}
          onClick={handleDeleteAccountButton}
        >
          <span>Account l&ouml;schen</span>
        </Button>
      </InputGrid>
    </FormGrid>
  )
}

export default DeleteAccountForm
