
import styled from 'styled'

export const TitleRow = styled.div`
  display: flex;
  > * {
    margin: ${props => props.theme.spaces.inline.s};
  }

  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors["grey-30"]};
`

export default TitleRow
