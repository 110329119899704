import styled from 'styled'

const BadgeContainer = styled.div`
  margin: ${props => props.theme.spaces.insetSquish.m};
  display: flex;
  align-items: center;
`

const BadgeTextArea = styled.div`
  background: ${props => props.theme.colors.background};
  padding: ${props => props.theme.spaces.insetSquish.s};
  color: ${props => props.theme.colors['grey-40']};

  border-radius: 100px;
  font-size: 10px;
  font-weight: 600;
`

const BadgeIconTextArea = styled(BadgeTextArea)`
  padding-left: ${props => props.theme.spaces.m};
  margin-left: -8px;
  border-radius: 0 100px 100px 0;
`

const BadgeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  width: 30px;
  height: 30px;
  border-radius: 500px;
  z-index: 2;
`

export { BadgeContainer, BadgeTextArea, BadgeIconTextArea, BadgeIcon }
