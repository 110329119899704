import React, { ReactNode, ChangeEventHandler } from 'react'

import { Wrapper, StyledSelect } from './Select'

type SelectProps = {
  children?: ReactNode
  name: string
  value?: string | number
  defaultValue?: string
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLSelectElement>
}

type OptionProps = {
  children?: ReactNode
  value: string | number
  disabled?: boolean
  selected?: boolean
  hidden?: boolean
}

function Option(props: OptionProps) {
  return <option {...props} />
}

function Select(props: SelectProps) {
  return (
    <Wrapper>
      <StyledSelect {...props}>{props.children}</StyledSelect>
    </Wrapper>
  )
}

Select.Option = Option
export default Select
