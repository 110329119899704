import React, { SyntheticEvent, useEffect, useRef } from 'react'
import * as yup from 'yup'

import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import InputGrid from 'components/InputGrid'
import Label from 'components/Label'
import RadioGroup from 'components/RadioGroup'
import InputGroup from 'components/InputGroup'

import * as API from 'api'
import { useFormFields, useApi, useYup } from 'hooks'
import { requiredError, tooLongError, emailError } from 'messages/errors'
import Select from 'components/Select'
import InputError from 'components/InputError'
import { SickfundService } from 'types'
import { ClaimStepFooter, ClaimData } from 'claimbuilder'
import Input from 'components/Input'
import { useClaim } from 'claimbuilder/ClaimContext'

export const DispatchStep = () => {
  const data = initialFormFields
  const { fields, values, setValue } = useFormFields(data)
  const {
    fields: sickfundFields,
    setValue: setSickfundValue,
    values: sickfundValues,
  } = useFormFields(data.sickfund)
  const {
    fields: chiefFields,
    setValue: setChiefValue,
    values: chiefValues,
  } = useFormFields(data.chief)
  useEffect(() => {
    setValue('sickfund', sickfundValues)
    setValue('chief', chiefValues)
  }, [sickfundValues, chiefValues, setValue])

  const [
    { data: sickfunds, loading: sickfundsLoading },
    { data: sickfundServices, loading: sickfundServicesLoading },
  ] = useGetSickfundService(sickfundValues, setSickfundValue)

  const { validate, errors } = useYup(values, validationSchema)
  const { setData } = useClaim()
  const handleNext = async (evt: SyntheticEvent<HTMLButtonElement>) => {
    const { isValid } = await validate()
    if (isValid) {
      const dispatch = formValuesToClaimDispatch(values)
      setData(prev => ({
        ...prev,
        dispatch
      }))
    } else {
      evt.preventDefault()
    }
  }

  const disabled = false
  return (
    <>
      <FormGrid>
        <FormDescription>
          <FormDescription.Heading as="h2">
            {'Adressat'}
          </FormDescription.Heading>
          <FormDescription.Content>
            {'Bitte geben Sie hier den Adressat an'}
          </FormDescription.Content>
        </FormDescription>
        <InputGrid>
          <InputGrid.Item>
            <Label>{'Adressat'}</Label>
            <RadioGroup {...fields.receiverOrganisation}>
              <InputGroup>
                <RadioGroup.Button
                  value="sickfund"
                  style={{ flex: '1', textAlign: 'center' }}
                  disabled={disabled}
                >
                  {'Krankenkasse'}
                </RadioGroup.Button>
                <RadioGroup.Button
                  value="chief"
                  style={{ flex: '1', textAlign: 'center' }}
                  disabled={disabled}
                >
                  {'interner chefärztlicher Dienst'}
                </RadioGroup.Button>
              </InputGroup>
            </RadioGroup>
          </InputGrid.Item>

          {fields.receiverOrganisation.value === 'sickfund' ? (
            <>
              <InputGrid.Item>
                <Label>Krankenkasse</Label>
                <Select
                  {...sickfundFields.sickfundId}
                  disabled={sickfundsLoading}
                >
                  <Select.Option key="disabled" value="" disabled>
                    {sickfundsLoading ? 'Lade Daten...' : 'Bitte auswählen...'}
                  </Select.Option>
                  {(sickfunds || [])
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map(sf => (
                      <Select.Option key={sf.id} value={sf.id}>
                        {sf.name}
                      </Select.Option>
                    ))}
                </Select>
                {errors.sickfund && errors.sickfund.sickfundName && (
                  <InputError>{errors.sickfund.sickfundName}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Service-Stelle</Label>
                <Select
                  {...sickfundFields.sickfundServiceId}
                  disabled={sickfundServicesLoading}
                >
                  <Select.Option key="disabled" value="" disabled>
                    {sickfundServicesLoading
                      ? 'Lade Daten...'
                      : 'Bitte auswählen...'}
                  </Select.Option>
                  {(sickfundServices || [])
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map(sfs => (
                      <Select.Option key={sfs.id} value={sfs.id}>
                        {sfs.name}
                      </Select.Option>
                    ))}
                </Select>
                {errors.sickfund && errors.sickfund.sickfundServiceName && (
                  <InputError>{errors.sickfund.sickfundServiceName}</InputError>
                )}
              </InputGrid.Item>
            </>
          ) : (
            <>
              <InputGrid.Item>
                <Label>E-Mail</Label>
                <Input type="text" {...chiefFields.email} />
                {errors.chief && errors.chief.email && (
                  <InputError>{errors.chief.email}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Krankenhaus Name</Label>
                <Input type="text" {...chiefFields.name} disabled={disabled} />
                {errors.chief && errors.chief.name && (
                  <InputError>{errors.chief.name}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Abteilung</Label>
                <Input
                  type="text"
                  {...chiefFields.department}
                  disabled={disabled}
                />
                {errors.chief && errors.chief.department && (
                  <InputError>{errors.chief.department}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Strasse</Label>
                <Input
                  type="text"
                  {...chiefFields.street}
                  disabled={disabled}
                />
                {errors.chief && errors.chief.street && (
                  <InputError>{errors.chief.street}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item side={'left'} responsiveFullAt={'phone'}>
                <Label>PLZ</Label>
                <Input
                  type="number"
                  onChange={evt =>
                    setChiefValue('zip', Number(evt.currentTarget.value))
                  }
                  name={chiefFields.zip.name}
                  value={chiefFields.zip.value || ''}
                  disabled={disabled}
                />
                {errors.chief && errors.chief.zip && (
                  <InputError>{errors.chief.zip}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item side={'right'} responsiveFullAt={'phone'}>
                <Label>Ort</Label>
                <Input type="text" {...chiefFields.city} disabled={disabled} />
                {errors.chief && errors.chief.city && (
                  <InputError>{errors.chief.city}</InputError>
                )}
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Telefon</Label>
                <Input type="text" {...chiefFields.phone} disabled={disabled} />
              </InputGrid.Item>
              <InputGrid.Item>
                <Label>Fax</Label>
                <Input type="text" {...chiefFields.fax} disabled={disabled} />
              </InputGrid.Item>
            </>
          )}
        </InputGrid>
      </FormGrid>

      <ClaimStepFooter onCommit={handleNext} />
    </>
  )
}

const validationSchema = yup.object().shape({
  receiverOrganisation: yup.string().oneOf(['chief', 'sickfund']),
  chief: yup
    .object()
    .when(
      'receiverOrganisation',
      (
        receiverOrganisation: 'chief' | 'sickfund',
        schema: yup.ObjectSchema<{}>
      ) => {
        if (receiverOrganisation === 'chief') {
          return schema.shape({
            email: yup
              .string()
              .email(emailError())
              .max(254, tooLongError('E-Mail', 254))
              .required(requiredError('E-Mail')),
            name: yup
              .string()
              .required(requiredError('Krankenhaus Name'))
              .max(100, tooLongError('Krankenhaus Name', 100)),
            department: yup
              .string()
              .required(requiredError('Abteilung'))
              .max(100, tooLongError('Abteilung', 100)),
            street: yup
              .string()
              .required(requiredError('Straße'))
              .max(50, tooLongError('Straße', 50)),
            zip: yup
              .number()
              .required(requiredError('PLZ'))
              .min(1010, 'Bitte geben Sie eine gültige PLZ an.')
              .max(9999, 'Bitte geben Sie eine gültige PLZ an.'),
            city: yup
              .string()
              .required(requiredError('Ort'))
              .max(50, tooLongError('Ort', 50)),
          })
        }
        return schema
      }
    ),
  sickfund: yup.object().when('receiverOrganisation', {
    is: val => val === 'sickfund',
    then: yup.object().shape({
      sickfundServiceName: yup
        .string()
        .required(requiredError('Service-Stelle')),
      sickfundName: yup.string().required(requiredError('Krankenkasse')),
    }),
    otherwise: yup.object(),
  }),
})

const initialFormFields = {
  receiverOrganisation: 'sickfund' as 'sickfund' | 'chief',
  sickfund: {
    sickfundId: '',
    sickfundName: '',
    sickfundServiceId: '',
    sickfundServiceName: '',
    sickfundServiceStreet: '',
    sickfundServiceCity: '',
    sickfundServiceZip: 0,
    sickfundServicePhone: '',
    sickfundServiceFax: '',
    sickfundServiceEmail: '',
    sickfundServiceSecureEmail: false,
    sickfundServiceEmailAllowed: false,
  },
  chief: {
    email: '',
    name: '',
    department: 'Chefärztlicher Dienst',
    street: '',
    zip: 0,
    city: '',
    phone: '',
    fax: '',
  },
}

const formValuesToClaimDispatch = (values: typeof initialFormFields): ClaimData['dispatch'] => {
  if (values.receiverOrganisation === 'chief') {
    const chief = values.chief
    return ({
      organisation: chief.name,
      department: chief.department,
      email: chief.email,
      street: chief.street,
      zip: `${chief.zip}`,
      city: chief.city,
      phone: chief.phone,
      fax: chief.fax,

      isSickfundService: false,
      sickfundServiceEmailAllowed: false,
      sickfundServiceSecureEmail: false
    })
  } else {
    const sf = values.sickfund
    return ({
      organisation: sf.sickfundName,
      department: sf.sickfundServiceName,
      email: sf.sickfundServiceEmail,
      street: sf.sickfundServiceStreet,
      zip: `${sf.sickfundServiceZip}`,
      city: sf.sickfundServiceCity,
      phone: sf.sickfundServicePhone,
      fax: sf.sickfundServiceFax,

      isSickfundService: true,
      sickfundServiceEmailAllowed: sf.sickfundServiceEmailAllowed,
      sickfundServiceSecureEmail: sf.sickfundServiceSecureEmail
    })
  }
}

type SetValueFn<T> = <K extends keyof T>(key: K, value: T[K]) => void
type SickfundValues = (typeof initialFormFields)['sickfund']

const useGetSickfundService = (
  sickfundValues: SickfundValues,
  setSickfundValue: SetValueFn<SickfundValues>
) => {
  const [sickfundData, getSickfunds] = useApi(API.getSickfunds)
  const [sickfundServiceData, getSickfundServices] = useApi(
    API.getSickfundService
  )

  useEffect(() => {
    getSickfunds()
  }, [getSickfunds])

  const sickfunds = sickfundData.data

  useEffect(() => {
    if (sickfunds) {
      const selectedSickfund = sickfunds.find(
        sickfund => sickfund.id === sickfundValues.sickfundId
      )
      setSickfundValue(
        'sickfundName',
        selectedSickfund ? selectedSickfund.name : ''
      )
    }
  }, [sickfunds, sickfundValues.sickfundId, setSickfundValue])

  useEffect(() => {
    if (sickfundValues.sickfundId) {
      getSickfundServices(sickfundValues.sickfundId)
    }
  }, [getSickfundServices, sickfundValues.sickfundId])

  const initialized = useRef(false)
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
    } else {
      setSickfundValue('sickfundServiceId', '')
    }
  }, [setSickfundValue, sickfundValues.sickfundId])

  const sickfundServices = sickfundServiceData.data
  useEffect(() => {
    if (sickfundServices) {
      const selectedSickfundService =
        sickfundServices.find(
          service => service.id === sickfundValues.sickfundServiceId
        ) || ({} as SickfundService)

      setSickfundValue(
        'sickfundServiceName',
        selectedSickfundService.name || ''
      )
      setSickfundValue(
        'sickfundServiceStreet',
        selectedSickfundService.street || ''
      )
      setSickfundValue(
        'sickfundServiceCity',
        selectedSickfundService.city || ''
      )
      setSickfundValue('sickfundServiceZip', selectedSickfundService.zip || 0)
      setSickfundValue(
        'sickfundServicePhone',
        selectedSickfundService.phone || ''
      )
      setSickfundValue('sickfundServiceFax', selectedSickfundService.fax || '')
      setSickfundValue(
        'sickfundServiceEmail',
        selectedSickfundService.email || ''
      )
      setSickfundValue(
        'sickfundServiceSecureEmail',
        selectedSickfundService.secureMail || false
      )
      setSickfundValue(
        'sickfundServiceEmailAllowed',
        selectedSickfundService.emailAllowed || false
      )
    }
  }, [setSickfundValue, sickfundServices, sickfundValues.sickfundServiceId])

  return [sickfundData, sickfundServiceData] as [
    typeof sickfundData,
    typeof sickfundServiceData
  ]
}
