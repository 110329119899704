import API from '@aws-amplify/api'

import config from 'aws-exports'
import { UserGroup } from 'types'

const API_URL = config.Api.endpoint
const API_NAME = 'api_gateway'
API.configure({
  endpoints: [
    {
      name: API_NAME,
      endpoint: API_URL,
      region: 'eu-central-1',
    },
  ],
})

async function getProfile<T>(id: string) {
  const data = await API.get(API_NAME, `/profile/${id}`, {})
  return data as T
}

interface SaveProfileParam {
  id: string,
  email: string,
  type: UserGroup
  [propName: string]: any
}

async function saveProfile(profile: SaveProfileParam) {
  const data = await API.post(API_NAME, '/profile', {
    body: profile
  })
  return data
}

async function deleteProfile(id: string) {
  const data = await API.del(API_NAME, `/profile/${id}`, {
  })
  return data
}

export { saveProfile, getProfile, deleteProfile }

export default {
  saveProfile, getProfile, deleteProfile
}

// async function getToken() {
//   const session = await Auth.currentSession()
//   if (!session) {
//     console.log('session not found.')
//     return null
//   }
//   return session.getAccessToken().getJwtToken()
// }
