import React from 'react'
import { RouteComponentProps } from '@reach/router'

import useUserGroups from 'auth/useUserGroups'

import DefaultPageLayout from 'layout/DefaultPageLayout'

import PageHero from 'components/PageHero'

import PharmaAdministration from './PharmaAdministration'
import AdminAdministration from './AdminAdministration'

const Administration = (props: RouteComponentProps) => {
  const { isAdmin, isPharma } = useUserGroups()

  return (
    <DefaultPageLayout>
      <PageHero>
        <PageHero.Title>Einladungen</PageHero.Title>
      </PageHero>

      {isAdmin && <AdminAdministration />}
      {isPharma && <PharmaAdministration />}
    </DefaultPageLayout>
  )
}

export default Administration
