import { useEffect } from 'react'

import { useAlert } from 'components/Alert'
import { useUserInfo } from 'auth'
import { useApi, useSignatureStatus } from 'hooks'
import * as API from 'api'

function ClaimSendWarning() {
  const userInfo = useUserInfo()
  const userId = userInfo ? userInfo.attributes.sub : ''

  useEmailVerifyAlert(userId)
  useSignatureAlert()

  return null
}

const useSignatureAlert = () => {
  const [alert] = useAlert()

  const [sigStatus] = useSignatureStatus()

  useEffect(() => {
    if (sigStatus) {
      if (sigStatus !== 'AVAILABLE') {
        alert.warning(`
          Sie haben noch keine Signatur hochgeladen.
          Wenn Sie den Antrag via E-Mail versenden wollen,
          müssen Sie zuerst im profil Ihre Signatur hochladen.
        `)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, sigStatus])
}

const useEmailVerifyAlert = (userId: string) => {
  const [alert] = useAlert()
  const [
    { data: verificationStatusArray, error: emailStatusError },
    emailVerified,
  ] = useApi(API.emailVerified)

  useEffect(() => {
    if (userId) {
      emailVerified(userId)
    }
  }, [emailVerified, userId])

  useEffect(() => {
    if (emailStatusError) {
      alert.error('Fehler beim laden des E-Mail Verifizierungs-Status')
    }
  }, [alert, emailStatusError])

  useEffect(() => {
    if (verificationStatusArray) {
      if (verificationStatusArray[0].status !== 'VERIFIED') {
        alert.warning(`
          Ihre E-Mail Adresse ist nicht verifiziert.
          Wenn Sie den Antrag via E-Mail versenden wollen,
          müssen Sie zuerst im Profil Ihre E-Mail Adresse verifizieren.
        `)
      }
    }
  }, [alert, verificationStatusArray])
}

export default ClaimSendWarning
