import { createContext, useContext } from 'react'
import { useUserGroups } from 'auth'

interface ClaimConfig {
  prefill: boolean
}

interface ClaimConfigContextType {
  config: ClaimConfig
  setConfig: (arg: ClaimConfig | ((prev: ClaimConfig) => ClaimConfig)) => void
}

const ClaimConfigContext = createContext<ClaimConfigContextType>({
  config: { prefill: false },
  setConfig: () => {},
})

export const ClaimConfigProvider = ClaimConfigContext.Provider

export const useClaimConfig = () => useContext(ClaimConfigContext)

export const useInitialClaimConfig = (): ClaimConfig => {
  const { isAdmin, isPharma, isDemo } = useUserGroups()
  const prefill = isAdmin || isPharma || isDemo

  return { prefill }
}
