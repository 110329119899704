import { useState, useCallback, useMemo } from 'react'
import { Storage } from 'aws-amplify'

const loadImage = (src: string) => {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = event => resolve(event.target)
    img.src = src
  })
}

const useSignature = () => {
  const [signatureImageSrc, setSignatureImageSrc] = useState<null | string>(
    null
  )
  const [loading, setLoading] = useState(false)
  const [dimensions, setDimensions] = useState<null | {
    width: number
    height: number
  }>(null)

  const loadSignature = useCallback(async () => {
    setLoading(true)
    const signature = (await Storage.vault.get('signature.png')) as string
    const image = (await loadImage(signature)) as HTMLImageElement
    setDimensions({ width: image.naturalWidth, height: image.naturalHeight })
    setSignatureImageSrc(signature)
    setLoading(false)
  }, [])

  const signatureData = useMemo(
    () => ({ src: signatureImageSrc, loading, dimensions }),
    [dimensions, loading, signatureImageSrc]
  )

  return [loadSignature, signatureData] as [
    typeof loadSignature,
    typeof signatureData
  ]
}

export default useSignature
