import { useState } from 'react'

import { changePassword as changePasswordFunction } from './util'

interface ChangePasswordError extends Error {
  name: 'InvalidParameterException' | 'InvalidPasswordException' | 'NotAuthorizedException' | 'LimitExceededException'
}

/**
 * react hook to change password
 * 
 * error.name types:
 *   - InvalidPasswordException: Password did not conform with policy
 *   - NotAuthorizedException: Incorrect password
 *   - LimitExceededException: Too many wrong requests. Try again later.
 */
function useChangePassword() {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ChangePasswordError | null>(null)
  const [success, setSuccess] = useState<boolean | null>(null)
  
  const changePassword = async (oldPassword: string, newPassword: string) => {
    let err: ChangePasswordError | null = null
    try {
      setLoading(true)
      await changePasswordFunction(oldPassword, newPassword)
    } catch (error) {
      err = error
    } finally {
      setError(err)
      setLoading(false)
      setSuccess(err === null)
    }
    return {
      error: err,
      success: err === null
    }
  }

  return {
    changePassword,
    loading,
    success,
    error,
  }
}

export default useChangePassword
