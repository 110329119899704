import React, { CSSProperties } from 'react'

import { useTheme } from 'styled'

import FaIcon from '../FaIcon'
import { Badge, RemoveButton } from './styled-attachment-badge'

interface AttachmentBadgeProps {
  fileName: string
  size: number
  onDelete?: () => void
  style?: CSSProperties
}

function AttachmentBadge(props: AttachmentBadgeProps) {
  const { spaces } = useTheme()
  const kb = Math.ceil(props.size / 1000)
  const mb = kb/1000
  const showMB = mb >= 1

  return (
    <>
      <Badge>
        <FaIcon name="file" style={{ margin: spaces.inline.xs }} />{' '}
        <span>
          {props.fileName.length > 25
            ? `${props.fileName.slice(0, 15)}...`
            : props.fileName}
          {` (${showMB ? mb.toFixed(2) : kb} ${showMB ? 'MB' : 'KB'})`}
        </span>
        {props.onDelete && (
          <RemoveButton variant="tertiary" onClick={props.onDelete}>
            <FaIcon name="trash" />
          </RemoveButton>
        )}
      </Badge>
    </>
  )
}

export default AttachmentBadge
