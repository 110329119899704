
import { rgba, grayscale } from 'polished'
import styled from 'styled'

const Input = styled.input`
  /* selector required because of sanatize */
  input[type='checkbox']& {
    appearance: none;
    outline: none;
    position: relative;
    box-sizing: border-box;

    width: 24px;
    height: 24px;
    background: white;
    border: 1px solid ${props => props.theme.colors['grey-70']};
    border-radius: 3px;

    &:focus:not([disabled]):not([readOnly]) {
      border: 1px solid ${props => props.theme.colors.primary};
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 3px ${props => rgba(props.theme.colors.primary, 0.6)};
    }

    &:hover:not([disabled]):not([readOnly]) {
      box-shadow: 0 0 3px ${props => rgba(props.theme.colors.primary, 0.4)};
    }

    &:checked:not([disabled]):not([readOnly]) {
      background: ${props => props.theme.colors.primary};
    }

    &:checked:disabled,
    &:checked:read-only {
      background: ${props => grayscale(props.theme.colors.primary)};
    }

    &:not(:checked):disabled,
    &:not(:checked):read-only {
      background: ${props => props.theme.colors['grey-70']};
    }

    &:checked:after {
      content: ' ';
      position: absolute;
      left: 9px;
      top: 6px;
      width:4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    & + label {
      margin: 4px 0px 0px;
      line-height: 20px;
      vertical-align: top;
    }
  }
`

export default Input
