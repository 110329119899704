import { useState, useEffect, useMemo } from 'react'
import { useTheme } from 'styled'

// Usage:
// const isWide = useMediaQuery("(min-width: 480px)");
const useMediaQuery = (query: string, defaultState: boolean = false): boolean => {
  const [state, setState] = useState<boolean>(defaultState)

  useEffect(
    () => {
      let mounted = true
      const mql = window.matchMedia(query)
      const onChange = () => {
        if (!mounted) return
        setState(!!mql.matches)
      }

      mql.addListener(onChange)
      setState(mql.matches)

      return () => {
        mounted = false
        mql.removeListener(onChange)
      }
    },
    [query]
  )

  return state
}

function useMedia() {
  const { screenSizes } = useTheme()
  const isPhone = useMediaQuery(`(max-width: ${screenSizes.phone/16}em)`)
  const isTablet = useMediaQuery(`(max-width: ${screenSizes.tablet/16}em)`)
  const isDesktop = useMediaQuery(`(max-width: ${screenSizes.desktop/16}em)`)

  const media = useMemo(() => {
    if (isPhone) {
      return 'phone'
    }
    if (isTablet) {
      return 'tablet'
    }
    if (isDesktop) {
      return 'desktop'
    }
    return 'default'
  }, [isPhone, isTablet, isDesktop])

  return {
    isPhone,
    isTablet: isTablet && !isPhone,
    isDesktop: isDesktop && !isPhone && !isTablet,
    isDefault: !isPhone && !isTablet && !isDesktop,
    media: media as typeof media // cast required here, else it is typed as string
  }
}

export default useMedia
