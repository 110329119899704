import React, { useState } from 'react'

import Button from 'components/Button'
import Dialog from 'components/Dialog'

import ClaimSummary from 'containers/ClaimSummary'

import { useClaim } from 'claimbuilder'
import styled, { useTheme } from 'styled'
import { useStepControls } from 'components/Steps'
import {
  isDosingStepDefinition,
  isArgumentsStepDefinition,
} from 'claimbuilder/types'
import { useSignatureStatus, useSignature } from 'hooks'
import { useAlert } from 'components/Alert'
import PdfDocument from './PdfDocument'
import SendingPortal from './SendingPortal'

export const SummaryStep = () => {
  const { spaces } = useTheme()
  const { goToStep } = useStepControls()

  const [noSignatureWarning, setNoSignatureWarning] = useState(false)
  const [showMailPortal, setShowMailPortal] = useState(false)

  const { data, definition } = useClaim()
  const { doctor, dispatch, patient, dosing, arguments: args } = data

  const dosingStepEnabled = !!definition.steps.find(isDosingStepDefinition)
  const argumentsStepEnabled = !!definition.steps.find(
    isArgumentsStepDefinition
  )

  // Signature
  const [sigStatus] = useSignatureStatus({ quietMode: true })
  const [loadSignature, signatureData] = useSignature()
  const handleSign = () => {
    loadSignature()
  }

  const signatureAvailable = sigStatus === 'AVAILABLE'
  const signatureSrc =
    signatureData && signatureData.src ? signatureData.src : null

  // mail
  const [mailAttachmentBlob, setMailAttachmentBlob] = useState<Blob | null>(
    null
  )
  const handleMailformCancel = () => {
    setMailAttachmentBlob(null)
    setShowMailPortal(false)
  }
  const [alert] = useAlert()
  const handleMailSuccess = () => {
    setMailAttachmentBlob(null)
    alert.success('E-Mail wurde erfolgreich versendet.')
    setShowMailPortal(false)
  }

  // document
  const pdfDoc = (
    <PdfDocument
      {...data}
      definition={definition}
      signatureSrc={signatureSrc || undefined}
      signatureWidth={
        signatureData.dimensions ? signatureData.dimensions.width : undefined
      }
      signatureHeight={
        signatureData.dimensions ? signatureData.dimensions.height : undefined
      }
    />
  )

  return (
    <>
      <ClaimSummary>
        <ClaimSummary.Heading />
        <ClaimSummary.Section name="Versand" onEdit={() => goToStep(4)}>
          <ClaimSummary.DocumentHeader
            to={dispatch.organisation}
            department={dispatch.department}
            firstAddressLine={dispatch.street}
            secondAddressLine={`${dispatch.zip} ${dispatch.city}`}
            phoneNo={dispatch.phone}
            faxNo={dispatch.fax}
            sentFromCity={doctor.city}
          />
        </ClaimSummary.Section>
        <ClaimSummary.Section name="Patient" onEdit={() => goToStep(1)}>
          <ClaimSummary.DocumentGreeting product={definition.product.name} />
          <ClaimSummary.DocumentPatientData
            name={patient.name}
            birthday={patient.birthdate}
            svn={patient.svn}
            weight={patient.weight}
            diagnose={patient.diagnose}
          />
        </ClaimSummary.Section>
        {dosingStepEnabled && (
          <ClaimSummary.Section name="Dosierung" onEdit={() => goToStep(2)}>
            <ClaimSummary.DocumentSectionTitle>
              Verbrauchsdaten:
            </ClaimSummary.DocumentSectionTitle>
            <ClaimSummary.DocumentText>
              <p>{dosing.description}</p>
              {dosing.monthDescription && <p>{dosing.monthDescription}</p>}
            </ClaimSummary.DocumentText>
          </ClaimSummary.Section>
        )}
        {argumentsStepEnabled && (
          <ClaimSummary.Section name="Begründung" onEdit={() => goToStep(3)}>
            <ClaimSummary.DocumentSectionTitle>
              {'Begründung:'}
            </ClaimSummary.DocumentSectionTitle>
            <ClaimSummary.DocumentText>
              <ul>
                {args.map(arg => (
                  <li key={arg.label} style={{ marginBottom: 10 }}>
                    {arg.label}
                    {arg.description ? ` - ${arg.description}` : ''}
                  </li>
                ))}
              </ul>
            </ClaimSummary.DocumentText>
          </ClaimSummary.Section>
        )}
        <ClaimSummary.Section name="Arzt Daten" onEdit={() => goToStep(0)}>
          <ClaimSummary.DocumentText>
            <p>Um einen positiven Bescheid wird gebeten.</p>
            <p>{'Mit freundlichen Grüßen'}</p>
            {!signatureAvailable ? (
              <NoSignatureWarning>
                {'zum Unterschreiben richten Sie Ihre Signatur im Profil ein'}
              </NoSignatureWarning>
            ) : signatureSrc ? (
              <img alt="Signatur" src={signatureSrc} />
            ) : (
              <Button
                variant="primary"
                onClick={handleSign}
                style={{ margin: spaces.stack.s }}
                disabled={!signatureAvailable}
              >
                {'Unterschreiben'}
              </Button>
            )}
            <SignatureUnderline />
            <ClaimSummary.AddressBlock
              name={doctor.name}
              companyName={doctor.organisation}
              department={doctor.department}
              firstAddressLine={doctor.street}
              secondAddressLine={`${doctor.zip} ${doctor.city}`}
              phoneNo={doctor.phone}
              faxNo={doctor.fax}
              style={{ marginBottom: 26 }}
            />
          </ClaimSummary.DocumentText>
        </ClaimSummary.Section>
        <ClaimSummary.CommitSection
          document={pdfDoc}
          documentName={`${definition.product.name}-Antrag.pdf`}
          onApplyViaMailClick={blob => {
            setMailAttachmentBlob(blob)
            signatureData.src
              ? setShowMailPortal(true)
              : setNoSignatureWarning(true)
          }}
          sickfundService={
            dispatch.isSickfundService
              ? {
                  secureEmail: dispatch.sickfundServiceSecureEmail,
                  emailAllowed: dispatch.sickfundServiceEmailAllowed,
                }
              : undefined
          }
          customMail={!dispatch.isSickfundService ? dispatch.email : undefined}
          signed={signatureData.src ? true : false}
        />
      </ClaimSummary>

      <Dialog
        open={noSignatureWarning}
        onClose={() => setNoSignatureWarning(false)}
        ariaLabelledby="no-siognatur-dialog-title"
        ariaDescribedby="no-siognatur-dialog-description"
      >
        <Dialog.Title id="no-siognatur-dialog-title">
          {'Ihr Antrag enthält keine Unterschrift'}
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText id="no-siognature-dialog-description">
            {
              'Bitte signieren Sie Ihren Antrag, um dessen Gültigkeit zu gewährleisten!'
            }
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            onClick={() => {
              setNoSignatureWarning(false)
              setShowMailPortal(true)
            }}
          >
            {'Ohne Signatur versenden'}
          </Button>
          <Button
            variant={'primary'}
            onClick={() => setNoSignatureWarning(false)}
          >
            {'Abbrechen'}
          </Button>
        </Dialog.Actions>
      </Dialog>

      {mailAttachmentBlob && showMailPortal && (
        <SendingPortal
          onSendSuccess={handleMailSuccess}
          cancelMailForm={handleMailformCancel}
          blob={mailAttachmentBlob}
          mailToAddress={dispatch.email}
        />
      )}
    </>
  )
}

const SignatureUnderline = styled.div`
  width: 300px;
  ${props => props.theme.media.phone`width: 100px;`}
  margin-bottom: 20px;
  ${props => props.theme.media.phone`margin-bottom: 8px;`}
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors['grey-10']};
  border-bottom-width: 2px;
  ${props => props.theme.media.phone`border-bottom-width: 1px;`}
`

const NoSignatureWarning = styled.div`
  color: ${props => props.theme.colors.warning};
  font-weight: 600;
  font-size: 15px;
  margin: ${props => props.theme.spaces.stack.m};
  margin-top: ${props => props.theme.spaces.m};
`
