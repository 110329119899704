import styled from 'styled'
import { transparentize } from 'polished'

import Button from '../../components/Button'

const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.theme.spaces.insetSquish.m};
  :not(:last-child) {
    margin: ${props => props.theme.spaces.inline.m};
    margin-bottom: ${props => props.theme.spaces.s};
  }
  margin-bottom: ${props => props.theme.spaces.s};
  box-shadow: 0 1px 3px
      ${props => transparentize(0.9, props.theme.colors['grey-40'])},
    0 1px 2px ${props => transparentize(0.85, props.theme.colors['grey-30'])};

  border-radius: ${props => props.theme.radius.s};
  border: 0;
  background: #fff;
`

const RemoveButton = styled(Button)`
  padding: 0;
  margin-left: ${props => props.theme.spaces.m};
  :hover {
    color: ${props => props.theme.colors.danger};
  }
`

export { Badge, RemoveButton }
