import React, { ReactNode } from 'react'
import styled, { useTheme } from 'styled'
import Seperator from 'components/Seperator'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'left center right';
`

const Left = styled.div`
  grid-area: left;
`

const Center = styled.div`
  grid-area: center;
  justify-self: center;
`

const Right = styled.div`
  grid-area: right;
  justify-self: end;
`

type FormFooterProps = {
  children: ReactNode
}

function FormFooter({ children }: FormFooterProps) {
  const { spaces } = useTheme()
  return (
    <>
      <Seperator style={{ margin: spaces.stack.l }} />
      <Grid>
        {children}
      </Grid>
    </>
  )
}

FormFooter.Left = Left
FormFooter.Center = Center
FormFooter.Right = Right

export default FormFooter
