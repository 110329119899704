import React, { useEffect, CSSProperties } from 'react'
import styled, { useTheme } from 'styled'
import { navigate } from '@reach/router'

import * as API from 'api'
import { useApi } from 'hooks'
import FormDescription from 'components/FormDescription'
import FormGrid from 'components/FormGrid'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import Link from 'components/HeaderMenuLink'
import { Sickfund } from 'types'
import { getSickfundEditRoute } from 'pages/routes'

export function SickfundsAdminstration() {
  return (
    <FormGrid>
      <FormDescription>
        <FormDescription.Heading>Krankenkassen</FormDescription.Heading>
        <FormDescription.Content>
          {'Administration der Krankenkassen'}
        </FormDescription.Content>
      </FormDescription>

      <div>
        <SickfundsList />
        <Button onClick={() => navigate(getSickfundEditRoute('new'))} variant="primary">New</Button>
      </div>
    </FormGrid>
  )
}

const Table = styled.table`
  width: 100%;

  & th {
    text-align: left;
  }

  & td {
    padding: 0px 8px;
  }
`

function SickfundsList() {
  const [response, getSickfunds] = useApi(API.getSickfunds)
  const sickfunds = response.data
  useEffect(() => {
    getSickfunds()
  }, [getSickfunds])

  if (response.loading) {
    return <Spinner />
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Kasse</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {(sickfunds || []).map((sickfund, idx) => (
          <SickfundRow sickfund={sickfund} index={idx} key={sickfund.id} />
        ))}
      </tbody>
    </Table>
  )
}

interface SickfundRowProps {
  sickfund: Sickfund
  index: number
}

function SickfundRow({ sickfund, index }: SickfundRowProps) {
  const { colors } = useTheme()
  const style: CSSProperties = {}
  if (index % 2) {
    style.background = colors['grey-90']
  }

  return (
    <tr style={style}>
      <td>{sickfund.name}</td>
      <td>
        <Link to={getSickfundEditRoute(sickfund.id)}>Edit</Link>
      </td>
    </tr>
  )
}

export default SickfundsAdminstration
