import React, { useState, useMemo, useEffect, useRef } from 'react'

import * as API from 'api'
import { useTheme, createGlobalStyle } from 'styled'
import { useFormFields, useFileSelect, useApi } from 'hooks'
import { AddressFields } from 'types'
import useUserInfo from 'auth/useUserInfo'
import useUserGroups from 'auth/useUserGroups'

import { AlertBox, useAlert, AlertProvider, AlertFeed } from 'components/Alert'
import Portal from 'components/Portal'
import Button from 'components/Button'
import TextArea from 'components/TextArea'
import Input from 'components/Input'
import PageHero from 'components/PageHero'
import FaIcon from 'components/FaIcon'
import AttachmentBadge from 'components/AttachmentBadge'
import FormGrid from 'components/FormGrid'
import FormDescription from 'components/FormDescription'
import Checkbox from 'components/Checkbox'
import Container from 'layout/DefaultPageLayout/Container'

import {
  Background,
  MailHeader,
  MailInfos,
  MailInfoLabel,
  CancelButton,
  AttachmentArea,
  Attachments as AttachmentList,
  GivenAttachmentsGrid,
  GivenAttachmentLink,
  GivenAttachmentsLabel,
  Mail,
} from 'containers/SendingPortal/styled-sending-portal'
import { useClaim } from 'claimbuilder/ClaimContext'
import { GivenAttachments, Attachment } from 'claimbuilder/types'

type SendingPortalProps = {
  cancelMailForm: () => void
  onSendSuccess: () => void
  blob: Blob

  mailToAddress: string
}

const getMessage = (
  doctorMail: string,
  product: string,
  address: AddressFields
) => `Sehr geehrte Damen und Herren, 

anbei der Antrag für die Erstattung von ${product}.
Um einen positiven Bescheid wird gebeten.


Mit freundlichen Grüßen

${address.name}${
  address.companyName
    ? `
${address.companyName}`
    : ''
}${
  address.department
    ? `
${address.department}`
    : ''
}
${address.firstAddressLine}
${address.secondAddressLine}

Mail: ${doctorMail}${
  address.phoneNo
    ? `
Tel: ${address.phoneNo}`
    : ''
}${
  address.faxNo
    ? `
Fax: ${address.faxNo}`
    : ''
}

`

const DisableBodyOverflow = createGlobalStyle`
  body {
    overflow: hidden;
    height: 100vh;
  }
  html {
    overflow: hidden;
  }
`

const blobToFile = (theBlob: Blob, fileName: string): File => {
  var b: any = theBlob
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date()
  b.name = fileName

  return theBlob as File
}

const getFileSizeInMB = (files: File[]) => {
  return (
    Math.ceil(
      files.reduce((acc, file) => {
        return acc + file.size
      }, 0) / 1000 /* KB */
    ) / 1000
  ) /* MB */
}

function SendingPortal(props: SendingPortalProps) {
  const userInfo = useUserInfo()
  const { isDoctor } = useUserGroups()
  const doctorMail = userInfo
    ? userInfo.attributes.email
    : 'Fehler! Keine E-Mail Adresse verfügbar.'
  const { spaces } = useTheme()
  const [files, setFiles] = useState<File[]>([])

  const { data, definition } = useClaim()
  const { doctor } = data

  const senderAddress: AddressFields = {
    name: doctor.name,
    companyName: doctor.organisation,
    department: doctor.department,
    firstAddressLine: doctor.street,
    secondAddressLine: `${doctor.zip} ${doctor.city}`,
    phoneNo: doctor.phone,
    faxNo: doctor.fax,
  }

  const productName = definition.product.name
  const givenAttachments = definition.givenAttachments
  const claimDocument = useMemo(
    () => blobToFile(props.blob, `${productName}-Antrag.pdf`),
    [props.blob, productName]
  )

  const { fields, values, setValue } = useFormFields({
    subject: `Antrag auf Erstattung von ${productName}`,
    message: getMessage(doctorMail, productName, senderAddress),
    selfBcc: true,
  })

  // TODO: email verified check
  const sendingEnabled = isDoctor

  const [{ data: sendMailResponse, loading, error }, sendMail] = useApi(
    API.sendMail
  )
  useEffect(() => {
    if (sendMailResponse && sendMailResponse.status === 'OK') {
      props.onSendSuccess()
    }
  }, [props, sendMailResponse])

  useEffect(() => {
    if (error) {
    }
  }, [error])

  const handleSendMail = () => {
    // TODO: check attachment max size!!!
    sendMail({
      to: props.mailToAddress,
      from: doctorMail,
      selfBcc: values.selfBcc,
      subject: values.subject,
      content: values.message,
      files: [claimDocument, ...files],
    })
  }

  return (
    <AlertProvider>
      <Portal>
        <>
          <DisableBodyOverflow />
          <Background>
            <Container
              style={{ paddingTop: spaces.xl, paddingBottom: spaces.xl }}
            >
              <PageHero>
                <PageHero.Title>E-Mail</PageHero.Title>
                <CancelButton onClick={props.cancelMailForm}>
                  <FaIcon name="times" />
                </CancelButton>
              </PageHero>

              <div style={{ margin: spaces.stack.xl }}>
                {error && (
                  <AlertBox type="error" style={{ margin: spaces.stack.m }}>
                    Leider ist beim senden der E-Mail ein Fehler aufgetreten.
                  </AlertBox>
                )}
                <AlertFeed alertStyle={{ margin: spaces.stack.m }} />
              </div>
              <FormGrid>
                <FormDescription>
                  <FormDescription.Content>
                    <p style={{ margin: spaces.stack.l }}>
                      {`Hier können Sie mittels Ihrer E-Mail Adresse den
                      Antrag TLS-verschlüsselt an die Krankenkasse übermitteln. Bitte
                      editieren sie gegebenenfalls den Betreff und den
                      Mail-Text, und fügen Sie allfällige Dokumente
                      wie zum Beispiel Befunde an.`}
                    </p>
                    <p style={{ margin: spaces.stack.xxl }}>
                      <strong>{`WICHTIG:`}</strong>{' '}
                      {`Aus Datenschutz Gründen empfehlen
                  wir Ihnen dringend, hier keinerlei personenbezogene Daten wie
                  Name oder Diagnose Ihres Patienten anzugeben. Dies gilt im
                  Speziellen für den Betreff, den Mail-Text, sowie für die
                  Datei-Namen der angefügten Dokumente.`}
                    </p>
                  </FormDescription.Content>
                </FormDescription>
                <div>
                  <MailHeader>
                    <Button
                      variant="primary"
                      style={{ fontSize: '14px', padding: spaces.inset.xxs }}
                      disabled={!sendingEnabled || loading}
                      onClick={handleSendMail}
                    >
                      <FaIcon
                        name="paper-plane"
                        size="2x"
                        style={{ margin: spaces.stack.m }}
                      />
                      Senden
                    </Button>
                    <MailInfos>
                      <MailInfoLabel>Von:</MailInfoLabel>
                      <Mail>{doctorMail}</Mail>

                      <MailInfoLabel>An:</MailInfoLabel>
                      <Mail>{props.mailToAddress}</Mail>

                      <MailInfoLabel>Bcc:</MailInfoLabel>
                      <Mail>
                        <div style={{ margin: `0px ${spaces.s} -4px -4px` }}>
                          <Checkbox
                            style={{ margin: spaces.inline.m }}
                            {...fields.selfBcc}
                            checked={values.selfBcc}
                            onChange={evt => {
                              setValue('selfBcc', evt.currentTarget.checked)
                            }}
                          />
                        </div>
                        <div style={{ alignSelf: 'center' }}>
                          Kopie an mich senden
                        </div>
                      </Mail>

                      <MailInfoLabel>Betreff:</MailInfoLabel>
                      <Input type="text" {...fields.subject} />
                    </MailInfos>
                  </MailHeader>
                  <Attachments
                    claim={claimDocument}
                    files={files}
                    givenAttachments={givenAttachments}
                    onAddFile={files =>
                      setFiles(prevFiles => [...prevFiles, ...files])
                    }
                    onDeleteFile={file =>
                      setFiles(prevFiles => prevFiles.filter(f => f !== file))
                    }
                  />

                  <TextArea
                    style={{ padding: spaces.insetSquish.xl, height: '500px' }}
                    {...fields.message}
                  />
                </div>
              </FormGrid>
            </Container>
          </Background>
        </>
      </Portal>
    </AlertProvider>
  )
}

function Attachments(props: {
  claim: File
  files: File[]
  givenAttachments: GivenAttachments | undefined
  onAddFile: (files: File[]) => void
  onDeleteFile: (file: File) => void
}) {
  const { spaces, colors } = useTheme()
  const [selectFile] = useFileSelect({ multiple: true })
  const [alert] = useAlert()

  const addFiles = (files: File[]) => {
    const accumulatedFileSize = getFileSizeInMB(
      props.files.concat(files).concat([props.claim])
    )
    if (accumulatedFileSize <= 10) {
      props.onAddFile(files)
    } else {
      alert.error(
        'Datei konnte nicht hinzugefügt werden. Angefügte Dateien dürfen in Summe 10MB nicht überschreiten.',
        { autoHide: false }
      )
    }
  }

  const handleFileSelect = async () => {
    const newFiles = await selectFile()
    addFiles(Array.from(newFiles))
  }

  // const handleGivenAttachmentChange = () => {
  //   const checked = evt.currentTarget.checked
  // }

  const attachmentRef = useRef<{ [src: string]: File }>({})
  const addGivenAttachment = async (attachment: Attachment) => {
    if (!attachmentRef.current[attachment.fileSrc]) {
      const result = await fetch(attachment.fileSrc)
      if (result.ok) {
        const blob: any = await result.blob()
        blob.name = attachment.label
        blob.lastModifiedDate = new Date()
        attachmentRef.current[attachment.fileSrc] = blob as File
      } else {
        alert.error(
          'Die Datei konnte leider nicht hinzugefügt werden. Kontaktieren Sie bitte den Administrator.'
        )
        return
      }
    }
    addFiles([attachmentRef.current[attachment.fileSrc]])
  }

  const removeGivenAttachment = (attachment: Attachment) => {
    props.onDeleteFile(attachmentRef.current[attachment.fileSrc])
  }

  return (
    <AttachmentArea>
      <Button
        variant="primary"
        style={{
          fontSize: '14px',
          alignSelf: 'start',
          marginBottom: spaces.s,
          width: '100%',
        }}
        onClick={handleFileSelect}
      >
        <Button.Icon name="paperclip" />
        <span>{'Datei anfügen'}</span>
      </Button>

      <AttachmentList>
        <AttachmentBadge fileName={props.claim.name} size={props.claim.size} />
        {props.files.map((file, idx) => (
          <AttachmentBadge
            fileName={file.name}
            size={file.size}
            key={`attachment-${idx}`}
            onDelete={() => props.onDeleteFile(file)}
          />
        ))}
        {props.files.length === 0 && (
          <div
            style={{
              color: colors.warning,
              padding: `${spaces.s} 0px `,
              marginBottom: spaces.s,
            }}
          >
            {'Bitte fügen Sie einen Befund an!'}
          </div>
        )}
      </AttachmentList>
      {props.givenAttachments && (
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <GivenAttachmentsLabel>
            {props.givenAttachments.sendingPortalLabel}
          </GivenAttachmentsLabel>
          {props.givenAttachments.attachments &&
            props.givenAttachments.attachments.map(attachment => (
              <GivenAttachmentsGrid key={attachment.label}>
                <Checkbox
                  name={attachment.label}
                  checked={
                    props.files.indexOf(
                      attachmentRef.current[attachment.fileSrc]
                    ) >= 0
                  }
                  onChange={({ currentTarget: { checked } }) =>
                    checked
                      ? addGivenAttachment(attachment)
                      : removeGivenAttachment(attachment)
                  }
                />
                <GivenAttachmentLink href={attachment.fileSrc} target="_blank">
                  {attachment.label}
                </GivenAttachmentLink>
              </GivenAttachmentsGrid>
            ))}
        </div>
      )}
    </AttachmentArea>
  )
}

export default SendingPortal
