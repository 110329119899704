import styled from 'styled'

const Heading = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors["grey-50"]};
  margin: ${props => props.theme.spaces.stack.m};
`

export default Heading
