import React, { SyntheticEvent } from 'react'
import { navigate } from '@reach/router'

import FormFooter from 'components/FormFooter'
import Button from 'components/Button'
import { useStepControls } from 'components/Steps'
import { useMedia } from 'hooks'
import wrapEvent from 'utils/wrap-event'

interface ClaimStepFooterProps {
  onCommit?: React.EventHandler<SyntheticEvent<HTMLButtonElement>>
  disableNext?: boolean
  disablePrev?: boolean
}

export const ClaimStepFooter: React.FC<ClaimStepFooterProps> = props => {
  const { isPhone } = useMedia()
  const { nextStep, prevStep } = useStepControls()

  const handleNext = wrapEvent(props.onCommit, () => {
    nextStep()
  })

  return (
    <FormFooter>
      <FormFooter.Left>
        {!props.disablePrev && (
          <Button variant="secondary" onClick={prevStep}>
            <Button.Icon name="arrow-alt-left" />
            {!isPhone ? <span>{'Zurück'}</span> : undefined}
          </Button>
        )}
      </FormFooter.Left>
      <FormFooter.Center>
        <Button onClick={() => navigate('/')}>
          {!isPhone ? <span>Abbrechen</span> : <Button.Icon name="times" />}
        </Button>
      </FormFooter.Center>
      <FormFooter.Right>
        {!props.disableNext && (
          <Button onClick={handleNext} variant="primary">
            {!isPhone ? <span>Weiter</span> : undefined}
            <Button.Icon name="arrow-alt-right" />
          </Button>
        )}
      </FormFooter.Right>
    </FormFooter>
  )
}
