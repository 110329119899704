import styled from 'styled'

const Seperator = styled.div`
  height: 1px;
  background-color: ${props => props.theme.colors["grey-80"]};
  width: 100%;
  margin: ${props => props.theme.spaces.stack.xl};
`

export default Seperator
