import React, { ReactNode } from 'react'
import Seperator from 'components/Seperator'

import TitleRow from './TitleRow'

import ProductArea from './ProductArea'
import PharmaArea from './PharmaArea'
import Icon from './Icon'
import Title from './Title'
import Logo from './Logo'
import SupportText from './SupportText'

type PageTitleProps = {
  children: ReactNode
}

function PageTitle(props: PageTitleProps) {
  return (
    <>
      <TitleRow>{props.children}</TitleRow>
      <Seperator />
    </>
  )
}

PageTitle.ProductArea = ProductArea
PageTitle.PharmaArea = PharmaArea
PageTitle.Icon = Icon
PageTitle.Title = Title
PageTitle.Logo = Logo
PageTitle.SupportText = SupportText

export default PageTitle
