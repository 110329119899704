import styled from 'styled'

import Input from 'components/Input'
import { darken } from 'polished'

const LogInGrid = styled.div`
  border: 1px solid ${props => props.theme.colors['grey-70']};
  border-radius: ${props => props.theme.radius.s};
  background: #f8fafc;
  width: 300px;
  margin: 20px auto;
  padding: ${props => props.theme.spaces.insetStretch.xl};
`

const LogInInput = styled(Input)`
  margin: ${props => props.theme.spaces.stack.l};
`

const LogInSubText = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: ${props => props.theme.colors['grey-40']};
`

const ForgotPwLink = styled.a`
  font-size: 12px;
  font-weight: 500;
  padding-left: ${props => props.theme.spaces.xs};
  text-decoration: none;

  color: ${props => props.theme.colors.primary};
  :hover {
    color: ${props => darken(0.07, props.theme.colors.primary)};
  }
`

const LogInDescriptionText = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors['grey-40']};
`

export {
  LogInGrid,
  LogInSubText,
  LogInInput,
  LogInDescriptionText,
  ForgotPwLink,
}
