import {
  ClaimDefinition,
  StepDefinitionTypes,
  PharmaCompany,
  DosingFrequencyUnit,
  ClaimClearance,
} from 'claimbuilder'

import infoDoc from './assets/F_FI_18-07-26_HyQvia.pdf'
import logo from './assets/hyqvia.png'

export const HyQviaClaimDefinition: ClaimDefinition = {
  product: {
    name: 'HyQvia',
    informationDocument: infoDoc,
    substances: ['normales Immunglobulin vom Menschen 10%'],
    logo,
  },
  clearance: ClaimClearance.APPROVED,
  owner: PharmaCompany.TAKEDA,
  steps: [
    {
      type: StepDefinitionTypes.DOCTOR,
    },
    {
      type: StepDefinitionTypes.PATIENT,
      diagnoseOptions: [
        {
          label: 'PID',
          value:
            'PID: Primärem Immundefektsyndrom mit unzureichender Antikörperbildung.',
        },
        {
          label: 'SID bei CLL',
          value:
            'SID bei CLL: Hypogammaglobulinämie und rezidivierenden bakteriellen Infektionen bei Patienten mit chronischer lymphatischer Leukämie (CLL), bei denen eine prophylaktische Antibiotikatherapie fehlgeschlagen oder kontraindiziert ist.',
        },
        {
          label: 'SID bei MM',
          value:
            'SID bei MM: Hypogammaglobulinämie und rezidivierenden bakteriellen Infektionen bei Patienten mit multiplem Myelom.',
        },
        {
          label: 'HSCT',
          value:
            'HSCT: Hypogammaglobulinämie bei Patienten vor und nach allogener hämatopoetischer Stammzelltransplantation (HSCT)',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DOSING,
      unit: 'Gramm',
      frequencyUnit: DosingFrequencyUnit.WEEK,
      frequencies: [
        {
          label: 'jede Woche',
          value: 1,
        },
        {
          label: 'alle 2 Wochen',
          value: 2,
        },
        {
          label: 'alle 3 Wochen',
          value: 3,
        },
        {
          label: 'alle 4 Wochen',
          value: 4,
        },
        {
          label: 'sonstiges',
          value: 'CUSTOM',
        },
      ],
      dosings: [
        {
          value: 2.5,
        },
        {
          value: 5,
        },
        {
          value: 7.5,
        },
        {
          value: 10,
        },
        {
          value: 12.5,
        },
        {
          value: 15,
        },
        {
          value: 17.5,
        },
        {
          value: 20,
        },
        {
          value: 22.5,
        },
        {
          value: 25,
        },
        {
          value: 27.5,
        },
        {
          value: 30,
        },
        {
          value: 32.5,
        },
        {
          value: 35,
        },
        {
          value: 37.5,
        },
        {
          value: 40,
        },
        {
          value: 42.5,
        },
        {
          value: 45,
        },
        {
          value: 47.5,
        },
        {
          value: 50,
        },
        {
          value: 52.5,
        },
        {
          value: 55,
        },
        {
          value: 57.5,
        },
        {
          value: 60,
        },
        {
          value: 62.5,
        },
        {
          value: 65,
        },
        {
          value: 67.5,
        },
        {
          value: 70,
        },
        {
          value: 72.5,
        },
        {
          value: 75,
        },
        {
          value: 77.5,
        },
        {
          value: 80,
        },
        {
          value: 82.5,
        },
        {
          value: 85,
        },
        {
          value: 87.5,
        },
        {
          value: 90,
        },
        {
          value: 92.5,
        },
        {
          value: 95,
        },
        {
          value: 97.5,
        },
        {
          value: 100,
        },
      ],
    },
    {
      type: StepDefinitionTypes.ARGUMENTS,
      arguments: [
        {
          label: 'Hochdosispatient',
          description: '',
        },
        {
          label: 'Hohe IgG-Talspiegel nötig',
          description: '',
        },
        {
          label: 'Pädiatrischer Patient',
          description:
            'wöchentliche Applikation, lange Infusionsdauer, viele Nadelstiche nicht zumutbar',
        },
        {
          label: 'Patient mit besonderen Bedürfnissen',
          description:
            'wöchentliche Applikation, lange Infusionsdauer, viele Nadelstiche nicht zumutbar',
        },
        {
          label: 'Patient mit motorischen Einschränkungen',
          description: 'wöchentliche Applikation nicht zumutbar',
        },
        {
          label: 'Mangelnde Verträglichkeit von IVIG',
          description:
            'iv-Immunglobulin-Präparat verursacht signifikante systemische Nebenwirkungen',
        },
        {
          label:
            'Mangelnde Verträglichkeit von bisherigem SCIG; signifikante Nebenwirkungen unter bisherigem SCIG',
          description: '',
        },
        {
          label:
            'Patient mit Anwendungsproblemen unter bisherigem iv-Immunglobulin-Präparat',
          description:
            'viele Fehltage in Schule/Arbeit wegen Krankenhausbesuchen, erhöhte Infektanfälligkeit bedingt durch Krankenhausbesuche',
        },
        {
          label:
            'Nachweisbarer Masern-Antikörper-Titer in Takeda IG-Präparaten',
          description: '(M. Farcet Vaccine 2019 ; 33: 3151–3153)',
        },
        {
          label: 'Mangelnde Infektkontrolle unter bisherigem SCIG',
          description: 'hoher Antibiotikaeinsatz',
        },
      ],
    },
    {
      type: StepDefinitionTypes.DISPATCH,
    },
    {
      type: StepDefinitionTypes.SUMMARY,
    },
  ],
}

export default HyQviaClaimDefinition
