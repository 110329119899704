import { createGlobalStyle } from 'styled'

const AppStyles = createGlobalStyle`

    html, body {
        padding: 0;
        margin: 0;
        min-height: 100vh;
        overflow: auto;
        /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
        font-family: ${props => props.theme.fonts.default};
        font-size: 14px;
    }

    #root {
      position: absolute;
      min-width: 100%;
      min-height: 100vh;
    }

    * {
        box-sizing: border-box;
        outline: none;
    }

    html {
        color: ${props => props.theme.colors.color};
    }

    a, a:visited {
      color: ${props => props.theme.colors.primary};
    }
`

export default AppStyles
