import styled from 'styled'

export default styled.ul`
  display: flex;
  align-content: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 45px;
  background-color: transparent;
  padding: 0;
  width: 100%;
  margin: ${props => props.theme.spaces.stack.l};
`
