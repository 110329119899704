import React, { useEffect, useState, useMemo, useCallback } from 'react'

import PageHero from 'components/PageHero'
import Steps from 'components/Steps'
import { useMedia } from 'hooks'

import {
  ClaimDefinition,
  isDoctorStepDefinition,
  isPatientStepDefinition,
  isDispatchStepDefinition,
  isSummaryStepDefinition,
  PharmaCompany,
  StepDefinitionTypes,
} from './types'
import { ClaimStepTitle, ClaimStepPanes } from './steps'
import { ClaimProvider, getInitialClaimData, useClaim, CustomStepRegistryItem } from './ClaimContext'
import PrescribingInfo from 'containers/PrescribingInfo'

interface ClaimBuilderProps {
  definition: ClaimDefinition
}

export const ClaimBuilder: React.FC<ClaimBuilderProps> = props => {
  useClaimDefValidation(props.definition)
  const [data, setData] = useState(getInitialClaimData())
  const [customSteps, setCustomSteps] = useState<CustomStepRegistryItem[]>([])

  const registerCustomStep = useCallback((step: CustomStepRegistryItem) => {
    setCustomSteps(prev => [...prev, step])
  }, [setCustomSteps])

  const unregisterCustomStep = useCallback((type: StepDefinitionTypes) => {
    setCustomSteps(prev => prev.filter(def => def.type !== type))
  }, [setCustomSteps])

  const contextValue = useMemo(() => ({
    definition: props.definition,
    data,
    setData,
    customSteps,
    registerCustomStep, 
    unregisterCustomStep,
  }), [customSteps, data, props.definition, registerCustomStep, unregisterCustomStep])

  return (
    <ClaimProvider value={contextValue}>
      <Steps>
        <ClaimStepList {...props} />
        <ProductHeader {...props} />
        <ClaimStepPanes />
        {props.children}
      </Steps>
    </ClaimProvider>
  )
}

const ClaimStepList: React.FC<ClaimBuilderProps> = ({ definition }) => {
  return (
    <Steps.StepList>
      {definition.steps.map((step, idx) => (
        <Steps.Step key={idx}>
          <ClaimStepTitle step={step} />
        </Steps.Step>
      ))}
    </Steps.StepList>
  )
}

const ProductHeader: React.FC<ClaimBuilderProps> = ({
  definition: { product },
}) => {
  const { isPhone } = useMedia()
  const logoSrc = useLogoSrc()
  return (
    <PageHero>
      <PageHero.ProductArea>
        <PageHero.Title>{product.name}</PageHero.Title>
        <PrescribingInfo pdfLink={product.informationDocument} />
      </PageHero.ProductArea>

      <PageHero.PharmaArea>
        {isPhone && <PageHero.Logo src={logoSrc} />}
        <PageHero.SupportText>
          {'mit freundlicher Unterstützung'}
        </PageHero.SupportText>
        {!isPhone && <PageHero.Logo src={logoSrc} />}
      </PageHero.PharmaArea>
    </PageHero>
  )
}

const useLogoSrc = (): string => {
  const { definition } = useClaim()
  return useCompanyLogo(definition.owner)
}

export const useCompanyLogo = (company: PharmaCompany): string => {
  switch (company) {
    case PharmaCompany.DEMO:
      return '/images/logos/DemoLogo.png'
    case PharmaCompany.TAKEDA:
      return '/images/logos/Takeda.png'
    case PharmaCompany.SIGMA_PHARM:
      return '/images/logos/Sigmapharm.png'
    default:
      return ''
  }

}

const useClaimDefValidation = (def: ClaimDefinition) => {
  useEffect(() => {
    if (!def.steps) {
      throw new Error('No steps in claim definition provided')
    }

    if (!isDoctorStepDefinition(def.steps[0])) {
      throw new Error('First step in definitino has to be a doctor definition')
    }

    if (!isPatientStepDefinition(def.steps[1])) {
      throw new Error('Second step has to be a patient definition')
    }

    if (!isDispatchStepDefinition(def.steps[def.steps.length - 2])) {
      throw new Error('Second to last step has to be a dispatch definition')
    }

    if (!isSummaryStepDefinition(def.steps[def.steps.length - 1])) {
      throw new Error('last step has to be a summary definition')
    }
  }, [def])
}
