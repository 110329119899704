import styled from 'styled'

export const Argument = styled.div`
  justify-self: start;
  width: 100%;
`

export const ArgumentHeadline = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors['grey-40']};
  padding-top: 4px;
  margin: ${props => props.theme.spaces.stack.xxs};
`

export const ArgumentGrid = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: ${props => props.theme.spaces.m};
  grid-row-gap: ${props => props.theme.spaces.m};
  justify-items: end;
`

export const ArgumentSubline = styled.span`
  font-size: 15px;
  color: ${props => props.theme.colors['grey-10']};
`
