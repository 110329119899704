import styled from 'styled'

import inputStyle from 'components/Input/styled-input'

export const Wrapper = styled.div`
  position: relative;
  border-radius: ${props => props.theme.radius.s};
  overflow: hidden;

  :after {
    background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="${props =>
      escape(
        props.theme.colors['grey-50']
      )}" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    pointer-events: none;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 43px;
  }
  
  :before {
    pointer-events: none;
    content: ' ';
    background: ${props => props.theme.colors['grey-90']};
    border: 1px solid ${props => props.theme.colors['grey-70']};
    border-top-right-radius: ${props => props.theme.radius.s};
    border-bottom-right-radius: ${props => props.theme.radius.s};
    position: absolute;
    top: 0;
    right: 0;
    height: 95%;
    width: 41px;
  }
`

// ToDo: invalid grey color setting
export const StyledSelect = styled.select`
  ${inputStyle}
  padding-top: 6px;
  :invalid {
    color: green;
  }
`
