import React from 'react'
import { RouteComponentProps } from '@reach/router'
import Obfuscate from 'react-obfuscate'
import { useTheme } from 'styled'

import PageHero from 'components/PageHero'

import DefaultPageLayout from 'layout/DefaultPageLayout'

const Disclosure = (props: RouteComponentProps) => {
  const { spaces, colors } = useTheme()
  return (
    <DefaultPageLayout>
      <PageHero>
        <PageHero.Title>Impressum</PageHero.Title>
      </PageHero>
      <div style={{ color: colors['grey-30'] }}>
        <div style={{ margin: spaces.stack.xs }}>
          Informationspflicht laut &sect;5 E-Commerce Gesetz, &sect;14
          Unternehmensgesetzbuch, &sect;63 Gewerbeordnung und
          Offenlegungspflicht laut &sect;25 Mediengesetz.
        </div>
        <div style={{ margin: spaces.stack.l }}>Version vom 16.03.2021</div>

        <div style={{ fontSize: '15px' }}>
          <div style={{ margin: spaces.stack.xxs }}>
            <b>do.vision GmbH</b>
          </div>
          <div style={{ margin: spaces.stack.xxs }}>
            Herrengasse 6-8/7/2A
          </div>
          <div style={{ margin: spaces.stack.xxs }}>1010 Wien</div>
          <div style={{ margin: spaces.stack.l }}>&Ouml;sterreich</div>

          <div style={{ margin: spaces.stack.xs }}>
            <b>Unternehmensgegenstand:</b> Software Entwicklung
          </div>
          <div style={{ margin: spaces.stack.l }}>
            <b>UID-Nummer:</b> ATU76524548
          </div>
          <div style={{ margin: spaces.stack.l }}>
            <b>Firmenbuch:</b> FN 548435y
          </div>

          <div style={{ margin: spaces.stack.xs }}>
            <b>Tel.: </b>
            <Obfuscate
              tel="+43 (0)660 766 85 08"
              style={{ textDecoration: 'none', color: colors['grey-30'] }}
            />
          </div>
          <div style={{ margin: spaces.stack.l }}>
            <b>E-Mail: </b>
            <Obfuscate
              email="office@chefarztantrag.at"
              style={{ textDecoration: 'none', color: colors['grey-30'] }}
            />
          </div>

          <div style={{ margin: spaces.stack.xs }}>
            <b>Mitglied bei:</b> WKO
          </div>
          <div style={{ margin: spaces.stack.xs }}>
            <b>Berufsrecht:</b> Gewerbeordnung:{' '}
            <a
              style={{ color: colors['grey-30'] }}
              href="https://www.ris.bka.gv.at/"
            >
              www.ris.bka.gv.at
            </a>
          </div>
          <div style={{ margin: spaces.stack.xs }}>
            <b>Aufsichtsbehörde/Gewerbebehörde:</b> Magistratisches Bezirksamt
            1. Bezirk
          </div>
          <div style={{ margin: spaces.stack.xs }}>
            <b>Berufsbezeichnung:</b> Softwareentwicklung
          </div>
          <div style={{ margin: spaces.stack.xl }}>
            <b>Verleihungsstaat:</b> &Ouml;sterreich
          </div>
        </div>

        <h2>EU-Streitschlichtung</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Gem&auml;&szlig; Verordnung &uuml;ber Online-Streitbeilegung in
          Verbraucherangelegenheiten (ODR-Verordnung) m&ouml;chten wir Sie
          &uuml;ber die Online-Streitbeilegungsplattform (OS-Plattform)
          informieren. Verbraucher haben die M&ouml;glichkeit, Beschwerden an
          die Online Streitbeilegungsplattform der Europ&auml;ischen Kommission
          unter{' '}
          <a
            style={{ color: colors['grey-30'] }}
            href="https://ec.europa.eu/odr?tid=221088969"
          >
            https://ec.europa.eu/odr?tid=221088969
          </a>{' '}
          zu richten. Die daf&uuml;r notwendigen Kontaktdaten finden Sie
          oberhalb in unserem Impressum.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Wir m&ouml;chten Sie jedoch darauf hinweisen, dass wir nicht bereit
          oder verpflichtet sind, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h2>Haftung für Inhalte dieser Webseite</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Wir entwickeln die Inhalte dieser Webseite st&auml;ndig weiter und
          bem&uuml;hen uns korrekte und aktuelle Informationen bereitzustellen.
          Leider k&ouml;nnen wir keine Haftung f&uuml;r die Korrektheit aller
          Inhalte auf dieser Webseite &uuml;bernehmen, speziell f&uuml;r jene
          die seitens Dritter bereitgestellt wurden.{' '}
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
          bitten wir Sie uns umgehend zu kontaktieren, Sie finden die
          Kontaktdaten im Impressum.{' '}
        </p>
        <h2>Haftung für Links auf dieser Webseite</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Unsere Webseite enth&auml;lt Links zu anderen Webseiten f&uuml;r deren
          Inhalt wir nicht verantwortlich sind. Haftung f&uuml;r verlinkte
          Websites besteht laut &sect; 17 ECG f&uuml;r uns nicht, da wir keine
          Kenntnis rechtswidriger T&auml;tigkeiten hatten und haben, uns solche
          Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links
          sofort entfernen w&uuml;rden, wenn uns Rechtswidrigkeiten bekannt
          werden.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten
          wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
        </p>
        <h2>Urheberrechtshinweis</h2>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
          unterliegen dem Urheberrecht. Falls notwendig, werden wir die
          unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
          verfolgen.
        </p>

        <div
          style={{
            margin: spaces.stack.xxl,
          }}
        >
          Quelle: Erstellt mit dem Impressum Generator von{' '}
          <a
            style={{ color: colors['grey-30'] }}
            href="https://www.firmenwebseiten.at/"
          >
            firmenwebseiten.at
          </a>{' '}
          in Kooperation mit{' '}
          <a
            style={{ color: colors['grey-30'] }}
            href="https://www.kinderausflug.at/"
          >
            kinderausflug.at
          </a>
          .
        </div>
        <div />
      </div>
    </DefaultPageLayout>
  )
}

export default Disclosure
