import { useEffect } from 'react'

const defaultOptions: ScrollToOptions = {
  top: 0,
  left: 0,
  behavior: 'smooth' 
}

const useScrollTo = (options: ScrollToOptions = {}) => {
  options = { ...defaultOptions, ...options}
  useEffect(() => {
    try {
      window.scroll(options)
    } catch {
      window.scrollTo(options)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)])
}

export default useScrollTo
