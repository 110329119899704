export const requiredError = (fieldName: string) =>
  `${fieldName} ist ein verpflichtendes Feld.`

export const tooShortError = (fieldName: string, min: number) =>
  `${fieldName} muss mindestens ${min} Zeichen beinhalten.`

export const tooLongError = (fieldName: string, max: number) =>
  `${fieldName} darf maximal ${max} Zeichen beinhalten.`

export const emailError = () =>
  `Das Format der eingegebene Email-Adresse ist nicht gültig.`
