import styled from 'styled'

type ItemProps = {
  side?: 'left' | 'right'
  responsiveFullAt?: 'desktop' | 'tablet' | 'phone'
}

const InputGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) ;
  grid-row-gap: ${props => props.theme.spaces.l};
  grid-column-gap: ${props => props.theme.spaces.xl};

  > * {
    grid-column: 1/3;
  }
`

const Item = styled.div<ItemProps>`

  ${props => props.side === 'left'? (
    {gridColumn: '1/2'} 
  ) : undefined }

  ${props => props.side === 'right'? (
    {gridColumn: '2/3'} 
  ) : undefined }

  ${props => {
    switch(props.responsiveFullAt) {
      case 'desktop':
        return props.theme.media.desktop`grid-column: 1/3;` 
      case 'tablet':
        return props.theme.media.tablet`grid-column: 1/3;` 
      case 'phone':
        return props.theme.media.phone`grid-column: 1/3;`
      default:
        return undefined
    }
  }}
`

export { InputGridContainer, Item }
