import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { useTheme } from 'styled'

import PageHero from 'components/PageHero'
import Button from 'components/Button'

import DefaultPageLayout from 'layout/DefaultPageLayout'

const Terms = (props: RouteComponentProps) => {
  const { spaces, colors } = useTheme()
  return (
    <DefaultPageLayout>
      <PageHero>
        <PageHero.Title>
          Nutzungsbedingungen f&uuml;r chefarztantrag.at (inkl. Bestimmungen zur
          Auftragsverarbeitung)
        </PageHero.Title>
      </PageHero>
      <div style={{ color: colors['grey-30'] }}>
        <div style={{ margin: spaces.stack.m }}>Version vom 13.03.2019</div>
        <a href="/documents/AGB.pdf" target="_blank">
          <Button style={{ margin: spaces.stack.l }}>
            <span>Download als PDF</span>
          </Button>
        </a>
        <h2>Pr&auml;ambel</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Diese Nutzungsbedingungen beschreiben die Nutzung des Produktes
          &bdquo;chefarztantrag.at&ldquo; durch den Nutzer, und zwar insbes. zur
          Erstellung von Erstattungsantr&auml;gen im Namen und Auftrag von
          Patienten.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Der Nutzer selbst hat die ausschlie&szlig;liche Kontrolle &uuml;ber
          den Einsatz der Mittel und des Zweckes der
          Verarbeitungst&auml;tigkeit. chefarztantrag.at hat (Lese-)Zugriff auf
          die personenbezogenen Daten ausschlie&szlig;lich bei der Wartung
          und/oder beim Support und/oder bei Erstellung einer Kopie der Daten
          f&uuml;r Wartungs- und/oder Supportzwecke. Sonstige Zugriffe auf die
          personenbezogenen Daten von chefarztantrag.at erfolgen nicht.
        </p>
        <h2>Geltungsbereich der Nutzungsbedingungen</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Nutzer des Produktes &bdquo;chefarztantrag.at&ldquo; sind im Bereich
          der Erstattungsf&auml;higkeit von Arzneimittel und der damit
          zusammenh&auml;ngenden Abwicklung, sowie &Auml;rzte/&Auml;rztinnen
          (insbes. Wahl&auml;rzte/Wahl&auml;rztinnen) &ndash; in der Folge
          &bdquo;<b>Nutzer</b>&ldquo; genannt - sowie andere
          Gesundheitsdienstleister (<b>GDA</b>); anderen Personen ist die
          Nutzung nicht gestattet.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Die Eingabe und Verarbeitung personenbezogener Gesundheitsdaten
          erfolgt ausschlie&szlig;lich Client-seitig &ndash; chefarztantrag.at
          speichert keinerlei personenbezogenen Gesundheitsdaten auf seinen
          Servern.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Diese Nutzungsbedingungen gelten f&uuml;r das Produkt
          &bdquo;chefarztantrag.at&ldquo;. Die Installation der Software erfolgt
          lokal in der Ordination des Nutzers in Form eines Webbrowsers.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Gegenstand dieser Nutzungsbedingungen ist die dauerhafte
          &Uuml;berlassung des Produktes &bdquo;chefarztantrag.at&ldquo; im
          Object-Code und die Einr&auml;umung der beschriebenen Nutzungsrechte.
          Die Hard- und Softwareumgebung, innerhalb derer, die Vertragssoftware
          einzusetzen ist, ist vom Nutzer selbst auf eigene Kosten zur
          Verf&uuml;gung zu stellen.
        </p>
        <h2>Leistungen von chefarztantrag.at</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at erm&ouml;glicht es einem Nutzer f&uuml;r einen
          individuell, von ihm behandelten Patienten eine Verschreibung
          (Verordnung) auf Basis von zur Verf&uuml;gung gestellten Informationen
          (Arzneimittelinformation) zu erstellen, zu verarbeiten und an die
          zust&auml;ndige Sozialversicherung/Krankenkasse zur Pr&uuml;fung der
          Erstattungsf&auml;higkeit zu senden.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at wird vom Nutzer auf seiner eigenen Hardware und
          Software im Rahmen eines g&auml;ngigen Internetbrowsers betrieben.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Nutzer sorgt f&uuml;r eine Hard- und Softwareumgebung, die den
          Zugang und Zugriff von unbefugten Personen ausschlie&szlig;t. Die
          gesetzlichen Vorgaben des Gesundheitstelematikgesetzes sowie der
          Gesundheitstelematikverordnung sind vom Nutzer einzuhalten.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Die Kommunikation mit der zust&auml;ndigen
          Sozialversicherung/Krankenkasse erfolgt per Email. Daf&uuml;r
          verwendet der Nutzer seine eigene Infrastruktur sowie seinen eigenen
          Internet- und Email-Serviceprovider. Chefarztantrag.at regt an, dass
          f&uuml;r die Kommunikation zur Erstattung mit den
          Sozialversicherungen/Krankenkassen vom Arzt eine separate, besonders
          gesicherte (zB verschl&uuml;sslungsf&auml;hige und signierf&auml;hige)
          Email-Adresse ausschlie&szlig;lich aus der Ordination des Nutzers
          genutzt wird. Im Betreff von versandten Emails ist darauf zu achten,
          dass keine personenbezogenen Daten (Name, Vorname,
          Sozialversicherungsnummer) oder personenbezogene Gesundheitsdaten
          aufscheinen; es sollten f&uuml;r die Betreffzeile eindeutige
          Kennzeichen, die dem Empf&auml;nger eine sachliche Zuordnung
          erm&ouml;glichen, jedoch keinen R&uuml;ckschluss auf die Person
          zulassen verwendet werden,
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Ausdr&uuml;cklich darauf hingewiesen wird, dass die Verwendung von
          chefarztantrag.at in einer WLAN-Umgebung, insbes. in offenen WLANs in
          Hotels, Bahnh&ouml;fen, &ouml;ffentlichen Einrichtungen oder
          Z&uuml;gen die Sicherheit der Daten nicht gew&auml;hrleisten kann,
          insbes. wenn der Nutzer dabei zB &uuml;ber das Internet auf einer
          Website direkt auf seine Email-Konten zugreift.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at (Softwarebetreiber/Lizenzgeber) &uuml;bernimmt keine
          Haftung f&uuml;r den Inhalt der Arzneimittelinformationen, die von den
          jeweiligen Pharmabetrieben zur Verf&uuml;gung gestellt werden. Es ist
          Aufgabe des Nutzers die bereit gestellten Informationen auf
          Aktualit&auml;t und inhaltlicher Hinsicht zu pr&uuml;fen.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          chefarztantrag.at &uuml;bernimmt keine Haftung f&uuml;r die
          Funktionsf&auml;higkeit der Hard- und Software des Nutzers, die dieser
          selbst zu warten und zu pflegen hat, um die Lauff&auml;higkeit von
          chefarztantrag.at sowie die Kommunikation mit der
          Sozialversicherung/Krankenkasse sicherzustellen.
        </p>
        <h2>Vertragsschluss und Benutzerkonto</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Mit Zurverf&uuml;gungstellung des Produktes
          &bdquo;chefarztantrag.at&ldquo; und Erstellung eines Profils kommt ein
          Nutzungsvertrag mit chefarztantrag.at zustande. Gegenstand des
          Nutzungsvertrages ist die unentgeltliche Nutzung von
          chefarztantrag.at.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          F&uuml;r die Erstellung eines Profils ist die Erstellung eines
          Benutzerkontos erforderlich. Dieses besteht aus einem Benutzernamen
          und einem Kennwort (&bdquo;Log-in-Daten&ldquo;).
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Die Erstellung eines Benutzerkontos ist nur unter Angabe einer
          aktuellen E-Mail-Adresse des Nutzers m&ouml;glich. Diese
          E-Mail-Adresse dient zugleich der Kommunikation mit dem Betreiber.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Nutzer sichert zu, dass die bei Erstellung seines Profils
          verwendeten Daten (&bdquo;Profil-Daten&ldquo;) zutreffend und
          vollst&auml;ndig sind. Die Nutzung von Pseudonymen ist
          unzul&auml;ssig.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Bei jedweder Kommunikation des Nutzers mit anderen Nutzern entstehen
          etwaige Vertragsbeziehungen ausschlie&szlig;lich zwischen den
          beteiligten Nutzern. Der Betreiber ist weder Stellvertreter noch wird
          er selbst Vertragspartner.
        </p>
        <h2>Rechtseinr&auml;umung / Lizenz f&uuml;r den Nutzer</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Nutzer erh&auml;lt ein nicht ausschlie&szlig;liches, zeitlich
          unbeschr&auml;nktes und nicht &uuml;bertragbares Recht zur Nutzung in
          der Ordination des Nutzers. Die Lizenzeinr&auml;umung erfolgt
          unentgeltlich. Die zul&auml;ssige Nutzung beinhaltet die Installation,
          das Laden in den Arbeitsspeicher sowie den
          bestimmungsgem&auml;&szlig;en Gebrauch durch den Nutzer.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Nutzer ist berechtigt, eine Sicherungskopie zu erstellen, sofern
          diese zur Sicherung der k&uuml;nftigen Nutzung erforderlich ist. Der
          Nutzer verpflichtet sich, auf der vorgenannten Sicherungskopie den
          Vermerk &bdquo;Sicherungskopie&ldquo; sichtbar anzubringen sowie einen
          Urheberrechtsvermerk, der auf den LG verweist.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Nutzer ist berechtigt, die Software zu dekompilieren und zu
          vervielf&auml;ltigen, wenn dies notwendig ist, um die
          Interoperabilit&auml;t mit anderen Programmen zu erhalten. Dies jedoch
          nur unter der Voraussetzung, dass chefarztantrag.at dem Nutzer die
          hierzu notwendigen Informationen auf Anforderung des Nutzers nicht
          innerhalb einer angemessenen Frist zug&auml;nglich macht.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Merkmale, die der Programmidentifikation dienen (z.B. Urhebervermerke,
          Seriennummern etc.) d&uuml;rfen nicht entfernt und/oder ver&auml;ndert
          werden.
        </p>
        <h2>Ausschluss der Gew&auml;hrleistung und Haftung</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Das Produkt wird unentgeltlich zur Verf&uuml;gung gestellt.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Von chefarztantrag.at wird daher <u>keine</u> Gew&auml;hrleistung
          und/oder Haftung f&uuml;r Schadenersatz, inkl. M&auml;ngelfolgeschaden
          oder Schaden der durch die Nutzung beim Nutzer oder Dritten entsteht,
          &uuml;bernommen
        </p>
        <h2>Sicherungsma&szlig;nahmen</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Nutzer verpflichtet sich, das Produkt chefarztantrag.at sowie die
          Zugangsdaten / Zugriffsdaten vor dem Zugriff durch unbefugte Dritte zu
          sichern. Der Nutzer wird hierf&uuml;r geeignete Ma&szlig;nahmen
          vornehmen.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Insbesondere verpflichtet sich der Nutzer, s&auml;mtliche Kopien sowie
          die vorgenannten Zugangsdaten / Zugriffsdaten an einem vor dem Zugriff
          durch Unbefugte Dritte gesch&uuml;tzten Ort aufzubewahren.
        </p>{' '}
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Der Nutzer verpflichtet sich, f&uuml;r ausreichende technische und
          organisatorische Ma&szlig;nahmen iSd Art 32 DSGVO aus eigenem Sorge zu
          tragen, und insbes. seine gesamte Infrastruktur bei Nutzung von
          chefarztantrag.at ausreichend mit angemessenen technischen und
          organisatorischen Ma&szlig;nahmen iSd Art 32 DSGVO gegen die Risiken,
          die sich aus der Verarbeitung der Daten, insbes. der besonderen Arten
          von Datenkategorien des Art 9 DSGVO (Gesundheitsdaten) ergeben.
        </p>
        <h2>Vertragsdauer/K&uuml;ndigung</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Vertrag wird auf unbestimmte Zeit geschlossen und kann von beiden
          Seiten jederzeit unter Einhaltung einer K&uuml;ndigungsfrist von einem
          Monat zum Monatsletzten ordentlich gek&uuml;ndigt werden.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          Daneben und dar&uuml;ber hinaus bleibt das Recht der Parteien, das
          Vertragsverh&auml;ltnis durch au&szlig;erordentliche K&uuml;ndigung
          aus wichtigem Grund zu beenden, unbenommen.
        </p>
        <h2>Auftragsverarbeitung (Art 28 DSGVO)</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Folgende Datenkategorien werden im Rahmen von chefarztantrag.at
          verarbeitet:
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Name Geburtsdatum, Gewicht Sozialversicherungsnummern, zust&auml;ndige
          Sozialversicherung / Krankenkasse, von Patienten, Diagnosen, Name und
          Kontaktdaten / Adressdaten sowie Signatur-Bilder von &Auml;rzten,
          Beurteilung der Erstattungsf&auml;higkeit, Datum und Zeit der
          Erstellung, Dokumente im Rahmen der Kommunikation mit der
          Sozialversicherung / Krankenkasse zur Erstattungsf&auml;higkeit.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Folgende Kategorien von betroffenen Personen werden verarbeitet:
          Patienten, Nutzer, Besch&auml;ftige des Nutzers
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at verpflichtet sich, Daten und Verarbeitungsergebnisse
          ausschlie&szlig;lich im Rahmen dokumentierter Auftr&auml;ge des
          Auftraggebers zu verarbeiten, wobei der Nutzer ausschlie&szlig;lich
          berechtigt ist, die Inhalte im Rahmen der eigenen Nutzung zu erstellen
          und zu verarbeiten. chefarztantrag.at erh&auml;lt nur Lesezugriff auf
          die personenbezogenen Daten, wenn eine Wartung und/oder Support
          f&uuml;r den Nutzer erfolgt. Die Daten werden von chefarztantrag.at
          nicht f&uuml;r eigene Zwecke verarbeitet.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at erkl&auml;rt, dass alle mit der Datenverarbeitung
          beauftragten Personen vor Aufnahme der T&auml;tigkeit zur
          Vertraulichkeit verpflichtet wurden oder diese einer angemessenen
          gesetzlichen Verschwiegenheitsverpflichtung unterliegen. Insbesondere
          bleibt die Verschwiegenheitsverpflichtung der mit der
          Datenverarbeitung beauftragten Personen auch nach Beendigung ihrer
          T&auml;tigkeit und Ausscheiden bei chefarztantrag.at aufrecht.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at erkl&auml;rt, dass er alle erforderlichen
          Ma&szlig;nahmen zur Gew&auml;hrleistung der Sicherheit der
          Verarbeitung nach Art 32 DSGVO ergriffen hat, sofern Zugriff auf die
          personenbezogenen Daten des Nutzers im Rahmen der Wartung und/oder des
          Support besteht. chefarztantrag.at wird Kopien der personenbezogenen
          Daten nur dann anfertigen, wenn dies technisch notwendig ist, um
          etwaige Arbeiten f&uuml;r den Nutzer in dessen Auftrag zu erledigen.
          Die Kopien werden unverz&uuml;glich nach Fertigstellung der Arbeiten
          gel&ouml;scht.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at ergreift die technischen und organisatorischen
          Ma&szlig;nahmen, damit der Nutzer die Rechte der betroffenen Person
          nach Kapitel III der DSGVO (Information, Auskunft, Berichtigung und
          L&ouml;schung, Daten&uuml;bertragbarkeit, Widerspruch, sowie
          automatisierte Entscheidungsfindung im Einzelfall) innerhalb der
          gesetzlichen Fristen jederzeit erf&uuml;llen kann und
          &uuml;berl&auml;sst dem Nutzer alle daf&uuml;r notwendigen
          Informationen, und zwar insbes. durch den direkten, eigenen Zugriff
          des Nutzers auf die personenbezogenen Daten, die der Nutzer selbst im
          Rahmen der Nutzung verarbeitet; chefarztantrag.at hat keinen Zugriff
          auf die personenbezogenen Daten au&szlig;erhalb der Wartung und/oder
          des Support.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Wird ein entsprechender Antrag an chefarztantrag.at gerichtet und
          l&auml;sst dieser erkennen, dass der Antragsteller chefarztantrag.at
          irrt&uuml;mlich f&uuml;r den Verantwortlichen der von ihm betriebenen
          Verarbeitungst&auml;tigkeit h&auml;lt, wird chefarztantrag.at den
          Antrag unverz&uuml;glich an den Nutzer weiterzuleiten und dies dem
          Antragsteller mitzuteilen.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at unterst&uuml;tzt den Nutzer als Verantwortlichen bei
          der Einhaltung der in den Art 32 bis 36 DSGVO genannten Pflichten
          (Datensicherheitsma&szlig;nahmen, Meldungen von Verletzungen des
          Schutzes personenbezogener Daten an die Aufsichtsbeh&ouml;rde,
          Benachrichtigung der von einer Verletzung des Schutzes
          personenbezogener Daten betroffenen Person,
          Datenschutz-Folgeabsch&auml;tzung, vorherige Konsultation).
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at wird f&uuml;r die vorliegende Auftragsverarbeitung
          einen Eintrag im Verzeichnis von Verarbeitungst&auml;tigkeiten iSd Art
          30 Abs 2 DSGVO erstellen. Der Nutzer wird f&uuml;r die
          Verarbeitungst&auml;tigkeit einen Eintrag in seinem Verzeichnis von
          Verarbeitungst&auml;tigkeiten iSd Art 30 Abs 1 DSGVO erstellen.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Nutzer hat w&auml;hrend der Nutzung eine direkte M&ouml;glichkeit
          auf von ihm verarbeitete Daten Einsicht zu nehmen. Dem Nutzer kommt
          ein Kontrollrecht in Bezug auf die Verarbeitung der personenbezogenen
          Daten durch chefarztantrag.at im Rahmen des Support und/oder der
          Wartung zu. Diese Kontrolle kann der Nutzer auch durch ihn beauftragte
          Dritte, die zur Verschwiegenheit verpflichtet sind, durchf&uuml;hren.
          chefarztantrag.at verpflichtet sich, dem Nutzer jene Informationen zur
          Verf&uuml;gung zu stellen, die zur Kontrolle der Einhaltung der in
          dieser Vereinbarung sowie der DSGVO genannten Verpflichtungen
          notwendig sind.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at ist nach Beendigung dieser Vereinbarung
          verpflichtet, alle Verarbeitungsergebnisse und Unterlagen, die Daten
          enthalten, zu vernichten. Eine Herausgabe ist nicht notwendig, da der
          Nutzer selbst &uuml;ber die Daten verf&uuml;gt, und auf diese direkten
          Zugriff hat.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at wird den Nutzer unverz&uuml;glich informieren, falls
          er der Ansicht ist, dass eine Weisung des Nutzers gegen
          Datenschutzbestimmungen der Europ&auml;ischen Union oder der
          Mitgliedstaaten, insbes. &Ouml;sterreichs verst&ouml;&szlig;t.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Personenbezogene Daten, insbesondere Gesundheitsdaten werden vom
          Nutzer lokal im Browser verarbeitet. Im Bereich der &Uuml;bermittlung
          der Erstattungsantr&auml;ge werden die Daten von Email-Providern
          innerhalb der europ&auml;ischen Union verarbeitet.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Sofern Datenverarbeitungst&auml;tigkeiten werden zumindest zum Teil
          auch au&szlig;erhalb der EU bzw des EWR durchgef&uuml;hrt werden, dann
          wird ein angemessenes Datenschutzniveau im Anlassfall sichergestellt.
          Dieses wird durch Ausnutzung der folgenden M&ouml;glichkeiten
          erreicht:
        </p>
        <ul>
          <li>
            Angemessenheitsbeschluss der Europ&auml;ischen Kommission nach Art
            45 DSGVO.
          </li>
          <li>
            Ausnahme f&uuml;r den bestimmten Fall nach Art 49 Abs 1 DSGVO.
          </li>
          <li>
            Standarddatenschutzklauseln nach Art 46 Abs 2 lit c und d DSGVO.
          </li>
          <li>
            Ausnahme f&uuml;r den Einzelfall nach Art 49 Abs 1 Unterabsatz 2
            DSGVO.
          </li>
        </ul>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at kann Sub-Auftragsverarbeiter hinzuziehen. Ein
          Sub-Auftragsverarbeiter-verh&auml;ltnis liegt dann nicht vor, wenn
          nicht der charakteristische Teil der Leistungserbringung an einen
          anderen, dritten Vertragspartner &uuml;bertragen wird (zB nur die
          Speicherung der Daten).
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Folgende Sub-Auftragsverarbeiter werden vom Nutzer genehmigt: AWS
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          chefarztantrag.at wird den Nutzer von der beabsichtigten Heranziehung
          eines Sub-Auftragsverarbeiters so rechtzeitig zu verst&auml;ndigen,
          dass er dies allenfalls untersagen kann. Untersagt der Nutzer die
          Beiziehung eines Sub-Auftragnehmers f&uuml;r eine
          Auftragsverarbeitung, dann ist chefarztantrag.at berechtigt, den
          Vertrag aufzul&ouml;sen, sofern nicht mit gleichem wirtschaftlichen
          Aufwand auch ein alternativer Auftragsverarbeiter von
          chefarztantrag.at eingesetzt werden kann.
        </p>
        <p
          style={{
            hyphens: 'auto',
            textAlign: 'justify',
            margin: spaces.stack.xl,
          }}
        >
          chefarztantrag.at schlie&szlig;t die erforderlichen Vereinbarungen im
          Sinne des Art 28 Abs 4 DSGVO mit etwaigen Sub-Auftragsverarbeitern ab.
          Dabei ist sicherzustellen, dass die Sub-Auftragsverarbeiter dieselben
          Verpflichtungen eingehen, die chefarztantrag.at auf Grund dieser
          Vereinbarung obliegen. Kommt der Sub-Auftragsverarbeiter seinen
          Datenschutzpflichten nicht nach, so haftet chefarztantrag.at
          gegen&uuml;ber dem Nutzer f&uuml;r die Einhaltung der Pflichten des
          Sub-Auftragsverarbeiters.
        </p>
        <h2>Schlussbestimmungen</h2>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Der Nutzer darf Anspr&uuml;che gegen chefarztantrag.at nur nach dessen
          schriftlicher Zustimmung an Dritte abtreten.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Eine Aufrechnung des Nutzers ist nur mit unbestrittenen oder
          rechtskr&auml;ftig festgestellten Forderungen zul&auml;ssig.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          &Auml;nderungen und Erg&auml;nzungen dieses Vertrages bed&uuml;rfen
          der Schriftform. Dies gilt auch f&uuml;r die &Auml;nderung oder
          Aufhebung der Schriftformklausel. Elektronische Dokumente in Textform
          erf&uuml;llen dieses Formerfordernis nicht.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Auf diesen Vertrag findet ausschlie&szlig;lich &ouml;sterreichisches
          Recht Anwendung.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Erf&uuml;llungsort f&uuml;r die Leistungen von chefarztantrag.at ist
          der Sitz von chefarztantrag.at. Ausschlie&szlig;licher Gerichtsstand
          ist Wien.
        </p>
        <p style={{ hyphens: 'auto', textAlign: 'justify' }}>
          Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein, so
          ber&uuml;hrt dies die G&uuml;ltigkeit der &uuml;brigen Bestimmungen
          nicht. Die Vertragsparteien werden sich in diesem Falle bem&uuml;hen,
          anstelle der unwirksamen Regelung eine wirksame zu finden, die dem
          wirtschaftlichen Interesse beider Parteien entspricht und der
          wirtschaftlichen Bedeutung der unwirksamen Klausel am ehesten
          nahekommt.
        </p>
      </div>
    </DefaultPageLayout>
  )
}

export default Terms
