import React, { useState, ChangeEvent, useEffect } from 'react'
import styled from 'styled'

import Select from 'components/Select'

type BirthDayPickerProps = {
  date?: Date
  onChange: (date: Date) => void
}

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: ${props => props.theme.spaces.s};

  grid-template-columns: 110px 170px 130px;
  ${props => props.theme.media.phone`grid-template-columns: auto;`}
  ${props =>
    props.theme.media.phone`grid-row-gap: ${props => props.theme.spaces.xs};`}
`
const days = [...Array(31)].map((_, idx) => Number(idx + 1))
const months = [
  'Jänner',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]
const years = [...Array(120)].map((_, idx) =>
  Number(new Date().getFullYear() - idx)
)

function BirthDayPicker(props: BirthDayPickerProps) {
  const [day, setDay] = useState<number | undefined>(
    props.date ? props.date.getDate() : undefined
  )
  const [month, setMonth] = useState<number | undefined>(
    props.date ? props.date.getMonth() : undefined
  )
  const [year, setYear] = useState<number | undefined>(
    props.date ? props.date.getFullYear() : undefined
  )

  useEffect(() => {
    if (
      typeof day === 'number' &&
      typeof month === 'number' &&
      typeof year === 'number'
    ) {
      if (props.onChange) {
        props.onChange(new Date(year, month, day))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year])

  const handleDayChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const { value } = evt.currentTarget
    setDay(value !== '' ? Number(value) : undefined)
  }
  const handleMonthChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const { value } = evt.currentTarget
    setMonth(value !== '' ? Number(value) : undefined)
  }
  const handleYearChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const { value } = evt.currentTarget
    setYear(value !== '' ? Number(value) : undefined)
  }

  return (
    <Wrapper>
      <Select name="birthDay" value={day || ''} onChange={handleDayChange}>
        <Select.Option value="" disabled>
          Tag
        </Select.Option>
        {days.map((day: number) => (
          <Select.Option value={day} key={`day-${day}`}>
            {day}
          </Select.Option>
        ))}
      </Select>
      <Select
        name="birthMonth"
        value={
          Number.isInteger(month as number) && (month as number) >= 0
            ? month
            : ''
        }
        onChange={handleMonthChange}
      >
        <Select.Option value="" disabled>
          Monat
        </Select.Option>
        {months.map((month: string, index) => (
          <Select.Option value={index} key={`month-${index}`}>
            {month}
          </Select.Option>
        ))}
      </Select>
      <Select name="birthYear" value={year || ''} onChange={handleYearChange}>
        <Select.Option value="" disabled>
          Jahr
        </Select.Option>
        {years.map((year: number) => (
          <Select.Option value={year} key={`year-${year}`}>
            {year}
          </Select.Option>
        ))}
      </Select>
    </Wrapper>
  )
}

export default BirthDayPicker
